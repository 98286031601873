import { HorizontalCard, VerticalCard } from "../common/Card";
import { EditorHeader } from "../common/EditorHeader";
import { AddNewButton } from "../common/AddNewButton";
import { AttributeBuilder } from "../common/AttributeBuilder";
import { EditorPage } from "../../types/editorPage";
import { UID } from "../../types/uid";
import { TextInput } from "../attributes/Input";
import { useSource, useSources } from "../hooks/useSources";
import {
  MediumIconType,
  SourceIconType,
  getMediumDisplayName,
  getMediumIcon,
  getMediumUTMValue,
  getSourceDisplayName,
  getSourceIcon,
  getSourceUTMValue,
  isValidSourceMedium,
} from "../../types/touchpoints";
import { useMedium, useMediumAttribute, useMediums } from "../hooks/useMediums";
import { Attribute } from "../common/Attribute";
import { RemoveItemButton } from "../common/RemoveItemButton";

const MediumAttribute = ({
  mediumId,
  attributeId,
}: {
  mediumId: UID;
  attributeId: UID;
}) => {
  const { name, type, selectRows, value, editValue } = useMediumAttribute(
    mediumId,
    attributeId
  );

  return (
    <Attribute
      className="max-w-full"
      type={type}
      selectRows={selectRows}
      value={value}
      editValue={editValue}
      name={name}
    />
  );
};

const Medium = ({
  className,
  mediumId,
}: {
  className?: string;
  mediumId: UID;
}) => {
  const { icon, name, utmValue, attributeIds, remove, rename, editUTM } =
    useMedium(mediumId);

  const IconComponent = getMediumIcon(icon);

  return (
    <HorizontalCard
      className={`${className} h-fit min-w-fit`}
      header={
        <div className="flex flex-col">
          <div className="flex items-center gap-1">
            <div className="flex w-fit">
              {/* <DotsSixVertical size={20} /> */}
              <IconComponent size={20} />
            </div>
            <TextInput
              initialValue={name}
              className="w-full"
              onChange={(newName) => rename(newName)}
            />

            <RemoveItemButton onRemove={() => remove()} />
          </div>
          <div className="text-sm text-slate-600">
            UTM Value (Medium)
            <TextInput
              initialValue={utmValue}
              onChange={(newValue) => editUTM(newValue)}
            />
          </div>
        </div>
      }
      bodyClassName="flex flex-col gap-x-4 gap-y-2 p-2 "
    >
      {attributeIds.map((attributeId: UID) => (
        <MediumAttribute
          key={attributeId}
          attributeId={attributeId}
          mediumId={mediumId}
        />
      ))}
    </HorizontalCard>
  );
};

const Source = ({
  className,
  sourceId,
}: {
  className?: string;
  sourceId: UID;
}) => {
  const { icon, mediumIds, name, utmValue, remove, rename, editUTM } =
    useSource(sourceId);

  const { create } = useMediums(sourceId);

  const IconComponent = getSourceIcon(icon);

  const newMediumOptions = [
    MediumIconType.Google,
    MediumIconType.Microsoft,
    MediumIconType.Facebook,
    MediumIconType.LinkedIn,
    MediumIconType.YouTube,
    MediumIconType.Instagram,
    MediumIconType.Snapchat,
    MediumIconType.TikTok,
    MediumIconType.Custom,
  ]
    .filter((mediumIconType) => isValidSourceMedium(icon, mediumIconType))
    .map((value: MediumIconType) => ({
      value,
      displayValue: getMediumDisplayName(value),
      icon: getMediumIcon(value),
    }));

  return (
    <VerticalCard
      className={`${className} h-fit min-w-fit`}
      header={
        <div className="flex flex-col">
          <div className="flex items-center gap-1">
            <div className="flex w-fit">
              {/* <DotsSixVertical size={20} /> */}
              <IconComponent size={20} />
            </div>
            <TextInput
              initialValue={name}
              className="w-full"
              onChange={(newName) => rename(newName)}
            />

            <RemoveItemButton onRemove={() => remove()} />
          </div>
          <div className="text-sm text-slate-600">
            UTM Value (Source)
            <TextInput
              initialValue={utmValue}
              onChange={(newValue) => editUTM(newValue)}
            />
          </div>
          <AddNewButton
            className="mt-2"
            title="Add medium"
            options={newMediumOptions}
            onSelect={(value) => {
              create(
                getMediumDisplayName(value),
                value,
                getMediumUTMValue(value)
              );
            }}
          />
        </div>
      }
      bodyClassName="flex flex-col justify-center gap-2 p-2 min-w-fit"
    >
      {mediumIds.map((mediumId: UID) => (
        <Medium key={mediumId} className="w-full" mediumId={mediumId} />
      ))}
    </VerticalCard>
  );
};

const TouchpointsEditor = ({ className }: { className?: string }) => {
  const { sourceIds, create } = useSources();

  const newSourceOptions = [
    SourceIconType.SEM,
    SourceIconType.RetargetedDisplay,
    SourceIconType.TargetedDisplay,
    SourceIconType.Video,
    SourceIconType.Email,
    SourceIconType.Print,
    SourceIconType.OrganicSocial,
    SourceIconType.Custom,
  ].map((value: SourceIconType) => ({
    value,
    displayValue: getSourceDisplayName(value),
    icon: getSourceIcon(value),
  }));

  return (
    <div
      className={`${className} flex w-full overflow-y-scroll no-scrollbar pb-48`}
    >
      <div className="w-full">
        <EditorHeader
          className="p-4"
          title="Touchpoints"
          subtitle="Define methods of reaching customers"
        >
          <AddNewButton
            title="Add source"
            options={newSourceOptions}
            onSelect={(value) =>
              create(
                getSourceDisplayName(value),
                value,
                getSourceUTMValue(value)
              )
            }
          />
        </EditorHeader>
        <div className="w-full grid grid-cols-12 gap-4 justify-center px-4 pb-96">
          {sourceIds.map((sourceId: UID) => (
            <Source
              key={sourceId}
              className="col-span-12 2xl:col-span-6 4xl:col-span-4"
              sourceId={sourceId}
            />
          ))}
        </div>
      </div>
      <AttributeBuilder
        className="sticky top-2 right-2"
        page={EditorPage.Touchpoints}
      />
    </div>
  );
};

export { TouchpointsEditor };
