import { Box, Button, TextField } from "monday-ui-react-core";
import { Envelope, PaperPlaneRight } from "@phosphor-icons/react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useAgency } from "../../../hooks/useAgency";

const AgencyInvite = ({ className, agencyId }: { className?: string, agencyId: number }) => {

  const [inviteEmail, setInviteEmail] = useState<string>("");
  const [inviteValidationErrors, setInviteValidationErrors] = useState<string[]>([]);

  const {
    inviteMember
  } = useAgency(agencyId);

  const invite = () => {

    if (!inviteEmail) {
      setInviteValidationErrors(["Email is required"]);
      return;
    }

    inviteMember({
      email: inviteEmail
    }).then(() => {
        toast.success("Member invited");
        setInviteEmail("");
        setInviteValidationErrors([]);
      }
    ).catch((e) => {
      setInviteValidationErrors(e.map((error: any) => error.message));
      toast.error("Error inviting member");
    });
  };

  return (
    <div className={`${className} px-2 w-full`}>

      <Box border={Box.borders.DEFAULT} rounded={Box.roundeds.SMALL} shadow={Box.shadows.MEDIUM}>
        <div className="flex flex-row gap-4 w-1/3 p-8">
          <TextField
            iconName={Envelope}
            placeholder="new-user@example.com"
            wrapperClassName="monday-storybook-text-field_size"
            onChange={(value) => setInviteEmail(value)}
            value={inviteEmail}
            validation={{
              status: inviteValidationErrors.length > 0 ? "error" : undefined,
              text: inviteValidationErrors.length > 0 ? inviteValidationErrors[0] : undefined
            }}
          />
          <Button onClick={invite}
                  kind={Button.kinds.SECONDARY}
                  color={Button.colors.POSITIVE}
                  size={Button.sizes.SMALL}
                  rightIcon={PaperPlaneRight}>Invite</Button>
        </div>

      </Box>


    </div>
  );
};

export { AgencyInvite };