import { useProjectDispatch, useProjectSelector } from "../../hooks/useEditor";
import { UID, generateUid } from "../../types/uid";
import { TargetEventType } from "../handlers/handleTargets";
import { ProjectState } from "../projectState";
import { createAction } from "../userEventHandlers";

const useTarget = (targetId: UID) => {
  const projectDispatch = useProjectDispatch();

  const name = useProjectSelector(
    (project: ProjectState) => project.targets.definitions[targetId].name,
  );

  const formulaId = useProjectSelector(
    (project: ProjectState) => project.targets.definitions[targetId].formulaId,
  );

  const filterUnionId = useProjectSelector(
    (project: ProjectState) =>
      project.targets.definitions[targetId].filterUnionId,
  );

  const duplicate = () =>
    projectDispatch(
      createAction[TargetEventType.Duplicate]({
        referenceTargetId: targetId,
        newTargetId: generateUid(),
        newFormulaId: generateUid(),
        newFilterIdBase: generateUid(),
        newEntrySetId: generateUid(),
      }),
    );

  const rename = (newName: string) =>
    projectDispatch(
      createAction[TargetEventType.Rename]({ targetId, newName }),
    );

  const remove = () => {
    projectDispatch(createAction[TargetEventType.Delete]({ targetId }));
  };

  return {
    name,
    formulaId,
    filterUnionId,
    duplicate,
    rename,
    remove,
  };
};

const useTargets = () => {
  const projectDispatch = useProjectDispatch();
  const targetIds = useProjectSelector(
    (project: ProjectState) => project.targets.ordering,
  );

  const targetDefinitions = useProjectSelector(
    (project: ProjectState) => project.targets.definitions,
  );

  const create = () => {
    const targetId = generateUid();
    projectDispatch(
      createAction[TargetEventType.Create]({
        targetId: targetId,
        formulaId: generateUid(),
        filterIdBase: generateUid(),
        entrySetId: generateUid(),
      }),
    );
  };

  return { targetIds, targetDefinitions, create };
};

export { useTargets, useTarget };
