import { Button, Text, TextField } from "monday-ui-react-core";
import { EmailCodeVerified, NewMemberObject, useEmailCode } from "../../hooks/useEmailCode";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ValidationErrors } from "../../api/useApiInstance";

const NewMember = ({ className, verified }: { className?: string, verified?: EmailCodeVerified }) => {

  const [data, setData] = useState<NewMemberObject>({
    first_name: "",
    last_name: "",
    email: verified?.user.email || "",
    plain_password: ""
  });

  useEffect(() => {
    setData({ ...data, email: verified?.user.email || "" });
  }, [verified?.user.email]);

  const [inviteValidationErrors, setInviteValidationErrors] = useState<ValidationErrors>([]);

  const navigate = useNavigate();

  const {
    acceptInvitation
  } = useEmailCode(verified?.code);

  const accept = () => {

    if (!verified) {
      return;
    }

    acceptInvitation(verified, data).then(r => {
      toast.success("Invitation accepted. You will be redirected to login page.");
      setTimeout(() => {
        navigate("/login");
      }, 4000);
    }).catch((e) => {
      setInviteValidationErrors(e);
      toast.error("Error accepting invitation");
    });
  };

  if (verified?.user.account_confirmed) {
    return <></>;
  }

  return (

    <div className={`${className}`}>

      <Text className="text-center mb-4" ellipsis={false}>
        Please fill in the following fields to create your account.
      </Text>

      <div className="flex flex-col gap-4 justify-center">

        <TextField
          id="firstName"
          type={TextField.types.TEXT}
          title="First Name"
          size={TextField.sizes.MEDIUM}
          value={data.first_name}
          onChange={(value) => setData({ ...data, first_name: value })}
          validation={{
            status: inviteValidationErrors.find((error) => error.property_path === "firstName") ? "error" : undefined,
            text: inviteValidationErrors.find((error) => error.property_path === "firstName")?.message
          }}
          placeholder="John"
        />

        <TextField
          id="lastName"
          type={TextField.types.TEXT}
          title="Last Name"
          size={TextField.sizes.MEDIUM}
          value={data.last_name}
          onChange={(value) => setData({ ...data, last_name: value })}
          validation={{
            status: inviteValidationErrors.find((error) => error.property_path === "lastName") ? "error" : undefined,
            text: inviteValidationErrors.find((error) => error.property_path === "lastName")?.message
          }}
          placeholder="Doe"
        />

        <TextField
          id="email"
          type={TextField.types.TEXT}
          disabled={true} title="Email"
          size={TextField.sizes.MEDIUM}
          value={data.email}
          placeholder="jon.doe@example.com"
        />

        <TextField
          id="password"
          type={TextField.types.PASSWORD}
          title="Password"
          size={TextField.sizes.MEDIUM}
          value={data.plain_password}
          validation={{
            status: inviteValidationErrors.find((error) => error.property_path === "plainPassword") ? "error" : undefined,
            text: inviteValidationErrors.find((error) => error.property_path === "plainPassword")?.message
          }}
          onChange={(value) => setData({ ...data, plain_password: value })}
        />

        <Button onClick={accept} size={Button.sizes.LARGE} color={Button.colors.POSITIVE}>
          Accept Invitation
        </Button>

      </div>

    </div>
  );
};

export { NewMember };
