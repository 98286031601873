import { Nav } from "../../components/Nav";
import { ProjectNavigator, ProjectView } from "./ProjectNavigator";
import { SelectsEditor } from "./SelectsEditor";
import { OffersEditor } from "./OffersEditor";
import { TouchpointsEditor } from "./TouchpointsEditor";
import { TargetsEditor } from "./TargetsEditor";
import { UTMsEditor } from "./UTMsEditor";
import { usePersistentState } from "../../hooks/usePersistentState";
import { ExportsEditor } from "./ExportsEditor";
import { ColumnsEditor } from "./ColumnsEditor";
import { NavProjectName } from "../../components/NavProjectName";

const UnderConstruction = () => {
  return (
    <div>
      This editor is under construction. Please use your imagination for now.
    </div>
  );
};

const getEditorView = (view: ProjectView) => {
  switch (view) {
    case ProjectView.Selects:
      return SelectsEditor;
    case ProjectView.Offers:
      return OffersEditor;
    case ProjectView.Touchpoints:
      return TouchpointsEditor;
    case ProjectView.URLs:
      return TargetsEditor;
    case ProjectView.UTMs:
      return UTMsEditor;
    case ProjectView.Columns:
      return ColumnsEditor;
    case ProjectView.Export:
      return ExportsEditor;
    default:
      return UnderConstruction;
  }
};

const ProjectEditor = ({
  className,
  projectId,
}: {
  className?: string;
  projectId: string;
}) => {
  const {
    selectedProjectView: selectedView,
    setSelectedProjectView: setSelectedView,
  } = usePersistentState();

  const EditorViewElement = getEditorView(selectedView);

  return (
    <div className={`${className} flex flex-col h-screen overflow-hidden `}>
      <Nav
        className="w-full"
        fullySpreadsControls
        projectName={
          <NavProjectName
            className="flex items-left w-full"
            projectId={projectId}
          />
        }
      />
      <div className="flex h-full w-full bg-slate-100">
        <ProjectNavigator
          onViewChange={(view: ProjectView) => setSelectedView(view)}
          selectedView={selectedView}
        />
        <EditorViewElement className="" />
      </div>
    </div>
  );
};

export { ProjectEditor };
