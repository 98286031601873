import {
  CalendarBlank,
  CaretCircleDown,
  CheckSquare,
  CirclesThree,
  CurrencyDollar,
  HashStraight,
  Icon,
  TextAa,
} from "@phosphor-icons/react";
import { UID } from "./uid";

enum AttributeType {
  Text = "text",
  Number = "number",
  Currency = "currency",
  Date = "date",
  Checkbox = "checkbox",
  SingleSelect = "single_select",
  MultiSelect = "multi_select",
}

const getAttributeIcon = (attributeType: AttributeType): Icon => {
  switch (attributeType) {
    case AttributeType.Text:
      return TextAa;
    case AttributeType.Number:
      return HashStraight;
    case AttributeType.Currency:
      return CurrencyDollar;
    case AttributeType.Date:
      return CalendarBlank;
    case AttributeType.Checkbox:
      return CheckSquare;
    case AttributeType.SingleSelect:
      return CaretCircleDown;
    case AttributeType.MultiSelect:
      return CirclesThree;
  }
};

const getAttributeTypeDescription = (attributeType: AttributeType): string => {
  switch (attributeType) {
    case AttributeType.Text:
      return "Text";
    case AttributeType.Number:
      return "Number";
    case AttributeType.Currency:
      return "Currency";
    case AttributeType.Date:
      return "Date";
    case AttributeType.Checkbox:
      return "Checkbox";
    case AttributeType.SingleSelect:
      return "Single-Select";
    case AttributeType.MultiSelect:
      return "Multi-Select";
  }
};

enum AttributeValidationType {
  None = "none",
  Regex = "regex",
  ListItem = "list_item",
}

type Attribute = {
  type: AttributeType;
  name: string;
  selectId?: UID;
};

export {
  AttributeType,
  AttributeValidationType,
  getAttributeIcon,
  getAttributeTypeDescription,
};

export type { Attribute };
