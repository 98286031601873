import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  TextField
} from "monday-ui-react-core";
import { Pencil, Trash } from "@phosphor-icons/react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useBrands } from "../../../hooks/useBrands";
import { Brand } from "../../../hooks/useBrand";

const AgencyBrands = ({ className, agencyId }: { className?: string, agencyId: number }) => {

  const [isAttemptingRenameBrand, setIsAttemptingRenameBrand] = useState<boolean>(false);
  const [brandToRename, setBrandToRename] = useState<Brand | undefined>(undefined);
  const [isAttemptingRemoveBrand, setIsAttemptingRemoveBrand] = useState<boolean>(false);
  const [brandToRemove, setBrandToRemove] = useState<Brand | undefined>(undefined);

  const {
    brands,
    createBrand,
    updateBrand,
    deleteBrand
  } = useBrands({ agency_id: agencyId.toString() });

  const renameBrand = () => {

    if (!brandToRename) {
      return;
    }

    updateBrand({
      brand_id: brandToRename.id,
      brand_name: brandToRename.brand_name
    }).then(() => {
      toast.success("Brand renamed");
      setBrandToRename(undefined);
    });
  };

  const removeBrand = () => {

    if (!brandToRemove || brandToRemove.id === undefined) {
      return;
    }

    deleteBrand(brandToRemove.id).then(() => {
      toast.success("Brand removed");
      setBrandToRemove(undefined);
    });
  };

  return (
    <div className={`${className}`}>

      <div className="px-2 w-full">
        <div
          className="flex shadow hover:bg-slate-50 w-full bg-white gap-2 py-2 px-4 mb-1 rounded-md items-center">
          <div className="grid grid-cols-7 w-full">
            <div className="col-span-2 text-start font-semibold">
              Brand Name
            </div>
            <div className="col-span-2 text-center font-semibold">
              Campaigns
            </div>
            <div className="col-span-2 text-center font-semibold">
              Brand Members
            </div>
            <div className="col-span-1"></div>
          </div>
        </div>
        {brands.map((brand: Brand, index) => (
          <div key={`brand-${brand.id}`}
               className="flex shadow hover:bg-slate-50 w-full bg-white gap-2 py-2 px-4 mb-1 rounded-md items-center">
            <div className="grid grid-cols-7 w-full">
              <div className="col-span-2 text-start">
                {brand.brand_name}
              </div>
              <div className="col-span-2 text-center">
                {brand.projects.length}
              </div>
              <div className="col-span-2 text-center">
                {brand.brand_members.length}
              </div>
              <div className="col-span-1">
                <MenuButton
                  className="float-right"
                  size={MenuButton.sizes.XS}
                  dialogPosition={MenuButton.dialogPositions.BOTTOM_START}>
                  <Menu id={`brand-${brand.id}`}>
                    <MenuItem
                      icon={Pencil}
                      title="Rename"
                      onClick={() => {
                        setBrandToRename(brand);
                        setIsAttemptingRenameBrand(true);
                      }}
                    />
                  </Menu>
                  <Menu id={`brand-${brand.id}-remove`}>
                    <MenuItem
                      icon={Trash}
                      title="Remove (Must delete all campaigns first)"
                      onClick={() => {
                        setBrandToRemove(brand);
                        setIsAttemptingRemoveBrand(true);
                      }}
                    />
                  </Menu>
                </MenuButton>
              </div>
            </div>
          </div>
        ))}
      </div>

      {brandToRemove && (
        <Modal
          data-testid={"modal_remove_brand_attempt"}
          show={isAttemptingRemoveBrand}
          onClose={() => {
            setBrandToRemove(undefined);
            setIsAttemptingRemoveBrand(false);
          }}
        >
          <ModalHeader title="Remove brand?" />
          <ModalContent>
            <div className="pb-2">

              {brandToRemove.projects.length > 0 && (
                <Text weight={"bold"}>You must delete all campaigns before removing this brand.</Text>)}

              {brandToRemove.projects.length === 0 && (
                <>
                  <Text>Are you sure you want to remove this brand?</Text>
                  <Text weight={"bold"}>{brandToRemove.brand_name}</Text>
                </>)}

            </div>
          </ModalContent>
          <ModalFooter className="flex justify-start gap-2">
            <Button kind={Button.kinds.PRIMARY} onClick={() => {
              setBrandToRemove(undefined);
              setIsAttemptingRemoveBrand(false);
            }
            }>
              Cancel
            </Button>

            <Button
              kind={Button.kinds.SECONDARY}
              onClick={() => {
                removeBrand();
                setBrandToRemove(undefined);
              }}
              rightIcon={Trash}
              color={Button.colors.NEGATIVE}
              disabled={brandToRemove.projects.length > 0}
            >
              Remove
            </Button>
          </ModalFooter>
        </Modal>
      )}

      {brandToRename && (
        <Modal
          data-testid={"modal_rename_brand_attempt"}
          show={isAttemptingRenameBrand}
          onClose={() => {
            setBrandToRename(undefined);
            setIsAttemptingRenameBrand(false);
          }}
        >
          <ModalHeader title="Rename brand?" />
          <ModalContent>
            <div className="pb-2">
              Are you sure you want to rename this brand?
              <TextField value={brandToRename.brand_name} onChange={
                (value) => {
                  if (!brandToRename) {
                    return;
                  }
                  brandToRename.brand_name = value;
                  setBrandToRename(brandToRename);
                }
              } />
            </div>
          </ModalContent>
          <ModalFooter className="flex justify-start gap-2">
            <Button kind={Button.kinds.PRIMARY} onClick={() => {
              setBrandToRename(undefined);
              setIsAttemptingRenameBrand(false);
            }
            }>
              Cancel
            </Button>
            <Button
              kind={Button.kinds.SECONDARY}
              onClick={() => {
                renameBrand();
                setBrandToRename(undefined);
              }}
              color={Button.colors.POSITIVE}
            >
              Rename
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export { AgencyBrands };