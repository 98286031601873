import { useProjectDispatch, useProjectSelector } from "../../hooks/useEditor";
import { Attribute, AttributeType } from "../../types/attribute";
import { EditorPage } from "../../types/editorPage";
import {
  BoolType,
  ComparisonType,
  FilterEntityType,
  FilterType,
  RefValueType,
  SetMatchType,
} from "../../types/filter";
import { UID } from "../../types/uid";
import { DropdownItem } from "../attributes/Dropdown";
import { FilterEventType } from "../handlers/handleFilters";
import { createAction } from "../userEventHandlers";
import {
  dateComparisonOptions,
  numericComparisonOptions,
} from "../util/filterComparison";
import { useAttributes } from "./useAttributes";
import { useOffers } from "./useOffers";
import { useSelectOptions } from "./useSelects";
import { useSet } from "./useSets";
import { useTargets } from "./useTargets";
import { useTouchpoints } from "./useTouchpoints";

enum RecordFilterType {
  OfferName = "offerName",
  SourceMediumName = "sourceMediumName",
  TargetName = "targetName",
  Attribute = "attribute",
  UTMParam = "utmParam",
  None = "none",
}

const useRecordFilter = (filterId: UID, editorPages: EditorPage[]) => {
  const projectDispatch = useProjectDispatch();

  const filter = useProjectSelector(
    (projectState) => projectState.filters[filterId],
  );

  const filterType = useProjectSelector(
    (projectState) => projectState.filters[filterId].type,
  );

  const selectedAttributeId = useProjectSelector((projectState) =>
    (() => {
      const filter = projectState.filters[filterId];
      switch (filter.type) {
        case FilterType.TrueFalseSet:
        case FilterType.SelectSet:
        case FilterType.ValueComparison:
        case FilterType.AttributeValueSubstring:
          return filter.attributeId;
        default:
          return undefined;
      }
    })(),
  );

  const selectedComparisonType = useProjectSelector((projectState) =>
    (() => {
      const filter = projectState.filters[filterId];
      switch (filter.type) {
        case FilterType.ValueComparison:
          return filter.comparisonType;
        default:
          return undefined;
      }
    })(),
  );

  const selectedSetMatchType = useProjectSelector((projectState) =>
    (() => {
      const filter = projectState.filters[filterId];
      switch (filter.type) {
        case FilterType.EntityIdSet:
        case FilterType.SelectSet:
          return filter.setMatchType;
        default:
          return undefined;
      }
    })(),
  );

  const setId = useProjectSelector(
    (projectState) => projectState.filters[filterId].entrySetId,
  );

  const entrySet = useSet(setId);

  const comparisonPrimaryValue = useProjectSelector((projectState) =>
    (() => {
      const filter = projectState.filters[filterId];
      switch (filter.type) {
        case FilterType.ValueComparison:
          return filter.refValuePrimary;
        default:
          return undefined;
      }
    })(),
  );

  const comparisonSecondaryValue = useProjectSelector((projectState) =>
    (() => {
      const filter = projectState.filters[filterId];
      switch (filter.type) {
        case FilterType.ValueComparison:
          return filter.refValueSecondary;
        default:
          return undefined;
      }
    })(),
  );

  const recordFilterType: RecordFilterType = useProjectSelector(
    (projectState) =>
      projectState.filters[filterId].userFriendlyType as RecordFilterType,
  );

  const currentSubstringValue: string | undefined = useProjectSelector(
    (projectState) =>
      (() => {
        const filter = projectState.filters[filterId];
        switch (filter.type) {
          case FilterType.AttributeValueSubstring:
            return filter.substring;
          default:
            return undefined;
        }
      })(),
  );

  const {
    attributeOptions: offerAttributeOptions,
    definitions: offerAttributeDefinitions,
  } = useAttributes(EditorPage.Offers);

  const {
    attributeOptions: touchpointAttributeOptions,
    definitions: touchpointAttributeDefinitions,
  } = useAttributes(EditorPage.Touchpoints);

  const attributeDefinitions: { [entityId: UID]: Attribute } = {
    ...(editorPages.includes(EditorPage.Offers)
      ? offerAttributeDefinitions
      : {}),
    ...(editorPages.includes(EditorPage.Touchpoints)
      ? touchpointAttributeDefinitions
      : {}),
  };

  const attributeOptions: DropdownItem[] = [
    ...(editorPages.includes(EditorPage.Offers) ? offerAttributeOptions : []),
    ...(editorPages.includes(EditorPage.Touchpoints)
      ? touchpointAttributeOptions
      : []),
  ];

  const selectedAttributeType = selectedAttributeId
    ? attributeDefinitions[selectedAttributeId]?.type
    : undefined;

  const selectedAttributeSelectId = selectedAttributeId
    ? attributeDefinitions[selectedAttributeId]?.selectId
    : undefined;

  const attributeSelectOptions = useSelectOptions(selectedAttributeSelectId);

  const comparisonOptions: DropdownItem[] | undefined = (() => {
    if (!selectedAttributeType) return undefined;
    switch (selectedAttributeType) {
      case AttributeType.Number:
      case AttributeType.Currency:
        return numericComparisonOptions;
      case AttributeType.Date:
        return dateComparisonOptions;
      default:
        return undefined;
    }
  })();

  const { offerIdOptions } = useOffers();
  const { targetIds, targetDefinitions } = useTargets();
  const targetOptions: DropdownItem[] = targetIds.map((targetId) => ({
    id: targetId,
    label: targetDefinitions[targetId].name,
  }));
  const { flatTouchpoints } = useTouchpoints();
  const touchpointOptions: DropdownItem[] = flatTouchpoints.map(
    ({ sourceName, mediumId, mediumName }) => ({
      id: mediumId,
      label: `${sourceName} / ${mediumName}`,
    }),
  );

  const setOptions: DropdownItem[] | undefined = (() => {
    if (filterType === FilterType.EntityIdSet) {
      switch (filter.entityType) {
        case FilterEntityType.Offer:
          return offerIdOptions;
        case FilterEntityType.Target:
          return targetOptions;
        case FilterEntityType.Touchpoint:
          return touchpointOptions;
      }
    }
    if (!selectedAttributeType) return undefined;
    switch (selectedAttributeType) {
      case AttributeType.MultiSelect:
      case AttributeType.SingleSelect:
        return attributeSelectOptions?.selectRows?.map(({ rowId, row }) => ({
          id: rowId,
          label: row.name,
        }));
      case AttributeType.Checkbox:
        return [
          { id: BoolType.True, label: "Checked" },
          { id: BoolType.False, label: "Unchecked" },
        ];
      default:
        return undefined;
    }
  })();

  const setMatchOptions: DropdownItem[] | undefined = (() => {
    if (!selectedAttributeType) return undefined;
    switch (selectedAttributeType) {
      case AttributeType.MultiSelect:
        return [
          { id: SetMatchType.Inclusion, label: "includes at least one of" },
        ];
      default:
        return undefined;
    }
  })();

  const selectComparisonType = (comparisonType: ComparisonType) => {
    if (filterType === FilterType.ValueComparison) {
      projectDispatch(
        createAction[FilterEventType.SelectComparisonType]({
          filterId,
          comparisonType,
        }),
      );
    } else {
      console.error(
        `Attempt to change comparison type of fitler type ${filterType}`,
      );
    }
  };

  const selectSetMatchType = (setMatchType: SetMatchType) => {
    if ([FilterType.EntityIdSet, FilterType.SelectSet].includes(filterType)) {
      projectDispatch(
        createAction[FilterEventType.SelectSetMatchType]({
          filterId,
          setMatchType,
        }),
      );
    } else {
      console.error(
        `Attempt to change set match type of fitler type ${filterType}`,
      );
    }
  };

  const changeType = (newType: RecordFilterType) => {
    projectDispatch(
      createAction[FilterEventType.ChangeRecordFilterType]({
        filterId,
        newType,
      }),
    );
  };

  const selectAttributeId = (attributeId?: UID) => {
    projectDispatch(
      createAction[FilterEventType.SelectAttributeId]({
        filterId,
        attributeId,
      }),
    );
  };

  const changePrimaryRefValue = (newValue: string) => {
    projectDispatch(
      createAction[FilterEventType.ChangeRefValue]({
        filterId,
        refValueType: RefValueType.Primary,
        newValue,
      }),
    );
  };

  const changeSecondaryRefValue = (newValue: string) => {
    projectDispatch(
      createAction[FilterEventType.ChangeRefValue]({
        filterId,
        refValueType: RefValueType.Secondary,
        newValue,
      }),
    );
  };

  const setSubstringValue = (substringValue?: string) => {
    projectDispatch(
      createAction[FilterEventType.SetSubstringValue]({
        filterId,
        substringValue: substringValue || "",
      }),
    );
  };

  return {
    recordFilterType,
    attributeOptions,
    selectedAttributeId,
    selectedComparisonType,
    comparisonOptions,
    comparisonPrimaryValue,
    comparisonSecondaryValue,
    selectedAttributeType,
    setOptions,
    entrySet,
    setMatchOptions,
    selectedSetMatchType,
    currentSubstringValue,
    setSubstringValue,
    selectSetMatchType,
    changeType,
    selectComparisonType,
    selectAttributeId,
    changePrimaryRefValue,
    changeSecondaryRefValue,
  };
};

export { useRecordFilter, RecordFilterType };
