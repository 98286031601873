import { Skeleton } from "monday-ui-react-core";
import { Nav } from "../../components/Nav";

const ProjectEditorLoader = ({ className }: { className?: string }) => {
  return (
    <div className={`${className} bg-slate-100`}>
      <Nav />
      <div className="w-full h-screen overflow-hidden flex flex-wrap gap-4 p-4">
        <Skeleton width={200} height={150} type={Skeleton.types.RECTANGLE} />
        <Skeleton width={200} height={150} type={Skeleton.types.RECTANGLE} />
        <Skeleton width={200} height={150} type={Skeleton.types.RECTANGLE} />
      </div>
    </div>
  );
};

export { ProjectEditorLoader };
