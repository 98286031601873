import "./App.css";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import {
  createBrowserRouter,
  RouterProvider,
  redirect
} from "react-router-dom";
import { Home } from "./pages/Home";
import { Signup } from "./pages/Signup";
import { Campaigns } from "./pages/Campaigns";
import { Edit } from "./editor/Edit";
import { Account } from "./pages/account/Account";
import { Brand } from "./pages/Brand";
import { Login } from "./pages/Login";
import { ErrorPage } from "./pages/ErrorPage";
import { AcceptInvitation } from "./pages/acceptInvitation/AcceptInvitation";
import { AuthProvider, RequireAuth } from "./AuthContext";
import { SelectBrand } from "./pages/SelectBrand";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DEBUG_REDIRECT_HOME: string | undefined = undefined;

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
    loader: async () => {
      if (DEBUG_REDIRECT_HOME) {
        return redirect(DEBUG_REDIRECT_HOME);
      }
      return null;
    }
  },
  { path: "/login", element: <Login /> },
  { path: "/signup", element: <Signup /> },
  { path: "/accept-invitation", element: <AcceptInvitation /> },
  {
    path: "/campaigns/",
    element: (
      <RequireAuth>
        <SelectBrand />
      </RequireAuth>
    )
  },
  {
    path: "/campaigns/:brandId",
    element: (
      <RequireAuth>
        <Campaigns />
      </RequireAuth>
    )
  },
  {
    path: "/edit/:projectId",
    element: (
      <RequireAuth>
        <Edit />
      </RequireAuth>
    )
  },
  {
    path: "/account",
    element: (
      <RequireAuth>
        <Account />
      </RequireAuth>
    )
  },
  {
    path: "/brand",
    element: (
      <RequireAuth>
        <Brand />
      </RequireAuth>
    )
  }
]);

function App() {

  return (
    <AuthProvider>
      <ToastContainer />
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </AuthProvider>
  );
}

export default App;
