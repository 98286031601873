import { useApiInstance } from "../api/useApiInstance";
import { EventID, GeneralEvent } from "../editor/eventUtil";
import { AxiosError } from "axios";
import { z } from "zod";
import { EventSourcePolyfill } from "event-source-polyfill";
import { useCallback, useEffect, useRef, useState } from "react";
import { MicrotimeSchema } from "../types/zod";
import { ProjectId } from "../types/util";

enum EventResponseType {
  Postpone = "POSTPONE",
  Accept = "ACCEPT",
}

const AcceptedEventSchema = z.object({
  id: z.string(),
  user_id: z.union([z.string(), z.number()]),
  project_id: z.union([z.string(), z.number()]),
  event_type: z.string(),
  payload_a: z.string(),
  payload_b: z.string(),
  payload_c: z.string(),
  created_at: MicrotimeSchema,
});

type AcceptedEvent = z.infer<typeof AcceptedEventSchema>;

const convertToGeneralEvent = (
  eventStoreEvent: AcceptedEvent
): GeneralEvent => ({
  eventType: eventStoreEvent.event_type,
  payload: {
    payloadA: eventStoreEvent.payload_a,
    payloadB: eventStoreEvent.payload_b,
    payloadC: eventStoreEvent.payload_c,
  },
});

const EventStoreEventsSchema = z.array(AcceptedEventSchema);

const EventMessageSchema = z.object({
  event: AcceptedEventSchema,
  last_event_id: z.string(),
});

type EventMessage = z.infer<typeof EventMessageSchema>;

const TOKEN: string = process.env.REACT_APP_MERCURE_TOKEN!;
const MERCURE_URL: string = process.env.REACT_APP_EVENT_URL!;

type EventMessageData = {
  action: EventResponseType.Accept;
  event: AcceptedEvent;
  lastEventId: EventID;
};

type PublishEventResponse =
  | {
      responseType: EventResponseType.Accept;
      data: AcceptedEvent;
    }
  | {
      responseType: EventResponseType.Postpone;
      data: undefined;
    };

const MAX_RETRIES = 3;
const RETRY_DELAY = 2000;

const useEventNotifications = (
  projectId: ProjectId,
  lastFetchedEventId: EventID,
  receiveEventCallback: ({
    action,
    event,
    lastEventId,
  }: EventMessageData) => void
) => {
  const eventSourceURL = `${MERCURE_URL}${projectId}&lastEventID=${lastFetchedEventId}`;

  const [eventSource, setEventSource] = useState<EventSourcePolyfill | null>();
  const [retryCount, setRetryCount] = useState(0);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
      if (eventSource) {
        eventSource.close();
      }
    };
  }, [eventSource]);

  useEffect(() => {
    if (isMounted.current) {
      if (eventSource) {
        eventSource.close();
      }

      const newEventSource = new EventSourcePolyfill(eventSourceURL, {
        headers: { Authorization: `Bearer ${TOKEN}` },
      });

      newEventSource.onopen = (o) => {
        console.log(o);
        setRetryCount(0);
      };

      newEventSource.onmessage = (event: {
        lastEventId: string;
        data: string;
      }) => {
        const acceptedEvent: AcceptedEvent = AcceptedEventSchema.parse(
          JSON.parse(event.data)
        );
        receiveEventCallback({
          action: EventResponseType.Accept,
          event: acceptedEvent,
          lastEventId: event.lastEventId,
        });
      };

      newEventSource.onerror = (errorEvent) => {
        console.error(errorEvent);
        if (retryCount < MAX_RETRIES) {
          setRetryCount(retryCount + 1);
          setTimeout(() => {
            if (isMounted.current) {
              setEventSource(null);
            }
          }, RETRY_DELAY);
        } else {
          console.error("Max retries reached. EventSource connection aborted.");
        }
      };

      setEventSource(newEventSource);
    }
  }, [
    isMounted,
    eventSource,
    eventSourceURL,
    receiveEventCallback,
    retryCount,
  ]);
};

const useEventApi = (projectId: ProjectId) => {
  const { instance } = useApiInstance();

  const publishEvent = async (
    event: GeneralEvent,
    lastVerifiedEventID: EventID
  ): Promise<PublishEventResponse> => {
    const res: PublishEventResponse = await instance
      .post(`/events/project/${projectId}/publish`, {
        event: {
          event_type: event.eventType,
          payload_a: event.payload.payloadA,
          payload_b: event.payload.payloadB,
          payload_c: event.payload.payloadC,
        },
        last_event_id: lastVerifiedEventID,
      })
      .then((resp) => {
        if (resp) {
          return resp.data;
        } else {
          console.error("Could not get data");
        }
      })
      .then((data) => {
        const resp: PublishEventResponse = {
          responseType: EventResponseType.Accept,
          data: EventMessageSchema.parse(data).event,
        };
        return resp;
      })
      .catch((error: AxiosError) => {
        if (error.response && error.response.status === 409) {
          const resp: PublishEventResponse = {
            responseType: EventResponseType.Postpone,
            data: undefined,
          };
          return resp;
        }
        throw error;
      });

    return res;
  };

  const getAllEvents = useCallback(async (): Promise<AcceptedEvent[]> => {
    const res = await instance
      .get(`/events/project/${projectId}`)
      .then((resp) => resp.data)
      .then((data): AcceptedEvent[] => EventStoreEventsSchema.parse(data));
    return res;
  }, [projectId, instance]);

  const getAllEventsSinceId = async (
    lastEventId?: EventID
  ): Promise<AcceptedEvent[]> => {
    const res = await instance
      .get(`/events/project/${projectId}?lastEventId=${lastEventId}`)
      .then((resp) => resp.data)
      .then((data): AcceptedEvent[] => EventStoreEventsSchema.parse(data));

    return res;
  };

  return { publishEvent, getAllEvents, getAllEventsSinceId };
};

export {
  useEventNotifications,
  useEventApi,
  convertToGeneralEvent,
  EventResponseType,
};
export type {
  AcceptedEvent,
  EventMessage,
  EventMessageData,
  PublishEventResponse,
};
