import { AddNewButton } from "./AddNewButton";
import { RemoveItemButton } from "./RemoveItemButton";
import {
  DropdownItem,
  MultiDropdown,
  SingleDropdown,
} from "../attributes/Dropdown";
import { UID } from "../../types/uid";
import {
  CurrencyInput,
  DateInput,
  NumberInput,
  TextInput,
} from "../attributes/Input";
import { useFilterIntersection, useFilterUnion } from "../hooks/useFilterUnion";
import { RecordFilterType, useRecordFilter } from "../hooks/useRecordFilter";
import { AttributeType } from "../../types/attribute";
import { ComparisonType, SetMatchType } from "../../types/filter";
import { IconButton } from "monday-ui-react-core";
import { X } from "@phosphor-icons/react";
import { EditorPage } from "../../types/editorPage";

const RecordFilter = ({
  className,
  filterId,
  onRemove,
  editorPages,
}: {
  className?: string;
  filterId: UID;
  onRemove: () => void;
  editorPages: EditorPage[];
}) => {
  const {
    recordFilterType,
    comparisonOptions,
    attributeOptions,
    selectedAttributeId,
    selectedAttributeType,
    selectedComparisonType,
    comparisonPrimaryValue,
    comparisonSecondaryValue,
    setOptions,
    entrySet,
    setMatchOptions,
    selectedSetMatchType,
    currentSubstringValue,
    setSubstringValue,
    selectSetMatchType,
    changeType,
    selectAttributeId,
    selectComparisonType,
    changePrimaryRefValue,
    changeSecondaryRefValue,
  } = useRecordFilter(filterId, editorPages);

  const ValueInputComponent =
    selectedAttributeType === AttributeType.Number
      ? NumberInput
      : selectedAttributeType === AttributeType.Currency
      ? CurrencyInput
      : selectedAttributeType === AttributeType.Date
      ? DateInput
      : TextInput;

  const filterOptions: DropdownItem[] = [
    ...(editorPages.includes(EditorPage.Offers)
      ? [{ id: RecordFilterType.OfferName, label: "Offer name" }]
      : []),
    ...(editorPages.includes(EditorPage.Touchpoints)
      ? [
          { id: RecordFilterType.TargetName, label: "Page name" },
          { id: RecordFilterType.SourceMediumName, label: "Source / medium" },
        ]
      : []),
    { id: RecordFilterType.Attribute, label: "Attribute value" },
    { id: RecordFilterType.None, label: "No filter" },
  ];

  return (
    <div
      className={`${className} flex items-center justify-between bg-slate-100 p-2`}
    >
      <div className="flex flex-wrap gap-x-2 gap-y-1">
        <SingleDropdown
          title="Filter type"
          possibleItems={filterOptions}
          onChange={(filterType) => {
            if (filterType) {
              changeType(filterType as RecordFilterType);
            }
          }}
          selectedId={recordFilterType}
          clearable={false}
        />
        {recordFilterType === RecordFilterType.Attribute ? (
          <SingleDropdown
            title="Attribute"
            possibleItems={attributeOptions}
            onChange={(attributeId?: UID) => selectAttributeId(attributeId)}
            selectedId={selectedAttributeId}
            clearable={false}
          />
        ) : (
          <></>
        )}
        {comparisonOptions ? (
          <SingleDropdown
            title="Comparison"
            possibleItems={comparisonOptions}
            onChange={(comparisonType?: string) =>
              selectComparisonType(comparisonType as ComparisonType)
            }
            selectedId={selectedComparisonType}
            clearable={false}
          />
        ) : (
          <></>
        )}
        {selectedComparisonType &&
        [ComparisonType.Between, ComparisonType.NotBetween].includes(
          selectedComparisonType,
        ) ? (
          <>
            <ValueInputComponent
              title="Start range (inclusive)"
              initialValue={comparisonPrimaryValue || ""}
              onChange={(newValue: string) => changePrimaryRefValue(newValue)}
            />
            <ValueInputComponent
              title="End range (inclusive)"
              initialValue={comparisonSecondaryValue || ""}
              onChange={(newValue: string) => changeSecondaryRefValue(newValue)}
            />
          </>
        ) : (
          <></>
        )}
        {selectedComparisonType &&
        [
          ComparisonType.Equal,
          ComparisonType.NotEqual,
          ComparisonType.GreaterThan,
          ComparisonType.GreaterThanEq,
          ComparisonType.LessThan,
          ComparisonType.LessThanEq,
        ].includes(selectedComparisonType) ? (
          <ValueInputComponent
            title="Value"
            initialValue={comparisonPrimaryValue || ""}
            onChange={(newValue: string) => changePrimaryRefValue(newValue)}
          />
        ) : (
          <></>
        )}
        {!setMatchOptions &&
        (selectedAttributeType === AttributeType.SingleSelect ||
          selectedAttributeType === AttributeType.Checkbox ||
          recordFilterType === RecordFilterType.OfferName) ? (
          <div className="flex items-end h-12 px-2 text-sm text-slate-800">
            is one of
          </div>
        ) : (
          <></>
        )}
        {setMatchOptions ? (
          <SingleDropdown
            title="Comparison"
            possibleItems={setMatchOptions}
            selectedId={selectedSetMatchType}
            onChange={(setMatchType?: string) => {
              if (setMatchType) {
                selectSetMatchType(setMatchType as SetMatchType);
              }
            }}
            clearable={false}
          />
        ) : (
          <></>
        )}
        {setOptions ? (
          <MultiDropdown
            title="Items"
            possibleItems={setOptions}
            selectedIds={entrySet.values}
            onAddItem={(id: UID) => entrySet.addValue(id)}
            onRemoveItem={(id: UID) => entrySet.removeValue(id)}
            onClearItems={() => entrySet.clearValues()}
          />
        ) : (
          <></>
        )}
        {!setOptions &&
        (selectedAttributeType === AttributeType.MultiSelect ||
          selectedAttributeType === AttributeType.SingleSelect) ? (
          <div className="flex items-end h-12 text-slate-600 px-2 text-sm">
            No selection options
          </div>
        ) : (
          <></>
        )}
        {selectedAttributeType === AttributeType.Text ? (
          <>
            <div className="flex items-end h-12 text-slate-800 px-2 text-sm">
              includes
            </div>
            <TextInput
              title="Text"
              initialValue={currentSubstringValue || ""}
              onChange={(newValue: string) => setSubstringValue(newValue)}
            />
          </>
        ) : (
          <></>
        )}
      </div>
      <IconButton
        icon={X}
        onClick={() => onRemove()}
        size={IconButton.sizes.SMALL}
      />
    </div>
  );
};

const FilterIntersection = ({
  className,
  filterUnionId,
  filterIntersectionId,
  editorPages,
}: {
  className?: string;
  filterUnionId: UID;
  filterIntersectionId: UID;
  editorPages: EditorPage[];
}) => {
  const { filterIds, removeFilterIntersection, addFilter, removeFilter } =
    useFilterIntersection(filterIntersectionId);

  return (
    <div
      className={`${className} border-2 flex flex-col rounded w-full p-2 gap-2 `}
    >
      <div className="flex items-center justify-between">
        <div className="flex gap-2 items-center">
          Filter group
          <AddNewButton title="Add filter" onSelect={() => addFilter()} />
        </div>
        <RemoveItemButton
          onRemove={() => removeFilterIntersection(filterUnionId)}
        />
      </div>

      {filterIds.map((filterId) => (
        <RecordFilter
          key={filterId}
          filterId={filterId}
          onRemove={() => removeFilter(filterId)}
          editorPages={editorPages}
        />
      ))}
    </div>
  );
};

const FilterUnion = ({
  className,
  filterUnionId,
  editorPages,
}: {
  className?: string;
  filterUnionId: UID;
  editorPages: EditorPage[];
}) => {
  const { filterIntersectionIds, addFilterIntersection } =
    useFilterUnion(filterUnionId);

  return (
    <div className={`${className} flex flex-col w-full gap-2`}>
      <AddNewButton
        title="Add group"
        onSelect={() => addFilterIntersection()}
      />
      {filterIntersectionIds &&
        filterIntersectionIds.map((filterIntersectionId) => (
          <FilterIntersection
            key={filterIntersectionId}
            filterUnionId={filterUnionId}
            filterIntersectionId={filterIntersectionId}
            editorPages={editorPages}
          />
        ))}
    </div>
  );
};

export { FilterUnion };
