import { useProjectDispatch, useProjectSelector } from "../../hooks/useEditor";
import { UTMParamType } from "../../types/utm";
import { UTMEventType } from "../handlers/handleUTM";
import { createAction } from "../userEventHandlers";

const useUTM = (utmParam: UTMParamType) => {
  const projectDispatch = useProjectDispatch();

  const isUsed = useProjectSelector(
    (project) => project.utmParameters[utmParam].isUsed
  );

  const formulaId = useProjectSelector(
    (project) => project.utmParameters[utmParam].formulaId
  );

  const changeIsUsed = (isUsed: boolean) => {
    projectDispatch(
      createAction[UTMEventType.ToggleUsage]({ utmParam, isUsed })
    );
  };

  return { isUsed, formulaId, changeIsUsed };
};

export { useUTM };
