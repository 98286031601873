import { useMemo } from "react";
import { AttributeTitle } from "./AttributeTitle";
import { Dropdown } from "monday-ui-react-core";
import { UID } from "../../types/uid";

/**
 * Options for Monday dropdown styling:
 *   container
 *   control
 *   placeholder
 *   indicatorsContainer
 *   dropdownIndicator
 *   clearIndicator
 *   singleValue
 *   input
 *   valueContainer
 *   menu
 *   option
 *   indicatorSeparator
 *   group
 *   groupHeading
 **/
const extraStyles = (baseStyles: object) => ({
  ...baseStyles,
  control: (base: object) => ({
    ...base,
    backgroundColor: "transparent",
    display: "flex",
    flexWrap: "none",
    minWidth: "fit-content",
    alignItems: "center",
    width: "100%",
  }),
  singleValue: (base: object) => ({
    ...base,
    position: "relative",
    backgroundColor: "transparent",
    maxWidth: "none",
    overflow: "visible",
    whiteSpace: "nowrap",
    paddingLeft: "0.25em",
    top: 0,
    transform: "",
    height: "100%",
  }),
  placeholder: (base: object) => ({
    ...base,
    display: "flex",
    position: "relative",
    top: "",
    bottom: "",
    transform: "",
    height: "100%",
    backgroundColor: "transparent",
    alignItems: "center",
    minWidth: "fit-content",
    whiteSpace: "nowrap",
    paddingLeft: "0.25em",
  }),
  valueContainer: (base: object) => ({
    ...base,
    backgroundColor: "transparent",
    flexWrap: "wrap-none",
    display: "flex",
    height: "100%",
    overflow: "visible",
    paddingLeft: "0.25em",
    paddingRight: "0.25em",
    padding: "0px",
    marginBottom: "0px",
    margin: "0px",
  }),
  input: (base: object) => ({
    ...base,
    position: "relative",
    marginLeft: "6px",
    backgroundColor: "transparent",
  }),
  container: (base: object) => ({
    ...base,
    borderColor: "rgb(203 213 225)", // border-slate-300
    backgroundColor: "transparent",
    height: "",
    minHeight: "",
    minWidth: "fit-content",
  }),
  indicatorsContainer: (base: object) => ({
    ...base,
    backgroundColor: "transparent",
  }),
  menu: (base: object) => ({
    ...base,
    minWidth: "fit-content",
    width: "fit-content",
    maxWidth: "none",
  }),
  option: (base: object) => ({
    ...base,
    whiteSpace: "no-wrap",
  }),
});

type DropdownItem = {
  id: UID;
  label: string;
};

const SingleDropdown = ({
  className,
  possibleItems,
  selectedId,
  onChange = () => {},
  title,
  clearable,
}: {
  className?: string;
  possibleItems: DropdownItem[];
  selectedId?: UID;
  onChange?: (id?: UID) => void;
  title?: string;
  clearable?: boolean;
}) => {
  const dropdownOptions = useMemo(
    () => possibleItems.map(({ id, label }) => ({ label, value: id })),
    [possibleItems]
  );

  const selectedValue = useMemo(
    () => possibleItems.find(({ id }) => id === selectedId),
    [selectedId, possibleItems]
  );

  return (
    <div className={`${className} flex flex-col gap-0.5`}>
      <AttributeTitle text={title} />
      <div>
        <Dropdown
          placeholder="Select value"
          extraStyles={extraStyles}
          options={dropdownOptions}
          value={
            selectedValue
              ? [{ label: selectedValue.label, value: selectedValue.id }]
              : null
          }
          onChange={(newValue: { label: string; value: string } | null) => {
            onChange(newValue ? newValue.value : undefined);
          }}
          size={"Medium"}
          clearable={clearable}
        />
      </div>
    </div>
  );
};

const MultiDropdown = ({
  className,
  possibleItems,
  selectedIds = [],
  onChange = () => {},
  onAddItem = () => {},
  onRemoveItem = () => {},
  onClearItems = () => {},
  title,
  clearable,
}: {
  className?: string;
  possibleItems: DropdownItem[];
  selectedIds?: UID[];
  onChange?: (ids: UID[]) => void;
  onAddItem?: (id: UID) => void;
  onRemoveItem?: (id: UID) => void;
  onClearItems?: () => void;
  title?: string;
  clearable?: boolean;
}) => {
  const dropdownOptions = useMemo(
    () => possibleItems.map(({ id, label }) => ({ label, value: id })),
    [possibleItems]
  );

  const selectedValues = useMemo(
    () => possibleItems.filter(({ id }) => selectedIds.includes(id)),
    [selectedIds, possibleItems]
  );

  return (
    <div className={`${className} flex flex-col gap-0.5 w-fit`}>
      <AttributeTitle text={title} />
      <div className="w-fit">
        <Dropdown
          placeholder="Select value"
          options={dropdownOptions}
          value={selectedValues.map(({ id, label }) => ({ label, value: id }))}
          onClear={() => {
            onChange([]);
            onClearItems();
          }}
          onOptionSelect={({
            value: addId,
          }: {
            label: string;
            value: string;
          }) => {
            onChange([...selectedIds, addId]);
            onAddItem(addId);
          }}
          onOptionRemove={({
            value: removedId,
          }: {
            label: string;
            value: string;
          }) => {
            onChange(selectedIds.filter((id) => id !== removedId));
            onRemoveItem(removedId);
          }}
          extraStyles={extraStyles}
          size={"Medium"}
          multi
          multiline
          clearable={clearable}
        />
      </div>
    </div>
  );
};

export type { DropdownItem };

export { SingleDropdown, MultiDropdown };
