import { Minus, Plus } from "@phosphor-icons/react";
import { Button, IconButton } from "monday-ui-react-core";
import { useCounter } from "../hooks/useCounters";

const CounterCard = ({
  className,
  counterId,
}: {
  className?: string;
  counterId: number;
}) => {
  const { value, name, increment, decrement } = useCounter(counterId);

  return (
    <div
      className={`
        ${className} flex w-48 h-fit flex-col items-center text-center
        bg-white rounded-md border-slate-200 border gap-2 p-4
        shadow-md select-none
      `}
    >
      <div className="font-bold">{name}</div>
      <div>has a value of</div>
      <div className="text-2xl">{value}</div>
      <div>clicks</div>
      <div className="flex gap-2">
        <IconButton
          size={Button.sizes.SMALL}
          color={Button.colors.NEGATIVE}
          kind={Button.kinds.PRIMARY}
          icon={Minus}
          onClick={() => decrement()}
        ></IconButton>
        <IconButton
          size={Button.sizes.SMALL}
          color={Button.colors.POSITIVE}
          kind={Button.kinds.PRIMARY}
          icon={Plus}
          onClick={() => increment()}
        ></IconButton>
      </div>
    </div>
  );
};

export { CounterCard };
