import {
  Button, Chips,
  Divider,
  Heading,
  Skeleton,
  Text,
  TextField
} from "monday-ui-react-core";
import React from "react";
import { Account, useAccount } from "../../hooks/useAccount";
import { toast } from "react-toastify";
import { useAuth } from "../../AuthContext";
import { Pencil, Trash, User } from "@phosphor-icons/react";

const MyAccount = ({ className }: { className?: string }) => {

  const {
    account,
    isLoading,
    roleLabel,
    updateAccount
  } = useAccount();

  const [accountData, setAccountData] = React.useState<any>({
    first_name: account?.first_name || "",
    last_name: account?.last_name || "",
    email: account?.email || "",
    is_active: account?.is_active || true
  });

  const { signOut } = useAuth();

  const update = () => {
    if (!account) {
      return;
    }
    updateAccount(accountData).then(() => {
      toast.success("Account updated");
    });
  };

  if (!account) {
    return <></>;
  }

  return (
    <div className={`${className} flex flex-col gap-2 bg-slate-50`}>

      <Heading type={Heading.types.h1} value="My Account" />
      {
        account.roles.map(role => {
          return <Chips key={role}
                        className="w-48"
                        label={roleLabel(role)}
                        color={Chips.colors.POSITIVE}
                        readOnly
                        leftIcon={User}

          />;
        })
      }
      <Divider />

      <div className="flex flex-col w-1/2 gap-2">

        <Text weight={"bold"}>First Name</Text>
        <TextField className="bg-white" value={account.first_name ? account.first_name : ""}
                   onChange={(value) => setAccountData({ ...accountData, first_name: value })}
        />

        <Text weight={"bold"}>Last Name</Text>
        <TextField className="bg-white" value={account.last_name ? account.last_name : ""}
                   onChange={(value) => setAccountData({ ...accountData, last_name: value })}
        />

        <Text weight={"bold"}>Email</Text>
        <TextField value={account.email} disabled={true} />

        <Button onClick={update}
                className="w-1/4"
                size={Button.sizes.SMALL}
                color={Button.colors.POSITIVE}
                kind={Button.kinds.SECONDARY}
                rightIcon={Pencil}>Update</Button>

      </div>

    </div>
  );
};

export { MyAccount };