const HorizontalCard = ({
  className,
  headerClassName,
  bodyClassName,
  header,
  children,
}: {
  className?: string;
  headerClassName?: string;
  bodyClassName?: string;
  header?: string | JSX.Element | JSX.Element[];
  children?: string | JSX.Element | JSX.Element[];
}) => {
  return (
    <div className={`${className} flex rounded-md border bg-slate-50 shadow `}>
      <div
        className={`${headerClassName} min-w-fit w-64 p-2 bg-slate-200 border-slate-400 `}
      >
        {header}
      </div>
      <div className={`${bodyClassName} w-full pb-2`}>{children}</div>
    </div>
  );
};

const VerticalCard = ({
  className,
  headerClassName,
  bodyClassName,
  header,
  children,
}: {
  className?: string;
  headerClassName?: string;
  bodyClassName?: string;
  header?: string | JSX.Element | JSX.Element[];
  children?: string | JSX.Element | JSX.Element[];
}) => {
  return (
    <div
      className={`${className} flex flex-col rounded-md  border bg-slate-50 shadow`}
    >
      <div
        className={`${headerClassName} w-full p-2 bg-slate-200 border-slate-400 `}
      >
        {header}
      </div>
      <div className={`${bodyClassName} w-full`}>{children}</div>
    </div>
  );
};

export { VerticalCard, HorizontalCard };
