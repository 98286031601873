import { useProjectDispatch, useProjectSelector } from "../../hooks/useEditor";
import { FormulaBlockType, SpreadMethodType } from "../../types/formula";
import { UID, generateUid } from "../../types/uid";
import { FormulaBlockEventType } from "../handlers/handleFormulaBlocks";
import { createAction } from "../userEventHandlers";

const useFormulaBlock = (formulaId: UID, blockId: UID) => {
  const projectDispatch = useProjectDispatch();
  const formulaBlockType = useProjectSelector(
    (project) => project.formulas[formulaId].blockDefinitions[blockId].type
  );

  const formulaBlockValue = useProjectSelector(
    (project) => project.formulas[formulaId].blockDefinitions[blockId].value
  );

  // const selectedAttributeId = useProjectSelector(
  //   (project) => formulaBlockType === FormulaBlockType.Attribute ?
  //   project.attributes.definitions[formulaBlockValue as UID].type
  //   )

  const formulaBlockSpreadMethod = useProjectSelector(
    (project) =>
      project.formulas[formulaId].blockDefinitions[blockId].spreadMethod
  );

  const formulaBlockSeparator = useProjectSelector(
    (project) => project.formulas[formulaId].blockDefinitions[blockId].separator
  );

  const editValue = (newValue: string) => {
    projectDispatch(
      createAction[FormulaBlockEventType.EditValue]({
        formulaId,
        blockId,
        newValue,
      })
    );
  };

  const chooseSpreadMethod = (newSpreadMethod: SpreadMethodType) => {
    projectDispatch(
      createAction[FormulaBlockEventType.EditSpreadMethod]({
        formulaId,
        blockId,
        newSpreadMethod,
      })
    );
  };

  const editSeparator = (newSeparator: string) => {
    projectDispatch(
      createAction[FormulaBlockEventType.EditSeparator]({
        formulaId,
        blockId,
        newSeparator,
      })
    );
  };

  const remove = () => {
    projectDispatch(
      createAction[FormulaBlockEventType.Remove]({ formulaId, blockId })
    );
  };

  return {
    formulaBlockType,
    formulaBlockValue,
    formulaBlockSpreadMethod,
    formulaBlockSeparator,
    editValue,
    chooseSpreadMethod,
    editSeparator,
    remove,
  };
};

const useFormula = (formulaId: UID) => {
  const projectDispatch = useProjectDispatch();

  const formulaBlockIds: UID[] | undefined = useProjectSelector(
    (project) => project.formulas[formulaId]?.blockOrdering
  );

  const formulaBlockDefinitions = useProjectSelector(
    (project) => project.formulas[formulaId]?.blockDefinitions
  );

  const addBlock = (
    blockType: FormulaBlockType,
    spreadMethod: SpreadMethodType
  ) => {
    const blockId = generateUid();

    projectDispatch(
      createAction[FormulaBlockEventType.Add]({
        formulaId,
        blockId,
        blockType,
        spreadMethod,
      })
    );
  };

  const moveBlock = (blockId: UID, newIndex: number) => {
    projectDispatch(
      createAction[FormulaBlockEventType.Move]({ formulaId, blockId, newIndex })
    );
  };

  return { addBlock, moveBlock, formulaBlockIds, formulaBlockDefinitions };
};

export { useFormula, useFormulaBlock };
