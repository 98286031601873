import { UID } from "./uid";

type SelectRow = { name: string; utmTerm: string; urlTerm: string };

type Select = {
  title: string;
  usesURL: boolean;
  usesUTM: boolean;
  rowDefinitions: {
    [rowId: UID]: SelectRow;
  };
  rowOrdering: UID[];
};

enum SelectToggle {
  UTM = "utm",
  URL = "url",
}

export { SelectToggle };
export type { Select, SelectRow };
