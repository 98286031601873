import { useProjectSelector } from "../../hooks/useEditor";
import { EditorPage } from "../../types/editorPage";
import { UID } from "../../types/uid";
import { ProjectState } from "../projectState";
import { useAttributes } from "./useAttributes";
import { useSources } from "./useSources";

type FlatTouchpoint = {
  sourceId: UID;
  sourceUtmValue: string;
  sourceName: string;
  mediumId: UID;
  mediumUtmValue: string;
  mediumName: string;
  attributeDefinitions: Record<UID, string>;
};

const useTouchpoints = () => {
  const { sourceIds } = useSources();

  const sourceDefinitions = useProjectSelector(
    (project: ProjectState) => project.touchpoints.sourceDefinitions
  );

  const mediumDefinitions = useProjectSelector(
    (project: ProjectState) => project.touchpoints.mediumDefinitions
  );

  const { attributeIds } = useAttributes(EditorPage.Touchpoints);

  const flatTouchpoints: FlatTouchpoint[] = (() => {
    const res: FlatTouchpoint[] = [];
    sourceIds.forEach((sourceId: UID) => {
      const sourceDefinition = sourceDefinitions[sourceId];
      const sourceUtmValue = sourceDefinition.utmValue;
      const mediumIds = sourceDefinition.mediumOrdering;
      const sourceName = sourceDefinition.title;
      mediumIds.forEach((mediumId: UID) => {
        const mediumDefinition = mediumDefinitions[mediumId];
        const mediumUtmValue = mediumDefinition.utmValue;
        const mediumName = mediumDefinition.title;

        const attributeDefinitions: Record<UID, string> = {};
        attributeIds.forEach((attributeId: UID) => {
          attributeDefinitions[attributeId] =
            mediumDefinition.attributeValues[attributeId]; // Convert to human readable if select element
        });

        res.push({
          sourceId,
          sourceUtmValue,
          sourceName,
          mediumId,
          mediumUtmValue,
          mediumName,
          attributeDefinitions,
        });
      });
    });
    return res;
  })();

  return { flatTouchpoints };
};

export { useTouchpoints };
