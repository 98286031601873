import produce from "immer";
import { EventGroup, HandlerLookup, makeGeneralPayload } from "../eventUtil";
import { createAction } from "../userEventHandlers";
import { UTMParamType } from "../../types/utm";
import { BoolType } from "../../types/filter";

enum UTMEventType {
  ToggleUsage = "utm/toggleUsage",
}

type UTMEvent = EventGroup<{
  [UTMEventType.ToggleUsage]: {
    utmParam: UTMParamType;
    isUsed: boolean;
  };
}>;

const utmHandlers: HandlerLookup<UTMEvent> = {
  [UTMEventType.ToggleUsage]: {
    generalizer: ({ payload: { utmParam, isUsed } }) =>
      makeGeneralPayload({
        payloadA: utmParam as UTMParamType,
        payloadB: isUsed ? BoolType.True : BoolType.False,
      }),
    specifier: ({ payloadA, payloadB }) => {
      return createAction[UTMEventType.ToggleUsage]({
        utmParam: payloadA as UTMParamType,
        isUsed: payloadB === BoolType.True,
      });
    },
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { utmParam, isUsed } = action.payload;
        draftState.utmParameters[utmParam].isUsed = isUsed;
      }),
    validator: (state, action) =>
      action.payload.utmParam in state.utmParameters,
  },
};

export { utmHandlers, UTMEventType };
export type { UTMEvent };
