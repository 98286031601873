import {
  ActionCreatorLookup,
  EventTemplate,
  HandlerLookup,
  UserEventType,
} from "./eventUtil";
import {
  AttributeEvent,
  AttributeEventType,
  attributeHandlers,
} from "./handlers/handleAttributes";
import {
  CounterEvent,
  CounterEventType,
  counterHandlers,
} from "./handlers/handleCounter";
import {
  MediumEvent,
  MediumEventType,
  mediumHandlers,
} from "./handlers/handleMedium";
import {
  MessageEvent,
  MessageEventType,
  messageHandlers,
} from "./handlers/handleMessage";
import {
  OfferEvent,
  OfferEventType,
  offerHandlers,
} from "./handlers/handleOffers";
import {
  SelectFieldEvent,
  SelectFieldEventType,
  selectFieldHandlers,
} from "./handlers/handleSelectField";
import {
  selectHandlers,
  SelectEvent,
  SelectEventType,
} from "./handlers/handleSelects";
import {
  targetHandlers,
  TargetEvent,
  TargetEventType,
} from "./handlers/handleTargets";
import {
  SourceEvent,
  SourceEventType,
  sourceHandlers,
} from "./handlers/handleSource";
import {
  UtilityEvent,
  UtilityEventType,
  utilityHandlers,
} from "./handlers/utility";
import {
  FormulaBlockEvent,
  FormulaBlockEventType,
  formulaBlockHandlers,
} from "./handlers/handleFormulaBlocks";
import { SetEvent, SetEventType, setHandlers } from "./handlers/handleSet";
import {
  FilterEvent,
  FilterEventType,
  filterHandlers,
} from "./handlers/handleFilters";
import { UTMEvent, UTMEventType, utmHandlers } from "./handlers/handleUTM";
import {
  ColumnFormatEvent,
  ColumnFormatEventType,
  columnFormatHandlers,
} from "./handlers/handleColumnFormats";
import {
  ColumnEvent,
  ColumnEventType,
  columnHandlers,
} from "./handlers/handleColumns";
import {
  FilterUnionEvent,
  FilterUnionEventType,
  filterUnionHandlers,
} from "./handlers/handleFilterUnion";
import {
  filterIntersectionHandlers,
  FilterIntersectionEventType,
  FilterIntersectionEvent,
} from "./handlers/handleFilterIntersection";
import {
  exportFormatHandlers,
  ExportFormatEventType,
  ExportFormatEvent,
} from "./handlers/handleExportFormats";
import {
  tabListHandlers,
  TabListEventType,
  TabListEvent,
} from "./handlers/handleTabList";
import {
  exportTabHandlers,
  ExportTabEventType,
  ExportTabEvent,
} from "./handlers/handleExportTabs";
import {
  debugEventHandlers,
  DebugEventType,
  DebugEvent,
} from "./handlers/handleDebug";

type UserEvent =
  | DebugEvent
  | CounterEvent
  | MessageEvent
  | UtilityEvent
  | AttributeEvent
  | SelectEvent
  | SelectFieldEvent
  | OfferEvent
  | SourceEvent
  | MediumEvent
  | TargetEvent
  | FormulaBlockEvent
  | SetEvent
  | FilterEvent
  | UTMEvent
  | ColumnFormatEvent
  | ColumnEvent
  | FilterUnionEvent
  | FilterIntersectionEvent
  | ExportFormatEvent
  | TabListEvent
  | ExportTabEvent;

const userEventTypes: UserEventType[] = [
  DebugEventType,
  CounterEventType,
  MessageEventType,
  UtilityEventType,
  AttributeEventType,
  SelectEventType,
  SelectFieldEventType,
  OfferEventType,
  SourceEventType,
  MediumEventType,
  TargetEventType,
  FormulaBlockEventType,
  SetEventType,
  FilterEventType,
  UTMEventType,
  ColumnFormatEventType,
  ColumnEventType,
  FilterUnionEventType,
  FilterIntersectionEventType,
  ExportFormatEventType,
  TabListEventType,
  ExportTabEventType,
]
  .map(Object.values)
  .flat();

const handlers: HandlerLookup<UserEvent> = {
  ...debugEventHandlers,
  ...counterHandlers,
  ...messageHandlers,
  ...utilityHandlers,
  ...attributeHandlers,
  ...selectHandlers,
  ...selectFieldHandlers,
  ...offerHandlers,
  ...sourceHandlers,
  ...mediumHandlers,
  ...targetHandlers,
  ...formulaBlockHandlers,
  ...setHandlers,
  ...filterHandlers,
  ...utmHandlers,
  ...columnFormatHandlers,
  ...columnHandlers,
  ...filterUnionHandlers,
  ...filterIntersectionHandlers,
  ...exportFormatHandlers,
  ...tabListHandlers,
  ...exportTabHandlers,
};

const createActionCreator = <CustomEvent extends EventTemplate>(
  eventType: CustomEvent["eventType"],
) => {
  return (payload: CustomEvent["payload"]): CustomEvent => {
    return {
      eventType: eventType,
      payload,
    } as CustomEvent;
  };
};

const createAction: ActionCreatorLookup<UserEvent> = userEventTypes.reduce<
  ActionCreatorLookup<UserEvent>
>(
  (acc, et) => ({ ...acc, [et]: createActionCreator(et) }),
  {} as ActionCreatorLookup<UserEvent>,
);

export type { UserEvent };
export { handlers, createAction };
