import { Divider, EditableHeading, Heading } from "monday-ui-react-core";
import React from "react";
import { toast } from "react-toastify";
import { useAgency } from "../../../hooks/useAgency";

const AgencyName = ({ className, agencyId }: { className?: string, agencyId: number }) => {

  const {
    agency,
    isLoading,
    renameAgency
  } = useAgency(agencyId);

  const handleChangeAgencyName = (e: React.ChangeEvent<HTMLInputElement>) => {

    if (!agency || e.target.value === agency.agency_name || e.target.value === "") {
      return;
    }

    renameAgency({ agency_name: e.target.value }).then(() => {
      toast.success("Agency name updated");
    });
  };

  if (isLoading || !agency) {
    return (
      <></>
    );
  }

  return (
    <div className={`${className}`}>
      <EditableHeading type={Heading.types.h2} value={agency.agency_name} onBlur={
        (e) => handleChangeAgencyName(e as unknown as React.ChangeEvent<HTMLInputElement>)}
      />
      <Divider />
    </div>
  );
};

export { AgencyName };