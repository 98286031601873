import produce from "immer";
import { EventGroup, HandlerLookup, makeGeneralPayload } from "../eventUtil";
import { createAction } from "../userEventHandlers";
import { UID } from "../../types/uid";
import { FilterEntityType, FilterType } from "../../types/filter";
import { RecordFilterType } from "../hooks/useRecordFilter";

enum FilterIntersectionEventType {
  AddFilter = "filterIntersection/addFilter",
  DeleteFilter = "filterIntersection/deleteFilter",
}

type FilterIntersectionEvent = EventGroup<{
  [FilterIntersectionEventType.AddFilter]: {
    intersectionId: UID;
    filterId: UID;
    entrySetId: UID;
  };
  [FilterIntersectionEventType.DeleteFilter]: {
    intersectionId: UID;
    filterId: UID;
  };
}>;

const filterIntersectionHandlers: HandlerLookup<FilterIntersectionEvent> = {
  [FilterIntersectionEventType.AddFilter]: {
    generalizer: ({ payload: { intersectionId, filterId, entrySetId } }) =>
      makeGeneralPayload({
        payloadA: intersectionId,
        payloadB: filterId,
        payloadC: entrySetId,
      }),
    specifier: ({ payloadA, payloadB, payloadC }) => {
      return createAction[FilterIntersectionEventType.AddFilter]({
        intersectionId: payloadA,
        filterId: payloadB,
        entrySetId: payloadC,
      });
    },
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { intersectionId, filterId, entrySetId } = action.payload;
        draftState.sets[intersectionId].push(filterId);

        draftState.filters[filterId] = {
          type: FilterType.None,
          entityType: FilterEntityType.Offer,
          userFriendlyType: RecordFilterType.None,
          entrySetId,
        };
      }),
    validator: (state, action) =>
      action.payload.intersectionId in state.sets &&
      !state.sets[action.payload.intersectionId].includes(
        action.payload.filterId,
      ),
  },
  [FilterIntersectionEventType.DeleteFilter]: {
    generalizer: ({ payload: { intersectionId, filterId } }) =>
      makeGeneralPayload({
        payloadA: intersectionId,
        payloadB: filterId,
      }),
    specifier: ({ payloadA, payloadB }) => {
      return createAction[FilterIntersectionEventType.DeleteFilter]({
        intersectionId: payloadA,
        filterId: payloadB,
      });
    },
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { intersectionId } = action.payload;
        draftState.sets[intersectionId] = state.sets[intersectionId].filter(
          (intersectionId) => intersectionId !== action.payload.filterId,
        );
      }),
    validator: (state, action) =>
      action.payload.intersectionId in state.sets &&
      state.sets[action.payload.intersectionId].includes(
        action.payload.filterId,
      ),
  },
};

export { filterIntersectionHandlers, FilterIntersectionEventType };
export type { FilterIntersectionEvent };
