import { Reducer } from "@reduxjs/toolkit";

type DebugState = {
  offlineActivated: boolean;
};

const initialState: DebugState = {
  offlineActivated: false,
};

enum DebugActionType {
  OfflineActivated = "debug/offlineActivated",
  OfflineDeactivated = "debug/offlineDeactivated",
}

type OfflineActivatedAction = {
  type: DebugActionType.OfflineActivated;
};

type OfflineDeactivatedAction = {
  type: DebugActionType.OfflineDeactivated;
};

type DebugAction = OfflineActivatedAction | OfflineDeactivatedAction;

const debugReducer: Reducer<DebugState, DebugAction> = (
  state: DebugState = initialState,
  action: DebugAction
): DebugState => {
  switch (action.type) {
    case DebugActionType.OfflineActivated:
      return {
        ...state,
        offlineActivated: true,
      };
    case DebugActionType.OfflineDeactivated:
      return {
        ...state,
        offlineActivated: false,
      };
    default:
      return state;
  }
};

export { debugReducer, DebugActionType };

export type {
  DebugAction,
  DebugState,
  OfflineActivatedAction,
  OfflineDeactivatedAction,
};
