import {
  EditableHeading,
  Heading,
  TextField,
  Toggle,
} from "monday-ui-react-core";
import { useProjectName } from "../../hooks/useProjectName";
import { useEditorDebugInfo } from "../../hooks/useEditorDebugInfo";
import { useCounters } from "../hooks/useCounters";
import { useMessage } from "../hooks/useMessage";
import { CounterCard } from "../widgets/CounterCard";
import { ProjectId } from "../../types/util";
import { Logo } from "../../components/Logo";
import { useDebug } from "../../hooks/useDebug";

const DebugCard = ({
  className,
  children,
}: {
  className?: string;
  children: JSX.Element | JSX.Element[];
}) => (
  <div
    className={`${className} flex flex-col gap-2 h-fit rounded-sm border 
                              border-slate-400 p-2`}
  >
    {children}
  </div>
);

const TestEditor = ({
  className,
  projectId,
}: {
  className?: string;
  projectId: ProjectId;
}) => {
  const { projectName, onEditProjectName } = useProjectName(projectId);
  const { eventQueue, liveState, verifiedState, lastVerifiedEventID } =
    useEditorDebugInfo();
  const { counterIds } = useCounters();
  const { value: messageValue, edit: editMessage } = useMessage();
  const { isOffline, goOffline, goOnline } = useDebug();

  return (
    <div className={`${className} bg-slate-200`}>
      <nav className="bg-slate-100 h-12 flex justify-start items-center px-4 gap-4 sticky top-0 z-10">
        <Logo />
        <EditableHeading
          type={Heading.types.h4}
          value={projectName}
          onFinishEditing={(newVal) => onEditProjectName(newVal)}
        />
      </nav>
      <div className="w-full h-screen overflow-auto">
        <div className="flex sticky flex-col gap-4 py-4">
          <div className="flex justify-center">
            <div className="font-bold text-lg">Test Editor</div>
          </div>
          <div className="flex gap-2 w-full justify-center items-center">
            {counterIds.map((counterId) => (
              <CounterCard key={counterId} counterId={counterId} />
            ))}
            <div
              className="
        flex w-64 h-fit flex-col items-center text-center
        bg-white rounded-md border-slate-200 border gap-2 p-4
        shadow-md
        "
            >
              <div className="select-none">
                Collaboratively edit the message
              </div>
              <TextField
                value={messageValue}
                onChange={(value) => editMessage(value)}
                debounceRate={300}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 p-4 gap-4 font-mono">
          <DebugCard className="col-span-4">
            <div>Verified State</div>
            <pre className="text-xs whitespace-pre-wrap">
              {JSON.stringify(verifiedState, null, 2)}
            </pre>
          </DebugCard>
          <div className="col-span-4 flex flex-col gap-2">
            <DebugCard>
              <div>Last verified event ID</div>
              <div className="text-xs">{lastVerifiedEventID || "<none>"}</div>
            </DebugCard>
            <DebugCard>
              <div className="flex justify-between">
                Event Publisher{" "}
                <Toggle
                  isSelected={!isOffline}
                  onChange={() => (isOffline ? goOnline() : goOffline())}
                />
              </div>
            </DebugCard>
            <DebugCard>
              <div>Event Queue</div>
              <div>
                {eventQueue.map((event, idx) => (
                  <div
                    key={idx}
                    style={{ wordBreak: "break-all" }}
                    className={`${
                      idx === 0
                        ? "bg-blue-100"
                        : idx % 2 === 1
                        ? "bg-slate-50"
                        : "bg-slate-100"
                    } p-1 border text-xs`}
                  >
                    {JSON.stringify(event)}
                  </div>
                ))}
              </div>
            </DebugCard>
          </div>
          <DebugCard className="col-span-4">
            <div>Live State</div>
            <pre className="text-xs whitespace-pre-wrap">
              {JSON.stringify(liveState, null, 2)}
            </pre>
          </DebugCard>
        </div>
      </div>
    </div>
  );
};

export { TestEditor };
