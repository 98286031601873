import { useProjectDispatch, useProjectSelector } from "../../hooks/useEditor";
import { Column } from "../../types/columnFormat";
import { SelectRow } from "../../types/select";
import { UID, generateUid } from "../../types/uid";
import { UTM, UTMParamType, getUTMCode } from "../../types/utm";
import { ColumnFormatEventType } from "../handlers/handleColumnFormats";
import { ColumnEventType } from "../handlers/handleColumns";
import { ProjectState } from "../projectState";
import { createAction } from "../userEventHandlers";
import { ColumnExport } from "../util/exportFileUtil";
import {
  defineRecord,
  extractReadableAttributeValueList,
} from "../util/formulaUtil";
import { PartialRecord, FullRecord } from "../../types/record";
import { useRecords } from "./useRecords";
import { DropdownItem } from "../attributes/Dropdown";
import { ExtraRecordAttributeType } from "./useRecordAttributes";
import { FormulaBlockEventType } from "../handlers/handleFormulaBlocks";

const useColumnFormat = (columnFormatId: UID) => {
  const projectDispatch = useProjectDispatch();
  const name = useProjectSelector(
    (project: ProjectState) =>
      project.columnFormats.definitions[columnFormatId].name,
  );

  const columnIds = useProjectSelector(
    (project: ProjectState) =>
      project.columnFormats.definitions[columnFormatId].columnOrdering,
  );

  const duplicate = () =>
    projectDispatch(
      createAction[ColumnFormatEventType.Duplicate]({
        referenceColumnFormatId: columnFormatId,
        newColumnFormatId: generateUid(),
      }),
    );

  const remove = () =>
    projectDispatch(
      createAction[ColumnFormatEventType.Delete]({
        columnFormatId,
      }),
    );

  const rename = (newName: string) =>
    projectDispatch(
      createAction[ColumnFormatEventType.Rename]({ columnFormatId, newName }),
    );

  const createColumn = () => {
    projectDispatch(
      createAction[ColumnEventType.Create]({
        columnFormatId,
        columnId: generateUid(),
      }),
    );
  };

  const moveColumn = (columnId: UID, newIndex: number) => {
    projectDispatch(
      createAction[ColumnEventType.Move]({
        columnId,
        columnFormatId,
        newIndex,
      }),
    );
  };

  return {
    name,
    columnIds,
    duplicate,
    remove,
    rename,
    createColumn,
    moveColumn,
  };
};

const useColumnFormats = () => {
  const projectDispatch = useProjectDispatch();
  const columnFormatDefinitions = useProjectSelector(
    (project: ProjectState) => project.columnFormats.definitions,
  );

  const columnFormatIds = useProjectSelector(
    (project: ProjectState) => project.columnFormats.ordering,
  );

  const columnFormatOptions: DropdownItem[] = columnFormatIds.map(
    (columnFormatId) => ({
      id: columnFormatId,
      label: columnFormatDefinitions[columnFormatId].name,
    }),
  );

  const create = () => {
    projectDispatch(
      createAction[ColumnFormatEventType.Create]({
        columnFormatId: generateUid(),
      }),
    );
  };

  return {
    columnFormatDefinitions,
    columnFormatIds,
    columnFormatOptions,
    create,
  };
};

const getBaseLink = (record: FullRecord) => {
  const linkFormula = record.targetPair?.value.formulaId;
  if (!linkFormula) return undefined;
  const link = record.formulaResults[linkFormula];
  return link;
};

const getFullLink = (
  record: FullRecord,
  utmParameters: {
    [param in UTMParamType]: UTM;
  },
) => {
  const link = getBaseLink(record);
  if (!link) return undefined;
  const formulaicParamString: string = [
    UTMParamType.Campaign,
    UTMParamType.Content,
    UTMParamType.Term,
    UTMParamType.Id,
  ]
    .filter((param: UTMParamType) => utmParameters[param].isUsed)
    .map(
      (param: UTMParamType) =>
        `${getUTMCode(param)}=${record.formulaResults[param]}`,
    )
    .join("&");

  const utmSource = record.sourcePair?.value.utmValue;
  const utmMedium = record.mediumPair?.value.utmValue;
  if (!utmMedium || !utmSource) return undefined;
  const utmParamString = `${formulaicParamString}&utm_source=${utmSource}&utm_medium=${utmMedium}`;
  const separator = link.includes("?") ? "&" : "?";
  return `${link}${separator}${utmParamString}`;
};

const deriveExtraColumnTypes = (
  record: FullRecord,
  utmParameters: {
    [param in UTMParamType]: UTM;
  },
): { [extraAttributeType: string]: string | undefined } => ({
  [ExtraRecordAttributeType.BaseLink]: getBaseLink(record),
  [ExtraRecordAttributeType.UTMLink]: getFullLink(record, utmParameters),
  [ExtraRecordAttributeType.OfferName]: record.offerPair?.value.title,
  [ExtraRecordAttributeType.TargetName]: record.targetPair?.value.name,
  [ExtraRecordAttributeType.SourceName]: record.sourcePair?.value.title,
  [ExtraRecordAttributeType.UTMSource]: record.sourcePair?.value.utmValue,
  [ExtraRecordAttributeType.MediumName]: record.mediumPair?.value.title,
  [ExtraRecordAttributeType.UTMMedium]: record.mediumPair?.value.utmValue,
  [ExtraRecordAttributeType.UTMCampaign]:
    record.formulaResults[UTMParamType.Campaign],
  [ExtraRecordAttributeType.UTMContent]:
    record.formulaResults[UTMParamType.Content],
  [ExtraRecordAttributeType.UTMId]: record.formulaResults[UTMParamType.Id],
  [ExtraRecordAttributeType.UTMTerm]: record.formulaResults[UTMParamType.Term],
});

const useColumnExport = () => {
  const { columnFormatDefinitions } = useColumnFormats();
  const { attributeDefinitions, entityDefinitions, selectDefinitions } =
    useRecords();

  const utmParameters = useProjectSelector(
    (project: ProjectState) => project.utmParameters,
  );

  const computeColumnExport = (
    columnFormatId: UID,
    utmRecords: PartialRecord[],
  ): ColumnExport => {
    const columnFormat = columnFormatDefinitions[columnFormatId];
    const columns: Column[] = columnFormat.columnOrdering.map(
      (columnId: UID) => {
        const columnValue: Column = columnFormat.columnDefinitions[columnId];
        return {
          ...columnValue,
          attributeId: columnValue.attributeId
            ? columnValue.attributeId
            : generateUid(),
        };
      },
    );

    const rows: { [attributeId: UID]: string }[] = [];

    const addedRowStrings = new Set<string>();

    const headers: { key: UID; label: string }[] = [];
    columns.forEach(({ attributeId, name }) => {
      if (!attributeId) return;
      headers.push({ key: attributeId, label: name });
    });

    utmRecords.forEach((utmPartialRecord: PartialRecord) => {
      const utmRecord = defineRecord(utmPartialRecord, entityDefinitions);
      const attributeValueLookup = {
        ...utmRecord.mediumPair?.value.attributeValues,
        ...utmRecord.offerPair?.value.attributeValues,
        ...utmRecord.chosenAttributeValues,
      };
      const extraAttributes = deriveExtraColumnTypes(utmRecord, utmParameters);
      const rowValues: { [attributeId: UID]: string } = {};
      columns.forEach(({ attributeId }) => {
        if (!attributeId) return;
        if (attributeId in extraAttributes) {
          rowValues[attributeId] = extraAttributes[attributeId] || "";
        } else {
          rowValues[attributeId] = extractReadableAttributeValueList({
            attributeId,
            attributeDefinitions,
            selectDefinitions,
            attributeValues: attributeValueLookup,
            accessSelectRowValue: (selectRow: SelectRow) => selectRow.name,
          })
            .map(({ readable }) => readable)
            .join(", ");
        }
      });
      const rowString = JSON.stringify(rowValues);
      if (!addedRowStrings.has(rowString)) {
        addedRowStrings.add(rowString);
        rows.push(rowValues);
      }
    });

    return { headers, rows };
  };

  return { computeColumnExport };
};

export { useColumnFormat, useColumnFormats, useColumnExport };
