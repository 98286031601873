import { useApiInstance } from "../api/useApiInstance";
import { z } from "zod";
import { TimestampSchema } from "../types/zod";
import { BrandId } from "../types/util";

const BrandProjectSchema = z.object({
  id: z.union([z.string(), z.number()]),
  name: z.string(),
});

const BrandMemberSchema = z.object({
  id: z.union([z.string(), z.number()]),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
});

const BrandSchema = z.object({
  id: z.union([z.string(), z.number()]),
  brand_name: z.string(),
  projects: z.array(BrandProjectSchema),
  brand_members: z.array(BrandMemberSchema),
  created_at: TimestampSchema,
  updated_at: TimestampSchema,
});

type BrandProject = z.infer<typeof BrandProjectSchema>;
type Brand = z.infer<typeof BrandSchema>;

const useBrand = (brandId: BrandId) => {
  const { useWrappedSWR } = useApiInstance();
  const { data, isLoading, error } = useWrappedSWR(`/brands/${brandId}`);
  const brand: Brand | undefined =
    data && !error ? BrandSchema.parse(data) : undefined;

  return { brand, isLoading, error };
};

export { useBrand, BrandSchema };
export type { Brand, BrandProject };
