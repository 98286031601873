import { Attribute, AttributeType } from "../../types/attribute";
import {
  BoolType,
  ComparisonType,
  Filter,
  FilterEntityType,
  FilterType,
  SetMatchType,
} from "../../types/filter";
import { Offer } from "../../types/offer";
import { UID } from "../../types/uid";
import { FullRecord } from "../../types/record";
import { DropdownItem } from "../attributes/Dropdown";

const numericComparisonOptions: DropdownItem[] = [
  { id: ComparisonType.Equal, label: "equals" },
  { id: ComparisonType.NotEqual, label: "does not equal" },
  { id: ComparisonType.GreaterThan, label: "is greater than" },
  { id: ComparisonType.GreaterThanEq, label: "is greater than or equal to" },
  { id: ComparisonType.LessThan, label: "is less than" },
  { id: ComparisonType.LessThanEq, label: "is less than or equal to" },
  { id: ComparisonType.Between, label: "is between" },
  { id: ComparisonType.NotBetween, label: "is not between" },
];

const dateComparisonOptions: DropdownItem[] = [
  { id: ComparisonType.Equal, label: "is exactly" },
  { id: ComparisonType.NotEqual, label: "is not" },
  { id: ComparisonType.GreaterThan, label: "is after" },
  { id: ComparisonType.GreaterThanEq, label: "is on or after" },
  { id: ComparisonType.LessThan, label: "is before" },
  { id: ComparisonType.LessThanEq, label: "is on or before" },
  { id: ComparisonType.Between, label: "is between" },
  { id: ComparisonType.NotBetween, label: "is not between" },
];

const setMatchOptions: DropdownItem[] = [
  { id: SetMatchType.Inclusion, label: "includes one of" },
];

const extractCheckboxComparable = (
  attributeValue: string | undefined,
): BoolType => (attributeValue ? (attributeValue as BoolType) : BoolType.False);

const extractNumberComparable = (
  attributeValue: string | undefined,
): number | undefined =>
  attributeValue ? parseFloat(attributeValue) : undefined;

const extractCurrencyComparable = (
  attributeValue: string | undefined,
): number | undefined =>
  attributeValue ? parseFloat(attributeValue) : undefined;

const extractDateComparable = (
  attributeValue: string | undefined,
): number | undefined =>
  attributeValue ? Date.parse(attributeValue).valueOf() : undefined;

const compareNumber = (
  comparisonType: ComparisonType,
  variable: number | undefined,
  referencePrimary: number | undefined,
  referenceSecondary: number | undefined,
): boolean | undefined => {
  if (variable === undefined || referencePrimary === undefined)
    return undefined;
  switch (comparisonType) {
    case ComparisonType.Equal:
      return variable === referencePrimary;
    case ComparisonType.NotEqual:
      return variable !== referencePrimary;
    case ComparisonType.GreaterThan:
      return variable > referencePrimary;
    case ComparisonType.GreaterThanEq:
      return variable >= referencePrimary;
    case ComparisonType.LessThan:
      return variable < referencePrimary;
    case ComparisonType.LessThanEq:
      return variable <= referencePrimary;
  }
  if (referenceSecondary === undefined) return undefined;
  switch (comparisonType) {
    case ComparisonType.Between:
      return referencePrimary <= variable && variable <= referenceSecondary;
    case ComparisonType.NotBetween:
      return !(referencePrimary <= variable && variable <= referenceSecondary);
  }
};

const compareSet = (
  matchType: SetMatchType,
  variable: string[] | undefined,
  reference: string[] | undefined,
): boolean => {
  const referenceSet = new Set(reference || []);
  const variableList = variable || [];
  switch (matchType) {
    case SetMatchType.Inclusion:
      return variableList.some((selectRowId: UID) =>
        referenceSet.has(selectRowId),
      );
  }
};

const matchesFilter = ({
  filter,
  entityId,
  attributeValue,
  attributeType,
  entrySetValues,
}: {
  filter: Filter;
  entityId: UID;
  attributeValue: string | undefined;
  attributeType: AttributeType | undefined;
  entrySetValues: string[];
}) => {
  switch (filter.type) {
    case FilterType.TrueFalseSet: {
      const checkboxValue: BoolType = extractCheckboxComparable(attributeValue);
      return entrySetValues.includes(checkboxValue);
    }
    case FilterType.AttributeValueSubstring: {
      return attributeValue?.includes(filter.substring) || false;
    }
    case FilterType.EntityIdSet: {
      return entrySetValues.includes(entityId);
    }
    case FilterType.SelectSet: {
      return compareSet(
        filter.setMatchType,
        entrySetValues,
        attributeValue?.split(","),
      );
    }
    case FilterType.ValueComparison: {
      if (!attributeType || !attributeValue || !filter.comparisonType)
        return false;

      const extract = (attributeValue: string | undefined) => {
        switch (attributeType) {
          case AttributeType.Date:
            return extractDateComparable(attributeValue);
          case AttributeType.Number:
            return extractNumberComparable(attributeValue);
          case AttributeType.Currency:
            return extractCurrencyComparable(attributeValue);
        }
      };

      return (
        compareNumber(
          filter.comparisonType,
          extract(attributeValue),
          extract(filter.refValuePrimary),
          extract(filter.refValueSecondary),
        ) || false
      );
    }
    case FilterType.None: {
      return true;
    }
  }
};

const offerMatchesFilter = ({
  filter,
  offerId,
  offer,
  attributeType,
  entrySetValues,
}: {
  filter: Filter;
  offerId: UID;
  offer: Offer;
  attributeType: AttributeType | undefined;
  entrySetValues: string[];
}): boolean => {
  const attributeValue: string | undefined =
    "attributeId" in filter && filter.attributeId
      ? offer.attributeValues[filter.attributeId]
      : undefined;

  return matchesFilter({
    filter,
    entityId: offerId,
    attributeValue,
    attributeType,
    entrySetValues,
  });
};

const recordMatchesFilter = ({
  filter,
  record,
  entrySetValues,
  attributeDefinitions,
}: {
  filter: Filter;
  record: FullRecord;
  entrySetValues: string[];
  attributeDefinitions: { [attributeId: UID]: Attribute };
}): boolean => {
  const attributeId: UID | undefined =
    "attributeId" in filter ? filter.attributeId : undefined;

  const attributeType: AttributeType | undefined =
    attributeId && attributeId in attributeDefinitions
      ? attributeDefinitions[attributeId].type
      : undefined;

  const attributeValues = {
    ...(record.offerPair?.value.attributeValues || {}),
    ...(record.mediumPair?.value.attributeValues || {}),
    ...record.chosenAttributeValues,
  };

  const attributeValue: string | undefined = attributeId
    ? attributeValues[attributeId]
    : undefined;

  const entityId = (() => {
    switch (filter.entityType) {
      case FilterEntityType.Offer:
        return record.offerPair?.id;
      case FilterEntityType.Target:
        return record.targetPair?.id;
      case FilterEntityType.Touchpoint:
        return record.mediumPair?.id;
    }
  })();

  return matchesFilter({
    filter,
    entityId: entityId || "",
    attributeValue,
    attributeType,
    entrySetValues,
  });
};

export {
  numericComparisonOptions,
  dateComparisonOptions,
  setMatchOptions,
  offerMatchesFilter,
  recordMatchesFilter,
};
