import {
  GeneralEvent,
  UserEvent,
  generalizeEvent,
  specifyEvent,
} from "../editor/eventUtil";
import { validateEvent } from "../editor/eventUtil";
import {
  EventMessageData,
  EventResponseType,
  AcceptedEvent,
  useEventNotifications,
  useEventApi,
} from "./useEventStore";
import { ProjectState } from "../editor/projectState";
import { EqualityFn, useDispatch, useSelector } from "react-redux";
import { AppDispatch, EditorThunk, RootState } from "../redux/store";
import {
  AcceptEventReceivedAction,
  EditorActionType,
  EventTriggeredAction,
  InvalidEventAction,
  ProjectClosedAction,
  ProjectOpenedAction,
  PublishEventAcceptedAction,
  PublishEventPostponedAction,
  PublishStartedAction,
  ReconnectedAction,
} from "../redux/editorReducer";
import { useCallback, useState } from "react";
import { ProjectId } from "../types/util";
import { toast } from "react-toastify";
import { invalidEventErrorMessage } from "../utils/errorMessages/editorErrorMessages";

const createProjectOpenedAction = (
  projectId: ProjectId,
  events: AcceptedEvent[],
): ProjectOpenedAction => ({
  type: EditorActionType.ProjectOpened,
  payload: { projectId, events },
});

const createProjectClosedAction = (): ProjectClosedAction => ({
  type: EditorActionType.ProjectClosed,
});

const createEventTriggeredAction = (
  event: UserEvent,
): EventTriggeredAction => ({
  type: EditorActionType.EventTriggered,
  payload: { event },
});

const createAcceptEventReceivedAction = (
  acceptedEvent: AcceptedEvent,
): AcceptEventReceivedAction => ({
  type: EditorActionType.AcceptEventReceived,
  payload: { acceptedEvent },
});

const createPublishEventAcceptedAction = (
  acceptedEvent: AcceptedEvent,
): PublishEventAcceptedAction => ({
  type: EditorActionType.PublishEventAccepted,
  payload: { acceptedEvent },
});

const createPublishEventPostponedAction = (): PublishEventPostponedAction => ({
  type: EditorActionType.PublishEventPostponed,
});

const createReconnectedAction = (
  unseenEvents: AcceptedEvent[],
): ReconnectedAction => ({
  type: EditorActionType.Reconnected,
  payload: { unseenEvents },
});

const createPublishStartedAction = (
  event: GeneralEvent,
): PublishStartedAction => ({
  type: EditorActionType.PublishStarted,
  payload: { event },
});

const createInvalidEventAction = (
  event: UserEvent,
  lastVerifiedEventID: string | undefined,
): InvalidEventAction => ({
  type: EditorActionType.InvalidEvent,
  payload: { invalidEvent: event },
});

const useEditor = (projectId: ProjectId) => {
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector((state: RootState) => state.editor.isLoading);
  const { getAllEvents, getAllEventsSinceId } = useEventApi(projectId);
  const [lastFetchedEventId, setLastFetchedEventId] = useState<string>(null!);

  useEventNotifications(
    projectId,
    lastFetchedEventId,
    (data: EventMessageData) => {
      dispatch(processAcceptEvent(data));
    },
  );

  const processAcceptEvent =
    ({ action, event, lastEventId }: EventMessageData): EditorThunk =>
    async (dispatch, getState) => {
      const { lastVerifiedEventID } = getState().editor;
      switch (action) {
        case EventResponseType.Accept: {
          if (lastEventId === lastVerifiedEventID) {
            dispatch(createAcceptEventReceivedAction(event));
          } else {
            dispatch(performStateCatchUp(getAllEventsSinceId));
          }
          break;
        }
        default: {
          throw new Error(`Received unexpected event message type: ${action}.`);
        }
      }
    };

  const openProject = useCallback(async () => {
    const events = await getAllEvents();
    dispatch(createProjectOpenedAction(projectId, events));
    setLastFetchedEventId(events[events.length - 1].id);
  }, [dispatch, projectId, getAllEvents]);

  const closeProject = useCallback(() => {
    dispatch(createProjectClosedAction());
  }, [dispatch]);

  return { openProject, closeProject, isLoading };
};

const useProjectSelector = <T>(
  selector: (project: ProjectState) => T,
  equalityFn?: EqualityFn<T>,
) => {
  return useSelector(
    (project: RootState) => selector(project.editor.liveState),
    equalityFn,
  );
};

const performStateCatchUp =
  (
    recentEventFetcher: (lastEventId?: string) => Promise<AcceptedEvent[]>,
  ): EditorThunk =>
  async (dispatch, getState) => {
    const { lastVerifiedEventID } = getState().editor;
    const newEvents: AcceptedEvent[] = await recentEventFetcher(
      lastVerifiedEventID,
    );
    if (newEvents.length > 0) {
      dispatch(createReconnectedAction(newEvents));
    }
  };

const useProjectDispatch = () => {
  const dispatch = useDispatch<AppDispatch>();
  const projectId = useSelector((state: RootState) => state.editor.projectId);
  const { publishEvent, getAllEventsSinceId } = useEventApi(projectId!);

  const validateAndPublish =
    (eventToPublish: UserEvent): EditorThunk =>
    async (dispatch, getState) => {
      const { verifiedState, lastVerifiedEventID } = getState().editor;

      if (!validateEvent(verifiedState, eventToPublish)) {
        dispatch(createInvalidEventAction(eventToPublish, lastVerifiedEventID));

        toast.error(
          invalidEventErrorMessage(eventToPublish, lastVerifiedEventID),
          {
            autoClose: false,
            closeOnClick: false,
            hideProgressBar: true,
            draggable: false,
          },
        );
      } else {
        const generalEvent = generalizeEvent(eventToPublish);

        while (getState().debug.offlineActivated) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }

        await publishEvent(generalEvent, lastVerifiedEventID!)
          .then(({ responseType, data }) => {
            switch (responseType) {
              case EventResponseType.Accept:
                dispatch(createPublishEventAcceptedAction(data));
                break;
              case EventResponseType.Postpone:
                dispatch(createPublishEventPostponedAction());
                dispatch(performStateCatchUp(getAllEventsSinceId));
                break;
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
      dispatch(processQueue());
    };

  const processQueue = (): EditorThunk => async (dispatch, getState) => {
    const { eventQueue, eventBeingPublished } = getState().editor;
    const nextInEventQueue = eventQueue.length > 0 ? eventQueue[0] : undefined;
    if (nextInEventQueue && !eventBeingPublished) {
      dispatch(createPublishStartedAction(nextInEventQueue));
      dispatch(validateAndPublish(specifyEvent(nextInEventQueue)));
    }
  };

  const editorThunk =
    (event: UserEvent): EditorThunk =>
    (dispatch) => {
      dispatch(createEventTriggeredAction(event));
      dispatch(processQueue());
    };

  const projectDispatch = (event: UserEvent) => {
    dispatch(editorThunk(event));
  };

  return projectDispatch;
};

export { useEditor, useProjectDispatch, useProjectSelector };
