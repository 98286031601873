import { produce } from "immer";
import { UID } from "../../types/uid";
import { EventGroup, HandlerLookup, makeGeneralPayload } from "../eventUtil";
import { ProjectState } from "../projectState";
import { createAction } from "../userEventHandlers";

enum SelectFieldEventType {
  CreateRow = "selectField/createRow",
  AssignField = "selectField/assignField",
  DeleteRow = "selectField/deleteRow",
}

enum SelectFieldAttribute {
  Name = "name",
  UTMTerm = "utm_term",
  URLTerm = "url_term",
}

type SelectFieldEvent = EventGroup<{
  [SelectFieldEventType.CreateRow]: {
    selectId: UID;
    rowId: UID;
  };
  [SelectFieldEventType.AssignField]: {
    selectId: UID;
    rowId: UID;
    attribute: SelectFieldAttribute;
    value: string;
  };
  [SelectFieldEventType.DeleteRow]: {
    selectId: UID;
    rowId: UID;
  };
}>;

const selectFieldHandlers: HandlerLookup<SelectFieldEvent> = {
  [SelectFieldEventType.CreateRow]: {
    generalizer: ({ payload: { selectId, rowId } }) =>
      makeGeneralPayload({
        payloadA: selectId,
        payloadB: rowId,
      }),
    specifier: ({ payloadA, payloadB }) =>
      createAction[SelectFieldEventType.CreateRow]({
        selectId: payloadA,
        rowId: payloadB,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { selectId, rowId } = action.payload;
        draftState.selects.definitions[selectId].rowDefinitions[rowId] = {
          name: "",
          utmTerm: "",
          urlTerm: "",
        };
        draftState.selects.definitions[selectId].rowOrdering.push(rowId);
      }),
    validator: (state, action) => {
      return !(
        action.payload.rowId in
        state.selects.definitions[action.payload.selectId].rowDefinitions
      );
    },
  },
  [SelectFieldEventType.AssignField]: {
    generalizer: ({ payload: { selectId, rowId, attribute, value } }) =>
      makeGeneralPayload({
        payloadA: `${selectId}/${rowId}`,
        payloadB: attribute,
        payloadC: value,
      }),
    specifier: ({ payloadA, payloadB, payloadC }) => {
      const path: UID[] = payloadA.split("/");
      return createAction[SelectFieldEventType.AssignField]({
        selectId: path[0],
        rowId: path[1],
        attribute: payloadB as SelectFieldAttribute,
        value: payloadC,
      });
    },
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { attribute, selectId, rowId, value } = action.payload;
        const row =
          draftState.selects.definitions[selectId].rowDefinitions[rowId];
        switch (attribute) {
          case SelectFieldAttribute.Name:
            row.name = value;
            break;
          case SelectFieldAttribute.URLTerm:
            row.urlTerm = value;
            break;
          case SelectFieldAttribute.UTMTerm:
            row.utmTerm = value;
            break;
        }
      }),
    validator: (state, action) => {
      const { selectId, rowId } = action.payload;
      return (
        selectId in state.selects.definitions &&
        rowId in state.selects.definitions[selectId].rowDefinitions
      );
    },
  },
  [SelectFieldEventType.DeleteRow]: {
    generalizer: ({ payload: { selectId, rowId } }) =>
      makeGeneralPayload({
        payloadA: selectId,
        payloadB: rowId,
      }),
    specifier: ({ payloadA, payloadB }) =>
      createAction[SelectFieldEventType.DeleteRow]({
        selectId: payloadA,
        rowId: payloadB,
      }),
    reducer: (state, action) =>
      produce(state, (draftState: ProjectState) => {
        const { selectId, rowId } = action.payload;
        delete draftState.selects.definitions[selectId].rowDefinitions[rowId];
        draftState.selects.definitions[selectId].rowOrdering =
          state.selects.definitions[selectId].rowOrdering.filter(
            (id: UID) => id !== action.payload.rowId
          );
      }),
    validator: (state, action) => {
      const { selectId, rowId } = action.payload;
      return (
        selectId in state.selects.definitions &&
        rowId in state.selects.definitions[selectId].rowDefinitions
      );
    },
  },
};

export { selectFieldHandlers, SelectFieldEventType, SelectFieldAttribute };
export type { SelectFieldEvent };
