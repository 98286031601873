import { ProjectState } from "../projectState";
import { useProjectDispatch, useProjectSelector } from "../../hooks/useEditor";
import { createAction } from "../userEventHandlers";
import { MessageEventType } from "../handlers/handleMessage";

const useMessage = () => {
  const projectDispatch = useProjectDispatch();

  const value = useProjectSelector((project: ProjectState) => project.message);

  const edit = (newMessage: string) => {
    projectDispatch(createAction[MessageEventType.Change]({ newMessage }));
  };

  return { value, edit };
};

export { useMessage };
