import { useApiInstance } from "../api/useApiInstance";
import { useSWRConfig } from "swr";
import { z } from "zod";
import { TimestampSchema } from "../types/zod";
import { BrandId, ProjectId } from "../types/util";
import getDateString from "../utils/date/DateUtils";

const BrandSchema = z.object({
  id: z.union([z.string(), z.number()]),
  brand_name: z.string(),
  created_at: TimestampSchema,
  updated_at: TimestampSchema
});

const ProjectSchema = z.object({
  id: z.union([z.string(), z.number()]),
  name: z.string(),
  brand: BrandSchema,
  created_at: TimestampSchema,
  updated_at: TimestampSchema
});

const ProjectsSchema = z.array(ProjectSchema);

type Project = z.infer<typeof ProjectSchema>;

const useProjects = (brandId: BrandId) => {
  const { mutate } = useSWRConfig();
  const { instance, useWrappedSWR } = useApiInstance();
  const { data, error, isLoading } = useWrappedSWR("/projects");
  const projects: Project[] = data && !error ? ProjectsSchema.parse(data) : [];

  const createCampaign = async (campaignName: string) => {
    await instance.post("/projects", { name: campaignName, brand_id: brandId });
    mutate(`/brands/${brandId}`);
  };

  return { projects, isLoading, error, createCampaign };
};

const useProject = (projectId: ProjectId) => {

  const { instance, useWrappedSWR } = useApiInstance();
  const { data, mutate, isLoading, error } = useWrappedSWR(
    `/projects/${projectId}`
  );
  const { mutate: globalMutate } = useSWRConfig();

  const project: Project | undefined =
    !isLoading && !error && data ? ProjectSchema.parse(data) : undefined;

  const deleteProject = async () => {
    try {
      await instance.delete(`/projects/${projectId}`);
      await mutate(undefined);
      if (project) {
        await globalMutate(`/brands/${project.brand.id}`);
      }
    } catch (error) {
      console.error(`Could not delete project: ${JSON.stringify(error)}`);
    }
  };

  const renameProject = async (newName: string) => {
    await instance.put(`/projects/${projectId}`, { name: newName });
    await mutate({ ...data, name: newName });
  };

  const duplicateProject = async () => {
    await instance.get(`/projects/${projectId}/clone`);

    if (project) {
      globalMutate(`/brands/${project.brand.id}`);
    }
  };

  const now = new Date();
  const created = new Date(project?.created_at || now);
  const modified = new Date(project?.updated_at || now);
  const createdDateString: string = getDateString(created);
  const modifiedDateString: string = getDateString(modified);

  return {
    project,
    createdDateString,
    modifiedDateString,
    isLoading,
    deleteProject,
    renameProject,
    duplicateProject
  };
};

export { useProjects, useProject };
