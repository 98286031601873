import { CaretRight, Palette } from "@phosphor-icons/react";
import { Button } from "monday-ui-react-core";
import { VerticalCard } from "./Card";
import { TextInput } from "../attributes/Input";
import { SingleDropdown } from "../attributes/Dropdown";
import { AddNewButton, AddNewOptions } from "./AddNewButton";
import {
  AttributeType,
  getAttributeIcon,
  getAttributeTypeDescription,
} from "../../types/attribute";
import { EditorPage } from "../../types/editorPage";
import { useAttribute, useAttributes } from "../hooks/useAttributes";
import { UID } from "../../types/uid";
import { useState } from "react";
import { useSelects } from "../hooks/useSelects";
import { RemoveItemButton } from "./RemoveItemButton";
import { usePersistentState } from "../../hooks/usePersistentState";

const attribueTypes: AttributeType[] = [
  AttributeType.Text,
  AttributeType.Number,
  AttributeType.Currency,
  AttributeType.Date,
  AttributeType.Checkbox,
  AttributeType.SingleSelect,
  AttributeType.MultiSelect,
];

const attributeOptions: AddNewOptions<AttributeType> = attribueTypes.map(
  (type: AttributeType) => ({
    value: type,
    displayValue: getAttributeTypeDescription(type),
    icon: getAttributeIcon(type),
  })
);

const Attribute = ({
  attributeId,
  page,
}: {
  attributeId: UID;
  page: EditorPage;
}) => {
  const { name, type, rename, remove, selectId, chooseSelect } = useAttribute(
    page,
    attributeId
  );
  const { selects } = useSelects();

  const Icon = getAttributeIcon(type);

  const isSelectAttribute = [
    AttributeType.MultiSelect,
    AttributeType.SingleSelect,
  ].includes(type);

  return (
    <VerticalCard
      className="bg-white"
      headerClassName="bg-transparent rounded-t px-0"
      bodyClassName="bg-transparent pb-0.5 pt-0"
      header={
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-0.5 pl-1 w-full">
            <div className="flex w-fit items-center pl-1">
              {/* <DotsSixVertical size={20} /> */}
              <Icon className="pr-1" size={20} />
            </div>
            <TextInput
              initialValue={name}
              onChange={(newName) => rename(newName)}
              className="w-full"
            />
          </div>
          <RemoveItemButton onRemove={() => remove()} />
        </div>
      }
    >
      {isSelectAttribute ? (
        <SingleDropdown
          className="m-2 mt-0"
          possibleItems={selects.map(({ select: { title }, selectId }) => ({
            id: selectId,
            label: title,
          }))}
          title="Selects"
          selectedId={selectId}
          onChange={(selectId?: UID) => chooseSelect(selectId)}
        />
      ) : (
        <></>
      )}
    </VerticalCard>
  );
};

const AttributeBuilder = ({
  className,
  page,
}: {
  className?: string;
  page: EditorPage;
}) => {
  const { attributeIds, create } = useAttributes(page);
  // const [isMinimized, setIsMinimized] = useState(false);

  const {
    attributeBuilderIsMinimized: isMinimized,
    setAttributeBuilderIsMinimized: setIsMinimized,
  } = usePersistentState();

  const [isHovering, setIsHovering] = useState(false);

  return (
    <div
      className={`${className} transition-width overflow-visible ${
        isMinimized
          ? "h-fit w-0"
          : "m-2 w-72 bg-slate-50 border rounded-md shadow-md"
      }  duration-300 `}
      style={{ direction: isMinimized ? "rtl" : "ltr" }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div className={`${isHovering && !isMinimized ? "relative " : "hidden"}`}>
        <button
          className="absolute flex items-center justify-center left-0 w-6 h-8 rounded-br-lg rounded-tl bg-slate-100 border"
          onClick={() => setIsMinimized(!isMinimized)}
        >
          <div
            className={`${
              isMinimized ? "rotate-180" : "rotate-0"
            } transform transition-transform`}
          >
            <CaretRight />
          </div>
        </button>
      </div>

      <div
        className={`flex flex-col p-2 transition-width ${
          isMinimized ? "w-fit h-fit overflow-visible " : "h-full w-72"
        } gap-4`}
      >
        {isMinimized ? (
          <Button
            onClick={() => {
              setIsMinimized(false);
            }}
            leftIcon={Palette}
            kind={Button.kinds.SECONDARY}
            size={Button.sizes.SMALL}
            style={{ direction: "ltr" }}
          >
            Attributes
          </Button>
        ) : (
          <>
            <div className="ml-6 h-12 ">
              <div>Attributes</div>
              <AddNewButton
                options={attributeOptions}
                onSelect={(attributeType: AttributeType) =>
                  create(attributeType)
                }
                title="Add field"
              />
            </div>
            <div className="flex flex-col gap-2 overflow-scroll pb-96 no-scrollbar">
              {attributeIds.map((id) => (
                <Attribute key={id} attributeId={id} page={page} />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export { AttributeBuilder };
