import {
  DotsSixVertical,
  Palette,
  Rectangle,
  Rows,
  TextAa,
  X,
} from "@phosphor-icons/react";
import { EditorPage } from "../../types/editorPage";
import { FormulaBlockType, SpreadMethodType } from "../../types/formula";
import { UID } from "../../types/uid";
import { useFormula, useFormulaBlock } from "../hooks/useFormula";
import { AddNewButton } from "./AddNewButton";
import { IconButton } from "monday-ui-react-core";
import { useEffect, useRef, useState } from "react";
import { DropdownItem, SingleDropdown } from "../attributes/Dropdown";
import { useAttribute, useAttributes } from "../hooks/useAttributes";
import {
  useSortable,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  TouchSensor,
  MouseSensor,
} from "@dnd-kit/core";
import { Toggle } from "./Toggle";
import { AttributeType } from "../../types/attribute";

const ResizingTextInput = ({
  className,
  initialValue,
  onChange,
}: {
  className?: string;
  initialValue: string;
  onChange: (newValue: string) => void;
}) => {
  const INPUT_MIN_WIDTH = 20;
  const MAX_INPUT_LENGTH = 160;
  const [content, setContent] = useState(initialValue);
  const [inputWidth, setInputWidth] = useState(INPUT_MIN_WIDTH);
  const spanRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (spanRef && spanRef.current) {
      setInputWidth(
        (spanRef.current.offsetWidth > INPUT_MIN_WIDTH
          ? spanRef.current.offsetWidth
          : INPUT_MIN_WIDTH) + 8,
      );
    }
  }, [content]);

  useEffect(() => {
    setContent(initialValue);
  }, [initialValue]);

  return (
    <div className={`${className} flex items-center `}>
      <span
        className="absolute px-1 bg-transparent text-transparent -z-10 outline-none 
        border-slate-300 border text-sm "
        ref={spanRef}
      >
        {content}
      </span>
      <input
        style={{ width: inputWidth }}
        className="bg-transparent px-1 outline-none border-slate-300 rounded-sm
        border text-slate-800 text-sm h-6"
        value={content}
        onChange={(event) => {
          const newValue: string = event.target.value;
          setContent(newValue);
        }}
        //maxLength={MAX_INPUT_LENGTH}
        onBlur={() => {
          onChange(content);
        }}
      />
    </div>
  );
};

const AttributeFormulaBlock = ({
  className,
  formulaId,
  blockId,
  attributeOptions,
  editorPage,
}: {
  className?: string;
  formulaId: UID;
  blockId: UID;
  attributeOptions: DropdownItem[];
  editorPage: EditorPage;
}) => {
  const {
    formulaBlockValue: attributeId,
    formulaBlockSpreadMethod,
    editValue,
    chooseSpreadMethod,
  } = useFormulaBlock(formulaId, blockId);

  const { type: attributeType } = useAttribute(editorPage, attributeId);

  return (
    <div className={`${className} flex`}>
      {attributeType === AttributeType.MultiSelect ? (
        <Toggle
          icon={
            formulaBlockSpreadMethod === SpreadMethodType.ForEach
              ? Rows
              : Rectangle
          }
          isSelected={false}
          onToggle={() =>
            chooseSpreadMethod(
              formulaBlockSpreadMethod === SpreadMethodType.ForEach
                ? SpreadMethodType.Concatenate
                : SpreadMethodType.ForEach,
            )
          }
          tooltip={
            formulaBlockSpreadMethod === SpreadMethodType.ForEach
              ? "Values spread into many rows"
              : "Values combined into one row"
          }
        />
      ) : (
        <></>
      )}
      <SingleDropdown
        possibleItems={attributeOptions}
        className="-mt-0.5"
        selectedId={attributeId}
        onChange={(newId?: UID) => editValue(newId || "")}
        clearable={false}
      />
    </div>
  );
};

const FormulaBlock = ({
  className,
  formulaId,
  blockId,
  attributeOptions,
  editorPage,
}: {
  className?: string;
  formulaId: UID;
  blockId: UID;
  attributeOptions: DropdownItem[];
  editorPage: EditorPage | undefined;
}) => {
  const { formulaBlockType, formulaBlockValue, editValue, remove } =
    useFormulaBlock(formulaId, blockId);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: blockId });

  return (
    <div
      className={`${className} flex items-center gap-1 px-1 bg-slate-100 border rounded `}
      ref={setNodeRef}
      style={{
        transform: CSS.Translate.toString(transform),
        transition,
      }}
    >
      <div className="w-4" {...attributes} {...listeners}>
        <DotsSixVertical />
      </div>
      {formulaBlockType === FormulaBlockType.Text ? (
        <ResizingTextInput
          className="bg-slate-100"
          initialValue={formulaBlockValue}
          onChange={(newValue: string) => {
            editValue(newValue);
          }}
        />
      ) : formulaBlockType === FormulaBlockType.Attribute && editorPage ? (
        <AttributeFormulaBlock
          formulaId={formulaId}
          blockId={blockId}
          attributeOptions={attributeOptions}
          editorPage={editorPage}
        />
      ) : (
        <></>
      )}
      <IconButton
        icon={X}
        size={IconButton.sizes.XS}
        className="w-4"
        onClick={() => remove()}
      />
    </div>
  );
};

const FormulaBuilder = ({
  className,
  formulaId,
  editorPage,
  title,
  defaultSpreadMethod,
}: {
  className?: string;
  formulaId: UID;
  editorPage: EditorPage;
  title: string;
  defaultSpreadMethod: SpreadMethodType;
}) => {
  const { addBlock, moveBlock, formulaBlockIds } = useFormula(formulaId);
  const { attributeOptions } = useAttributes(editorPage);

  const formulaBlockOptions = [
    { value: FormulaBlockType.Text, displayValue: "Text", icon: TextAa },
    {
      value: FormulaBlockType.Attribute,
      displayValue: "Attribute",
      icon: Palette,
    },
  ];

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const movedId = over.id as UID;
      const newIndex = formulaBlockIds.indexOf(movedId);
      moveBlock(active.id as UID, newIndex);
    }
  };

  const SmallLine = () => (
    <svg className="w-1 h-8">
      <line x1="0" y1="16" x2="4" y2="16" stroke="slategray" strokeWidth="2" />
    </svg>
  );

  return (
    <div className={`${className}`}>
      <div className="flex gap-2">
        <div>{title}</div>
        <AddNewButton
          options={formulaBlockOptions}
          onSelect={(formulaBlockType) =>
            addBlock(formulaBlockType, defaultSpreadMethod)
          }
          title="Add block"
        />
      </div>
      <div className="flex flex-wrap gap-y-1 pt-1 pb-2 pl-2">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={formulaBlockIds}
            strategy={verticalListSortingStrategy}
          >
            {formulaBlockIds.map((blockId: UID, idx: number) => (
              <div
                key={blockId}
                className={`flex items-center ${idx === 0 ? "-ml-2" : ""}`}
              >
                {idx > 0 ? <SmallLine /> : <></>}
                <FormulaBlock
                  key={blockId}
                  formulaId={formulaId}
                  blockId={blockId}
                  attributeOptions={attributeOptions}
                  editorPage={editorPage}
                />
                {idx < formulaBlockIds.length - 1 ? <SmallLine /> : <></>}
              </div>
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
};

export { FormulaBuilder };
