import { Skeleton } from "monday-ui-react-core";

const TestEditorLoader = ({ className }: { className?: string }) => {
  return (
    <div className={`${className} bg-slate-200`}>
      <nav className="bg-slate-100 h-12 top-0"></nav>
      <div className="w-full h-screen overflow-hidden">
        <div className="flex sticky flex-col gap-4 py-4">
          <div className="flex justify-center">
            <div className="font-bold text-lg">Test Editor</div>
          </div>
          <div className="flex gap-2 w-full justify-center items-center">
            <Skeleton type={Skeleton.types.RECTANGLE} />
            <Skeleton type={Skeleton.types.RECTANGLE} />
            <div className="flex flex-col gap-4">
              <Skeleton
                type={Skeleton.types.TEXT}
                size={Skeleton.sizes.TEXT.H1}
              />
              <Skeleton
                type={Skeleton.types.TEXT}
                size={Skeleton.sizes.TEXT.H1}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { TestEditorLoader };
