import { useEffect } from "react";
import { TestEditor } from "./views/TestEditor";
import { useParams } from "react-router-dom";
import { useEditor } from "../hooks/useEditor";
import { TestEditorLoader } from "./views/TestEditorLoader";
import { ProjectId } from "../types/util";
import { ProjectEditor } from "./views/ProjectEditor";
import { ProjectEditorLoader } from "./views/ProjectEditorLoader";

const USE_DEBUG_EDITOR =
  process.env.REACT_APP_USE_DEBUG_EDITOR?.toLowerCase() === "true";

const EditorLoader = USE_DEBUG_EDITOR ? TestEditorLoader : ProjectEditorLoader;
const Editor = USE_DEBUG_EDITOR ? TestEditor : ProjectEditor;

const Edit = ({ className }: { className?: string }) => {
  const { projectId } = useParams<{ projectId: ProjectId }>();

  if (!projectId) {
    throw new Error("Editor started without project Id");
  }

  const { openProject, closeProject, isLoading } = useEditor(projectId);

  useEffect(() => {
    openProject();
    return () => {
      closeProject();
    };
  }, [projectId, openProject, closeProject]);

  return isLoading ? (
    <EditorLoader className={className} />
  ) : (
    <Editor className={className} projectId={projectId} />
  );
};

export { Edit };
