import { UID } from "./uid";

type Offer = {
  title: string;
  attributeValues: {
    [attributeId: UID]: string;
  };
};

const defaultOffer: Offer = {
  title: "",
  attributeValues: {},
};

export { defaultOffer };
export type { Offer };
