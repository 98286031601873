import { AttributeType } from "../../types/attribute";
import { MultiDropdown, SingleDropdown } from "../attributes/Dropdown";
import {
  CurrencyInput,
  DateInput,
  NumberInput,
  TextInput,
} from "../attributes/Input";
import { Checkbox } from "../attributes/Checkbox";
import { UID } from "../../types/uid";
import { SelectRow } from "../../types/select";
import { BoolType } from "../../types/filter";

const Attribute = ({
  className,
  type,
  selectRows,
  value,
  editValue,
  name,
}: {
  className?: string;
  type: AttributeType;
  selectRows: { rowId: UID; row: SelectRow }[] | undefined;
  value: string | undefined;
  editValue: (newValue?: string) => void;
  name: string;
}) => {
  const selectOptions = selectRows
    ? selectRows.map(({ rowId, row: { name } }) => ({ id: rowId, label: name }))
    : undefined;

  switch (type) {
    case AttributeType.SingleSelect:
      return (
        <SingleDropdown
          className={`${className} w-52`}
          title={name}
          possibleItems={selectOptions ? selectOptions : []}
          selectedId={value === "" || !value ? undefined : (value as UID)}
          onChange={(newId?: UID) => {
            editValue(newId);
          }}
        />
      );
    case AttributeType.MultiSelect:
      return (
        <MultiDropdown
          className={`${className} w-52`}
          title={name}
          possibleItems={selectOptions ? selectOptions : []}
          selectedIds={
            value === "" || !value ? undefined : (value.split(",") as UID[])
          }
          onChange={(newIds: UID[]) => {
            editValue(newIds.join(","));
          }}
        />
      );
    case AttributeType.Currency:
      return (
        <CurrencyInput
          className={className}
          title={name}
          initialValue={value || ""}
          onChange={(newValue: string | undefined) => editValue(newValue || "")}
        />
      );
    case AttributeType.Number:
      return (
        <NumberInput
          className={className}
          title={name}
          initialValue={value || ""}
          onChange={(newValue: string | undefined) => editValue(newValue || "")}
        />
      );
    case AttributeType.Text:
      return (
        <TextInput
          className={className}
          title={name}
          initialValue={value || ""}
          onChange={(newValue: string | undefined) => editValue(newValue || "")}
        />
      );
    case AttributeType.Date:
      return (
        <DateInput
          className={className}
          title={name}
          initialValue={value || ""}
          onChange={(newValue: string | undefined) => editValue(newValue || "")}
        />
      );

    case AttributeType.Checkbox:
      return (
        <Checkbox
          className={className}
          title={name}
          initialValue={value === BoolType.True}
          onChange={(newValue: boolean | undefined) =>
            editValue(newValue ? BoolType.True : BoolType.False)
          }
        />
      );
    default:
      return <div>[{type}]</div>;
  }
};

export { Attribute };
