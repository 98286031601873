import produce from "immer";
import { UID } from "../../types/uid";
import { EventGroup, HandlerLookup, makeGeneralPayload } from "../eventUtil";
import { createAction } from "../userEventHandlers";
import { SourceIconType } from "../../types/touchpoints";

enum SourceEventType {
  Create = "source/create",
  Delete = "source/delete",
  Rename = "source/rename",
  ChangeIcon = "source/changeIcon",
  EditUTM = "source/editUTM",
}

type SourceEvent = EventGroup<{
  [SourceEventType.Create]: {
    sourceId: UID;
    title: string;
    icon: SourceIconType;
    utmValue: string;
  };
  [SourceEventType.Delete]: {
    sourceId: UID;
  };
  [SourceEventType.Rename]: {
    sourceId: UID;
    newName: string;
  };
  [SourceEventType.ChangeIcon]: {
    sourceId: UID;
    newIcon: SourceIconType;
  };
  [SourceEventType.EditUTM]: {
    sourceId: UID;
    newUTM: string;
  };
}>;

const sourceHandlers: HandlerLookup<SourceEvent> = {
  [SourceEventType.Create]: {
    generalizer: ({ payload: { sourceId, title, icon, utmValue } }) =>
      makeGeneralPayload({
        payloadA: sourceId,
        payloadB: title,
        payloadC: `${icon}/${utmValue}`,
      }),
    specifier: ({ payloadA, payloadB, payloadC }) => {
      const iconName = payloadC.split("/")[0];
      const slashIdx = payloadC.indexOf("/");
      const utmValue = slashIdx >= 0 ? payloadC.slice(slashIdx + 1) : "";
      return createAction[SourceEventType.Create]({
        sourceId: payloadA,
        title: payloadB,
        icon: iconName as SourceIconType,
        utmValue,
      });
    },
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { sourceId, title, icon, utmValue } = action.payload;
        draftState.touchpoints.sourceDefinitions[sourceId] = {
          title,
          utmValue,
          icon,
          mediumOrdering: [],
        };
        draftState.touchpoints.sourceOrdering.push(sourceId);
      }),
    validator: (state, action) =>
      !(action.payload.sourceId in state.touchpoints.sourceDefinitions),
  },
  [SourceEventType.Delete]: {
    generalizer: ({ payload: { sourceId } }) =>
      makeGeneralPayload({
        payloadA: sourceId,
      }),
    specifier: ({ payloadA }) =>
      createAction[SourceEventType.Delete]({
        sourceId: payloadA,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { sourceId } = action.payload;
        const mediumIds: UID[] =
          state.touchpoints.sourceDefinitions[sourceId].mediumOrdering;
        for (const mediumId in mediumIds) {
          delete draftState.touchpoints.mediumDefinitions[mediumId];
        }
        delete draftState.touchpoints.sourceDefinitions[sourceId];
        draftState.touchpoints.sourceOrdering =
          state.touchpoints.sourceOrdering.filter(
            (sourceId) => sourceId !== action.payload.sourceId
          );
      }),
    validator: (state, action) =>
      action.payload.sourceId in state.touchpoints.sourceDefinitions,
  },
  [SourceEventType.Rename]: {
    generalizer: ({ payload: { sourceId, newName } }) =>
      makeGeneralPayload({
        payloadA: sourceId,
        payloadB: newName,
      }),
    specifier: ({ payloadA, payloadB }) =>
      createAction[SourceEventType.Rename]({
        sourceId: payloadA,
        newName: payloadB,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { sourceId, newName } = action.payload;
        draftState.touchpoints.sourceDefinitions[sourceId].title = newName;
      }),
    validator: (state, action) =>
      action.payload.sourceId in state.touchpoints.sourceDefinitions,
  },
  [SourceEventType.ChangeIcon]: {
    generalizer: ({ payload: { sourceId, newIcon } }) =>
      makeGeneralPayload({
        payloadA: sourceId,
        payloadB: newIcon,
      }),
    specifier: ({ payloadA, payloadB }) =>
      createAction[SourceEventType.ChangeIcon]({
        sourceId: payloadA,
        newIcon: payloadB as SourceIconType,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { sourceId, newIcon } = action.payload;
        draftState.touchpoints.sourceDefinitions[sourceId].icon = newIcon;
      }),
    validator: (state, action) =>
      action.payload.sourceId in state.touchpoints.sourceDefinitions &&
      action.payload.newIcon in SourceIconType,
  },
  [SourceEventType.EditUTM]: {
    generalizer: ({ payload: { sourceId, newUTM } }) =>
      makeGeneralPayload({
        payloadA: sourceId,
        payloadB: newUTM,
      }),
    specifier: ({ payloadA, payloadB }) =>
      createAction[SourceEventType.EditUTM]({
        sourceId: payloadA,
        newUTM: payloadB,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { sourceId, newUTM } = action.payload;
        draftState.touchpoints.sourceDefinitions[sourceId].utmValue = newUTM;
      }),
    validator: (state, action) =>
      action.payload.sourceId in state.touchpoints.sourceDefinitions,
  },
};

export { sourceHandlers, SourceEventType };
export type { SourceEvent };
