import { produce } from "immer";
import { AttributeType } from "../../types/attribute";
import { UID } from "../../types/uid";
import { EventGroup, HandlerLookup, makeGeneralPayload } from "../eventUtil";
import { EditorPage } from "../../types/editorPage";
import { createAction } from "../userEventHandlers";

enum AttributeEventType {
  Create = "attribute/create",
  Rename = "attribute/rename",
  Delete = "attribute/delete",
  ChooseSelect = "attribute/chooseSelect",
}

type AttributeEvent = EventGroup<{
  [AttributeEventType.Create]: {
    attributeId: UID;
    page: EditorPage;
    type: AttributeType;
  };
  [AttributeEventType.Rename]: {
    attributeId: UID;
    page: EditorPage;
    newName: string;
  };
  [AttributeEventType.Delete]: {
    attributeId: UID;
    page: EditorPage;
  };
  [AttributeEventType.ChooseSelect]: {
    attributeId: UID;
    page: EditorPage;
    selectId?: UID;
  };
}>;

const attributeHandlers: HandlerLookup<AttributeEvent> = {
  [AttributeEventType.Create]: {
    generalizer: ({ payload: { attributeId, page, type } }) =>
      makeGeneralPayload({
        payloadA: attributeId,
        payloadB: page,
        payloadC: type,
      }),
    specifier: ({ payloadA, payloadB, payloadC }) =>
      createAction[AttributeEventType.Create]({
        attributeId: payloadA,
        page: payloadB as EditorPage,
        type: payloadC as AttributeType,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { attributeId, page, type } = action.payload;
        draftState.attributes.definitions[page][attributeId] = {
          type: type,
          name: "",
        };
        draftState.attributes.orderings[page].push(attributeId);
      }),
    validator: (state, action) => {
      const { attributeId, page, type } = action.payload;
      return (
        Object.values(EditorPage).includes(page) &&
        Object.values(AttributeType).includes(type) &&
        !(attributeId in state.attributes.definitions[page])
      );
    },
  },
  [AttributeEventType.Rename]: {
    generalizer: ({ payload: { attributeId, page, newName } }) =>
      makeGeneralPayload({
        payloadA: attributeId,
        payloadB: page,
        payloadC: newName,
      }),
    specifier: ({ payloadA, payloadB, payloadC }) =>
      createAction[AttributeEventType.Rename]({
        attributeId: payloadA,
        page: payloadB as EditorPage,
        newName: payloadC,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        draftState.attributes.definitions[action.payload.page][
          action.payload.attributeId
        ].name = action.payload.newName;
      }),
    validator: (state, action) => {
      const { attributeId, page } = action.payload;
      return (
        Object.values(EditorPage).includes(page) &&
        attributeId in state.attributes.definitions[page]
      );
    },
  },
  [AttributeEventType.Delete]: {
    generalizer: ({ payload }) =>
      makeGeneralPayload({
        payloadA: payload.attributeId,
        payloadB: payload.page,
      }),
    specifier: ({ payloadA, payloadB }) =>
      createAction[AttributeEventType.Delete]({
        attributeId: payloadA,
        page: payloadB as EditorPage,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { attributeId, page } = action.payload;
        delete draftState.attributes.definitions[page][attributeId];
        draftState.attributes.orderings[page] = draftState.attributes.orderings[
          page
        ].filter(
          (attributeId: UID) => action.payload.attributeId !== attributeId
        );
      }),
    validator: (state, action) => {
      return Object.values(EditorPage).includes(action.payload.page);
    },
  },
  [AttributeEventType.ChooseSelect]: {
    generalizer: ({ payload }) =>
      makeGeneralPayload({
        payloadA: payload.attributeId,
        payloadB: payload.page,
        payloadC: payload.selectId || "",
      }),
    specifier: ({ payloadA, payloadB, payloadC }) =>
      createAction[AttributeEventType.ChooseSelect]({
        attributeId: payloadA,
        page: payloadB as EditorPage,
        selectId: payloadC === "" ? undefined : payloadC,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { attributeId, page, selectId } = action.payload;
        draftState.attributes.definitions[page][attributeId].selectId =
          selectId;
      }),
    validator: (state, action) => {
      const { attributeId, page, selectId } = action.payload;
      return (
        Object.values(EditorPage).includes(action.payload.page) &&
        attributeId in state.attributes.definitions[page] &&
        (!selectId || selectId in state.selects.definitions)
      );
    },
  },
};

export { attributeHandlers, AttributeEventType };

export type { AttributeEvent };
