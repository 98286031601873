import produce from "immer";
import { EventGroup, HandlerLookup, makeGeneralPayload } from "../eventUtil";
import { createAction } from "../userEventHandlers";
import { UID } from "../../types/uid";

enum TabListEventType {
  AddTab = "tabList/addTab",
  RemoveTab = "tabList/removeTab",
}

type TabListEvent = EventGroup<{
  [TabListEventType.AddTab]: {
    tabListId: UID;
    tabId: UID;
    filterUnionId: UID;
  };
  [TabListEventType.RemoveTab]: {
    tabListId: UID;
    tabId: UID;
  };
}>;

const tabListHandlers: HandlerLookup<TabListEvent> = {
  [TabListEventType.AddTab]: {
    generalizer: ({ payload: { tabListId, tabId, filterUnionId } }) =>
      makeGeneralPayload({
        payloadA: tabListId,
        payloadB: tabId,
        payloadC: filterUnionId,
      }),
    specifier: ({ payloadA, payloadB, payloadC }) => {
      return createAction[TabListEventType.AddTab]({
        tabListId: payloadA,
        tabId: payloadB,
        filterUnionId: payloadC,
      });
    },
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { tabListId, tabId, filterUnionId } = action.payload;
        draftState.sets[tabListId].push(tabId);
        draftState.exportTabs[tabId] = {
          name: "New tab",
          filterUnionId,
          columnFormatId: undefined,
        };
        draftState.sets[filterUnionId] = [];
      }),
    validator: (state, action) =>
      action.payload.tabListId in state.sets &&
      !state.sets[action.payload.tabListId].includes(action.payload.tabId),
  },
  [TabListEventType.RemoveTab]: {
    generalizer: ({ payload: { tabListId, tabId } }) =>
      makeGeneralPayload({
        payloadA: tabListId,
        payloadB: tabId,
      }),
    specifier: ({ payloadA, payloadB }) => {
      return createAction[TabListEventType.RemoveTab]({
        tabListId: payloadA,
        tabId: payloadB,
      });
    },
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { tabListId, tabId } = action.payload;
        draftState.sets[tabListId] = state.sets[tabListId].filter(
          (tabId) => tabId !== action.payload.tabId,
        );

        const { filterUnionId } = state.exportTabs[tabId];
        state.sets[filterUnionId].forEach((filterIntersectionId) => {
          state.sets[filterIntersectionId].forEach((filterId) => {
            const entrySetId = state.filters[filterId].entrySetId;
            delete draftState.sets[entrySetId];
            delete draftState.filters[filterId];
          });
          delete draftState.sets[filterIntersectionId];
        });
        delete draftState.sets[filterUnionId];

        delete draftState.exportTabs[tabId];
      }),
    validator: (state, action) => {
      return (
        action.payload.tabListId in state.sets &&
        state.sets[action.payload.tabListId].includes(action.payload.tabId)
      );
    },
  },
};

export { tabListHandlers, TabListEventType };
export type { TabListEvent };
