import { ProjectState } from "../projectState";
import { useProjectDispatch, useProjectSelector } from "../../hooks/useEditor";
import { createAction } from "../userEventHandlers";
import { ExportFormatEventType } from "../handlers/handleExportFormats";
import { UID, generateUid } from "../../types/uid";
import { TabListEventType } from "../handlers/handleTabList";
import { ExportTabEventType } from "../handlers/handleExportTabs";
import { Filter } from "../../types/filter";
import { useRecords } from "./useRecords";
import { useColumnExport } from "./useColumnFormats";
import { ColumnExport } from "../util/exportFileUtil";

const useExportTab = (tabId: UID) => {
  const projectDispatch = useProjectDispatch();

  const name = useProjectSelector(
    (project: ProjectState) => project.exportTabs[tabId]?.name,
  );
  const filterUnionId = useProjectSelector(
    (project: ProjectState) => project.exportTabs[tabId]?.filterUnionId,
  );
  const columnFormatId = useProjectSelector(
    (project: ProjectState) => project.exportTabs[tabId]?.columnFormatId,
  );

  const rename = (newName: string) => {
    projectDispatch(
      createAction[ExportTabEventType.Rename]({ tabId, newName }),
    );
  };

  const setColumnFormatId = (columnFormatId?: UID) => {
    projectDispatch(
      createAction[ExportTabEventType.AssignColumnFormat]({
        tabId,
        columnFormatId,
      }),
    );
  };

  return { name, filterUnionId, columnFormatId, setColumnFormatId, rename };
};

const useExportFormat = (formatId: UID) => {
  const projectDispatch = useProjectDispatch();

  const name = useProjectSelector(
    (project: ProjectState) => project.exportFormats.definitions[formatId].name,
  );

  const tabListId = useProjectSelector(
    (project: ProjectState) =>
      project.exportFormats.definitions[formatId].tabListId,
  );

  const tabIds = useProjectSelector(
    (project: ProjectState) => project.sets[tabListId],
  );

  const remove = () => {
    projectDispatch(createAction[ExportFormatEventType.Delete]({ formatId }));
  };

  const rename = (newName: string) => {
    projectDispatch(
      createAction[ExportFormatEventType.Rename]({ formatId, newName }),
    );
  };

  const addTab = () => {
    projectDispatch(
      createAction[TabListEventType.AddTab]({
        tabListId,
        tabId: generateUid(),
        filterUnionId: generateUid(),
      }),
    );
  };

  const removeTab = (tabId: UID) => {
    projectDispatch(
      createAction[TabListEventType.RemoveTab]({
        tabListId,
        tabId,
      }),
    );
  };

  return { remove, rename, addTab, removeTab, name, tabIds };
};

const useExportFormatDownload = (formatId: UID) => {
  const { tabIds } = useExportFormat(formatId);
  const tabs = useProjectSelector((project) => project.exportTabs);
  const { computeUTMRecords, applyFilterUnion } = useRecords();
  const sets = useProjectSelector((project) => project.sets);
  const filterDefinitions = useProjectSelector((project) => project.filters);
  const { computeColumnExport } = useColumnExport();

  const computeDownload = (): {
    name: string;
    columnExport: ColumnExport;
  }[] => {
    const tabFilters: {
      name: string;
      columnFormatId: UID;
      filterUnion: Filter[][];
    }[] = tabIds
      .filter((tabId: UID) => tabs[tabId].columnFormatId)
      .map((tabId: UID) => {
        const tab = tabs[tabId];
        return {
          name: tab.name,
          columnFormatId: tab.columnFormatId!,
          filterUnion: sets[tab.filterUnionId].map((filterIntersectionId) =>
            sets[filterIntersectionId].map(
              (filterId) => filterDefinitions[filterId],
            ),
          ),
        };
      });

    const partialRecords = computeUTMRecords();

    const tabExports: {
      name: string;
      columnExport: ColumnExport;
    }[] = tabFilters.map(({ name, columnFormatId, filterUnion }) => {
      const filteredRecords = applyFilterUnion({ partialRecords, filterUnion });
      return {
        name,
        columnExport: computeColumnExport(columnFormatId, filteredRecords),
      };
    });

    return tabExports;
  };

  return { computeDownload };
};

const useExportFormats = () => {
  const projectDispatch = useProjectDispatch();

  const exportFormatDefinitions = useProjectSelector(
    (project: ProjectState) => project.exportFormats.definitions,
  );

  const exportFormatIds = useProjectSelector(
    (project: ProjectState) => project.exportFormats.ordering,
  );

  const create = () => {
    projectDispatch(
      createAction[ExportFormatEventType.Create]({
        formatId: generateUid(),
        tabListId: generateUid(),
      }),
    );
  };

  return { exportFormatIds, exportFormatDefinitions, create };
};

export {
  useExportTab,
  useExportFormat,
  useExportFormats,
  useExportFormatDownload,
};
