const getDateString = (date: Date) => {
    let now = new Date();
    const sameYear = date.getFullYear() === now.getFullYear();
    const sameDay = now.getTime() - date.getTime() < 24 * 60 * 60 * 1000;
    return new Intl.DateTimeFormat("en-US", {
        year: sameYear ? undefined : "numeric",
        month: sameDay ? undefined : "short",
        day: sameDay ? undefined : "numeric",
        hour: sameDay ? "numeric" : undefined,
        minute: sameDay ? "2-digit" : undefined,
    }).format(date);
};

export default getDateString;