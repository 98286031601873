import {
  Box,
  Button,
  Divider,
  Heading,
  TextField
} from "monday-ui-react-core";
import { PlusCircle } from "@phosphor-icons/react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useBrands } from "../../../hooks/useBrands";

const AgencyBrandAdd = ({ className, agencyId }: { className?: string, agencyId: number }) => {

  const [brandNameToCreate, setBrandNameToCreate] = useState<string>("");

  const {
    brands,
    createBrand
  } = useBrands({ agency_id: agencyId.toString() });


  const createBrandForAgency = () => {
    if (!brandNameToCreate) {
      return;
    }

    createBrand({
      agency_id: agencyId,
      brand_name: brandNameToCreate
    }).then(() => {
      toast.success("Brand created");
      setBrandNameToCreate("");
    });

  };

  return (
    <div className={`${className}`}>

      <Heading type={Heading.types.h2} value={"Brands"} />
      <Divider />

      <Box border={Box.borders.DEFAULT} rounded={Box.roundeds.SMALL} shadow={Box.shadows.MEDIUM}>
        <div className="flex flex-row gap-4 w-1/3 p-8">
          <TextField
            iconName={PlusCircle}
            placeholder="Brand Name"
            wrapperClassName="monday-storybook-text-field_size"
            onChange={(value) => setBrandNameToCreate(value)}
            value={brandNameToCreate}
          />
          <Button onClick={createBrandForAgency}
                  size={Button.sizes.SMALL}
                  kind={Button.kinds.SECONDARY}
                  color={Button.colors.POSITIVE}
                  rightIcon={PlusCircle}>Add</Button>
        </div>
      </Box>

    </div>
  );
};

export { AgencyBrandAdd };