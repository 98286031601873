import { useProjectDispatch, useProjectSelector } from "../../hooks/useEditor";
import { SourceIconType } from "../../types/touchpoints";
import { UID, generateUid } from "../../types/uid";
import { SourceEventType } from "../handlers/handleSource";
import { ProjectState } from "../projectState";
import { createAction } from "../userEventHandlers";

const useSource = (sourceId: UID) => {
  const projectDispatch = useProjectDispatch();

  const icon = useProjectSelector(
    (project: ProjectState) =>
      project.touchpoints.sourceDefinitions[sourceId].icon
  );

  const mediumIds = useProjectSelector(
    (project: ProjectState) =>
      project.touchpoints.sourceDefinitions[sourceId].mediumOrdering
  );

  const name = useProjectSelector(
    (project: ProjectState) =>
      project.touchpoints.sourceDefinitions[sourceId].title
  );

  const utmValue = useProjectSelector(
    (project: ProjectState) =>
      project.touchpoints.sourceDefinitions[sourceId].utmValue
  );

  const remove = () => {
    projectDispatch(createAction[SourceEventType.Delete]({ sourceId }));
  };

  const rename = (newName: string) => {
    projectDispatch(
      createAction[SourceEventType.Rename]({ sourceId, newName })
    );
  };

  const changeIcon = (newIcon: SourceIconType) => {
    projectDispatch(
      createAction[SourceEventType.ChangeIcon]({ sourceId, newIcon })
    );
  };

  const editUTM = (newUTM: string) => {
    projectDispatch(
      createAction[SourceEventType.EditUTM]({ sourceId, newUTM })
    );
  };

  return {
    icon,
    mediumIds,
    name,
    utmValue,
    remove,
    rename,
    changeIcon,
    editUTM,
  };
};

const useSources = () => {
  const projectDispatch = useProjectDispatch();

  const sourceIds = useProjectSelector(
    (project: ProjectState) => project.touchpoints.sourceOrdering
  );

  const sourceDefinitions = useProjectSelector(
    (project: ProjectState) => project.touchpoints.sourceDefinitions
  );

  const create = (title: string, icon: SourceIconType, utmValue: string) => {
    const sourceId = generateUid();
    projectDispatch(
      createAction[SourceEventType.Create]({ sourceId, title, icon, utmValue })
    );
  };

  return { sourceIds, sourceDefinitions, create };
};

export { useSources, useSource };
