import { Sparkle } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";

const Logo = ({ className }: { className?: string }) => {
  const navigate = useNavigate();
  return (
    <div className={`${className} flex h-full justify-center items-center`}>
      <button
          className="w-24"
          onClick={() => {
            navigate("/campaigns");
          }}
        >
          <div
            className="
flex items-center justify-start gap-1
hover:scale-110 transition-transform duration-50 ease-in-out
"
          >
            <Sparkle size={22} />
            <div>Adstrala</div>
          </div>
        </button>
    </div>
  );
};

export { Logo };
