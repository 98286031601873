import { Checkbox as MondayCheckbox } from "monday-ui-react-core";
import { useEffect, useState } from "react";
import { AttributeTitle } from "./AttributeTitle";

const Checkbox = ({
  className,
  initialValue,
  onChange = () => {},
  title,
}: {
  className?: string;
  initialValue?: boolean;
  onChange?: (newValue: boolean) => void;
  title?: string;
}) => {
  const [isChecked, setIsChecked] = useState(initialValue);

  useEffect(() => {
    setIsChecked(initialValue);
  }, [initialValue]);

  return (
    <div className={`${className} flex flex-col gap-0.5`}>
      <AttributeTitle text={title} />
      <MondayCheckbox
        checked={isChecked}
        onChange={() => {
          onChange(!isChecked);
        }}
      />
    </div>
  );
};

export { Checkbox };
