import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { DebugActionType } from "../redux/debugReducer";

const useDebug = () => {
  const isOffline = useSelector(
    (state: RootState) => state.debug.offlineActivated
  );

  const dispatch = useDispatch();

  const goOffline = () => {
    dispatch({ type: DebugActionType.OfflineActivated });
  };

  const goOnline = () => {
    dispatch({ type: DebugActionType.OfflineDeactivated });
  };

  return { isOffline, goOnline, goOffline };
};

export { useDebug };
