import { useEffect, useState } from "react";
import { AttributeTitle } from "./AttributeTitle";
import CurrencyInput from "react-currency-input-field";
import { TextField } from "monday-ui-react-core";
import { TextFieldTextType } from "monday-ui-react-core/dist/types/components/TextField/TextFieldConstants";

type InputProps = {
  className?: string;
  initialValue: string;
  onChange?: (newValue: string) => void;
  title?: string;
  onEnterPress?: () => void;
};

interface GeneralNumericInputProps extends InputProps {
  prefix: string;
  decimals: number;
}

const GeneralNumericInput = ({
  className,
  initialValue,
  onChange = () => {},
  title,
  prefix,
  decimals,
  onEnterPress,
}: GeneralNumericInputProps) => {
  const [currentValue, setCurrentValue] = useState<string>(initialValue);

  useEffect(() => {
    setCurrentValue(initialValue);
  }, [initialValue]);

  return (
    <div className={`${className} flex flex-col gap-0.5`}>
      <AttributeTitle text={title} />
      <CurrencyInput
        placeholder={prefix}
        className="
        h-8 px-2 text-sm
        border rounded border-slate-300 focus:border-blue-500 hover:border-black
        bg-transparent  outline-none"
        prefix={prefix + " "}
        decimalsLimit={decimals}
        onValueChange={(value) => {
          setCurrentValue(value || "");
        }}
        onBlur={() => {
          onChange(currentValue);
        }}
        value={currentValue}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            if (currentValue !== initialValue) {
              onChange(currentValue);
            }
            if (onEnterPress) onEnterPress();
          }
        }}
      />
    </div>
  );
};

const NumberInput = ({
  className,
  initialValue,
  onChange = () => {},
  title,
  onEnterPress,
}: InputProps) => {
  return (
    <GeneralNumericInput
      className={className}
      initialValue={initialValue}
      onChange={onChange}
      title={title}
      prefix={"#"}
      decimals={10}
      onEnterPress={onEnterPress}
    />
  );
};

const Currency = ({
  className,
  initialValue,
  onChange = () => {},
  title,
  onEnterPress,
}: InputProps) => {
  return (
    <GeneralNumericInput
      className={className}
      initialValue={initialValue}
      onChange={onChange}
      title={title}
      prefix={"$"}
      decimals={2}
      onEnterPress={onEnterPress}
    />
  );
};

const GeneralStringInput = ({
  className,
  initialValue,
  onChange = () => {},
  title,
  type,
  onEnterPress,
}: {
  className?: string;
  initialValue: string;
  onChange?: (newValue: string) => void;
  title?: string;
  type: TextFieldTextType;
  onEnterPress?: () => void;
}) => {
  const [currentValue, setCurrentValue] = useState<string>(initialValue);

  useEffect(() => {
    setCurrentValue(initialValue);
  }, [initialValue]);

  return (
    <div className={`${className} flex flex-col gap-0.5 w-52`}>
      <AttributeTitle text={title} />
      <TextField
        value={currentValue}
        size={TextField.sizes.SMALL}
        type={type}
        onChange={(value: string) => {
          setCurrentValue(value);
        }}
        onBlur={() => {
          if (currentValue !== initialValue) {
            onChange(currentValue);
          }
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            if (currentValue !== initialValue) {
              onChange(currentValue);
            }
            if (onEnterPress) onEnterPress();
          }
        }}
      />
    </div>
  );
};

const DateInput = ({
  className,
  initialValue,
  onChange = () => {},
  title,
  onEnterPress,
}: InputProps) => (
  <GeneralStringInput
    className={className}
    initialValue={initialValue}
    onChange={onChange}
    onEnterPress={onEnterPress}
    title={title}
    type={TextField.types.DATE}
  />
);

const TextInput = ({
  className,
  initialValue,
  onChange = () => {},
  title,
  onEnterPress,
}: InputProps) => (
  <GeneralStringInput
    className={className}
    initialValue={initialValue}
    onChange={onChange}
    onEnterPress={onEnterPress}
    title={title}
    type={TextField.types.TEXT}
  />
);

export { NumberInput, Currency as CurrencyInput, DateInput, TextInput };
