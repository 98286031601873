import { ArrowsClockwise } from "@phosphor-icons/react";
import { IconButton } from "monday-ui-react-core";

const RefreshButton = ({
  className,
  onRefresh,
}: {
  className?: string;
  onRefresh?: () => void;
}) => {
  return (
    <div className={`${className} w-4`}>
      <IconButton
        icon={ArrowsClockwise}
        onClick={() => (onRefresh ? onRefresh() : {})}
        size={IconButton.sizes.XS}
      />
    </div>
  );
};

export { RefreshButton };
