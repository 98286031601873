import { useProjectDispatch, useProjectSelector } from "../../hooks/useEditor";
import { UID, generateUid } from "../../types/uid";
import { ColumnEventType } from "../handlers/handleColumns";
import { ProjectState } from "../projectState";
import { createAction } from "../userEventHandlers";

const useColumn = (columnFormatId: UID, columnId: UID) => {
  const projectDispatch = useProjectDispatch();
  const name = useProjectSelector(
    (project: ProjectState) =>
      project.columnFormats.definitions[columnFormatId].columnDefinitions[
        columnId
      ].name
  );

  const attributeId = useProjectSelector(
    (project: ProjectState) =>
      project.columnFormats.definitions[columnFormatId].columnDefinitions[
        columnId
      ].attributeId
  );

  const duplicate = () =>
    projectDispatch(
      createAction[ColumnEventType.Duplicate]({
        columnFormatId,
        referenceColumnId: columnId,
        newColumnId: generateUid(),
      })
    );

  const remove = () =>
    projectDispatch(
      createAction[ColumnEventType.Delete]({ columnFormatId, columnId })
    );

  const rename = (newName: string) =>
    projectDispatch(
      createAction[ColumnEventType.Rename]({
        columnFormatId,
        columnId,
        newName,
      })
    );

  const changeAttributeId = (newAttributeId?: UID) =>
    projectDispatch(
      createAction[ColumnEventType.ChangeAttribute]({
        columnFormatId,
        columnId,
        newAttributeId,
      })
    );

  return { name, attributeId, duplicate, remove, rename, changeAttributeId };
};

const useColumns = (columnFormatId: UID) => {
  const projectDispatch = useProjectDispatch();
  const columnDefinitions = useProjectSelector(
    (project: ProjectState) =>
      project.columnFormats.definitions[columnFormatId].columnDefinitions
  );

  const columnIds = useProjectSelector(
    (project: ProjectState) =>
      project.columnFormats.definitions[columnFormatId].columnOrdering
  );

  const create = () => {
    projectDispatch(
      createAction[ColumnEventType.Create]({
        columnFormatId,
        columnId: generateUid(),
      })
    );
  };

  return { columnDefinitions, columnIds, create };
};

export { useColumn, useColumns };
