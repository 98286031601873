/**
 * Commputes intersection of two comma separated sets
 * commaSeparatedIntersection("1,2,3", "2,3,4") = "2,3"
 * @param {string} a first string in intersection
 * @param {string} b second string in intersection
 * @returns {string} intersection of a and b
 */
const commaSeparatedIntersection = (a: string, b: string): string => {
  const bSet = new Set(b.split(","));
  const intersect = new Set(a.split(",").filter((x) => bSet.has(x)));
  return Array.from(intersect).join(",");
};

/**
 * Commputes intersection of two comma separated sets
 * commaSeparatedIntersection("1,2,3", "2,3,4") = "1,2,3,4"
 * @param {string} a first string in intersection
 * @param {string} b second string in intersection
 * @returns {string} intersection of a and b
 */
const commaSeparatedUnion = (a: string, b: string): string => {
  const union = new Set([...a.split(","), ...b.split(",")]);
  return Array.from(union).join(",");
};

export { commaSeparatedIntersection, commaSeparatedUnion };
