import {
  AttentionBox,
  Button, Divider,
  Heading,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text
} from "monday-ui-react-core";
import { Trash } from "@phosphor-icons/react";
import { ReactNode, useState } from "react";
import { useAccount } from "../../hooks/useAccount";
import { toast } from "react-toastify";
import { useAuth } from "../../AuthContext";

function StoryDescription(props: { description: string, children: ReactNode }) {
  return null;
}

const Deactivate = ({ className }: { className?: string }) => {

  const [isAttemptingToDeactivateAccount, setIsAttemptingToDeactivateAccount] = useState<boolean>(false);

  const {
    account,
    isLoading,
    updateAccount
  } = useAccount();

  const { signOut } = useAuth();

  const deactivate = () => {
    if (!account) {
      return;
    }
    updateAccount({ ...account, is_active: false }).then(() => {
      toast.success("Account deactivated. You will be logged out.", {
        autoClose: 4000
      });

      setTimeout(() => {
        signOut(true);
      }, 5000);

    });
  };

  return (
    <div className={`${className} flex flex-col gap-2 bg-slate-50`}>

      <Heading className="text-orange-600" type={Heading.types.h2} value={"Deactivate account"} />
      <Divider />


      <AttentionBox
        className="w-1/3"
        title="Are you sure?"
        text="Once you deactivate your account, you will no longer be able to access your account."
        type={AttentionBox.types.DANGER}
      />


      <div className="w-1/3">
        <Button onClick={() => setIsAttemptingToDeactivateAccount(true)}
                color={Button.colors.NEGATIVE}
                kind={Button.kinds.SECONDARY}
                rightIcon={Trash}>Deactivate Account</Button>
      </div>

      <Modal
        data-testid={"modal_deactivate_account"}
        show={isAttemptingToDeactivateAccount}
        onClose={() => {
          setIsAttemptingToDeactivateAccount(false);
        }}
      >
        <ModalHeader title="Deactivate account?" />
        <ModalContent>
          <div className="pb-2">
            Are you sure you want deactivate your account, this action cannot be undone?
          </div>
        </ModalContent>
        <ModalFooter className="flex justify-start gap-2">
          <Button kind={Button.kinds.PRIMARY} onClick={() => {
            setIsAttemptingToDeactivateAccount(false);
          }
          }>
            Cancel
          </Button>
          <Button
            kind={Button.kinds.SECONDARY}
            onClick={() => {
              deactivate();
              setIsAttemptingToDeactivateAccount(false);
            }}
            color={Button.colors.NEGATIVE}
            rightIcon={Trash}
          >
            Deactivate account
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export { Deactivate };