import { useState, createContext, useContext, useEffect } from "react";
import { useLocation, Navigate } from "react-router-dom";
import {
  LoginResponse,
  checkLogin,
  loginRequest,
  logoutRequest,
} from "./api/login";
import { Grid } from "react-loader-spinner";
import { z } from "zod";

type SignInMethodType = (
  userEmail: string,
  userPassword: string
) => Promise<LoginResponse>;
type SignOutMethodType = (isUserPrompted: boolean) => void;

const userSchema = z.object({ email: z.string() });

type User = z.infer<typeof userSchema>;

interface AuthContextType {
  user?: User;
  isLoading: boolean;
  signIn: SignInMethodType;
  signOut: SignOutMethodType;
}

const AuthContext = createContext<AuthContextType>(null!);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User>();
  const [isLoading, setIsLoading] = useState(true);

  const handleLoginMessage = (loginMessage: LoginResponse) => {
    if (loginMessage.successful) {
      setUser({ email: loginMessage.data.result });
    } else {
      setUser(undefined);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      const loginMessage = await checkLogin();
      handleLoginMessage(loginMessage);
    })();
  }, []);

  const signIn: SignInMethodType = async (userEmail, userPassword) => {
    const loginMessage: LoginResponse = await loginRequest(
      userEmail,
      userPassword
    );
    handleLoginMessage(loginMessage);
    return loginMessage;
  };

  const signOut: SignOutMethodType = async (isUserPrompted) => {
    setIsLoading(true);
    setUser(undefined);
    if (isUserPrompted) {
      await logoutRequest();
    }
    setIsLoading(false);
  };

  const value: AuthContextType = { user, isLoading, signIn, signOut };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  return useContext(AuthContext);
};

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const location = useLocation();

  if (auth.isLoading) {
    return (
      <div className="flex h-screen w-screen items-center justify-center ">
        <Grid
          height="80"
          width="80"
          color="#fb923c"
          ariaLabel="grid-loading"
          radius="12.5"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  if (!auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export { AuthProvider, RequireAuth, useAuth };
