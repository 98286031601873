import { Envelope } from "@phosphor-icons/react";
import { Button, TextField, Text } from "monday-ui-react-core";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { LoginFailureReason } from "../api/login";

const authenticatedStartPage = "/campaigns";

const Login = ({ className }: { className?: string }) => {
  const [isAttemptingLogin, setIsAttemptingLogin] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginFailMessage, setLoginFailMessage] = useState<string | undefined>(
    undefined
  );
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  const getFailureMessage = (reason: LoginFailureReason): string => {
    switch (reason) {
      case LoginFailureReason.InvalidCredentials:
        return "Invalid username or password";
      default:
        return "Issue processing login. Please try again later.";
    }
  };

  const clearLoginFailure = () => {
    if (loginFailMessage) {
      setLoginFailMessage(undefined);
    }
  };

  useEffect(() => {
    if (auth.user && !auth.isLoading) {
      navigate(location.state?.from?.pathname || authenticatedStartPage);
    }
  }, [auth.user, auth.isLoading, location.state, navigate]);

  const attemptLogin = async () => {
    setIsAttemptingLogin(true);
    const loginAttempt = await auth.signIn(email, password);
    setIsAttemptingLogin(false);
    if (loginAttempt.successful) {
      navigate(location.state?.from?.pathname || authenticatedStartPage);
    } else {
      setLoginFailMessage(getFailureMessage(loginAttempt.failureReason));
    }
  };

  return (
    <div className={`${className} h-screen grid grid-cols-12 gap-2`}>
      <div className="col-span-7 flex flex-col gap-2 justify-center max-w-sm mx-auto">
        <div className="text-center text-lg">Sign in</div>
        <TextField
          id="email"
          title="Email"
          iconName={Envelope}
          size={TextField.sizes.MEDIUM}
          onChange={(val) => {
            setEmail(val);
            clearLoginFailure();
          }}
          validation={{ status: loginFailMessage ? "error" : undefined }}
        />
        <TextField
          id="password"
          title="Password"
          type={TextField.types.PASSWORD}
          size={TextField.sizes.MEDIUM}
          onChange={(val) => {
            setPassword(val);
            clearLoginFailure();
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") attemptLogin();
          }}
          validation={{ status: loginFailMessage ? "error" : undefined }}
        />
        <div className="flex flex-col items-center">
          {loginFailMessage && (
            <div className="text-sm text-red-500 pb-2">{loginFailMessage}</div>
          )}
          <Button
            onClick={attemptLogin}
            loading={isAttemptingLogin}
            className="w-full"
          >
            Login
          </Button>
        </div>
        <div className="flex flex-col items-center gap-2 pt-12">
          <Text paragraph>Don't have an organization?</Text>
          <Button
            kind={Button.kinds.SECONDARY}
            onClick={() => {
              navigate("/signup");
            }}
          >
            Start new org
          </Button>
        </div>
      </div>
      <div className="col-span-5 bg-orange-100"></div>
    </div>
  );
};

export { Login };
