import {useApiInstance} from "../api/useApiInstance";
import {z} from "zod";
import {Brand, BrandSchema} from "./useBrand";
import {useSWRConfig} from "swr";

const BrandsSchema = z.array(BrandSchema);

const useBrands = (filters?: {
    agency_id: string;
}) => {

    const {mutate: globalMutate} = useSWRConfig();
    const {instance, useWrappedSWR} = useApiInstance();
    const url = "/brands" + (filters ? "?" + new URLSearchParams(filters) : "");
    const {data, mutate, isLoading, error} = useWrappedSWR(url);
    const brands: Brand[] = data && !error ? BrandsSchema.parse(data) : [];

    const createBrand = async (updateRequest: {
        agency_id: number;
        brand_name: string;
    }) => {
        const {data} = await instance.post("/brands", {
            agency_id: updateRequest.agency_id,
            brand_name: updateRequest.brand_name,
        });
        await globalMutate(url);
        return data;
    }

    const updateBrand = async (updateRequest: {
        brand_id: number | string;
        brand_name: string;
    }) => {
        const {data} = await instance.put("/brands/" + updateRequest.brand_id, {
            brand_name: updateRequest.brand_name,
        });
        await globalMutate(url);
        await globalMutate(`/account`);
        return data;
    }

    const deleteBrand = async (brand_id: number | string) => {
        const {data} = await instance.delete("/brands/" + brand_id);
        await globalMutate(url);
        await globalMutate(`/account`);
        return data;
    }

    return {brands, createBrand, updateBrand, deleteBrand, isLoading};
};

export {useBrands};
