import produce from "immer";
import { EventGroup, HandlerLookup, makeGeneralPayload } from "../eventUtil";
import { createAction } from "../userEventHandlers";
import { UID } from "../../types/uid";

enum ExportTabEventType {
  Rename = "exportTab/rename",
  AssignColumnFormat = "exportTab/assignColumnFormat",
}

type ExportTabEvent = EventGroup<{
  [ExportTabEventType.Rename]: {
    tabId: UID;
    newName: string;
  };
  [ExportTabEventType.AssignColumnFormat]: {
    tabId: UID;
    columnFormatId?: UID;
  };
}>;

const exportTabHandlers: HandlerLookup<ExportTabEvent> = {
  [ExportTabEventType.Rename]: {
    generalizer: ({ payload: { tabId, newName } }) =>
      makeGeneralPayload({
        payloadA: tabId,
        payloadB: newName,
      }),
    specifier: ({ payloadA, payloadB }) => {
      return createAction[ExportTabEventType.Rename]({
        tabId: payloadA,
        newName: payloadB,
      });
    },
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { tabId, newName } = action.payload;
        draftState.exportTabs[tabId].name = newName;
      }),
    validator: (state, action) => {
      const { tabId } = action.payload;
      return tabId in state.exportTabs;
    },
  },
  [ExportTabEventType.AssignColumnFormat]: {
    generalizer: ({ payload: { tabId, columnFormatId } }) =>
      makeGeneralPayload({
        payloadA: tabId,
        payloadB: columnFormatId || "",
      }),
    specifier: ({ payloadA, payloadB }) => {
      return createAction[ExportTabEventType.AssignColumnFormat]({
        tabId: payloadA,
        columnFormatId: payloadB === "" ? undefined : payloadB,
      });
    },
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { tabId, columnFormatId } = action.payload;
        draftState.exportTabs[tabId].columnFormatId = columnFormatId;
      }),
    validator: (state, action) => {
      const { tabId } = action.payload;
      return tabId in state.exportTabs;
    },
  },
};

export { exportTabHandlers, ExportTabEventType };
export type { ExportTabEvent };
