import { Reducer } from "@reduxjs/toolkit";
import {
  UserEvent,
  eventsAreEqual,
  generalizeEvent,
  reduceEvent,
} from "../editor/eventUtil";
import {
  ProjectState,
  buildState,
  initialProjectState,
} from "../editor/projectState";
import { EventID, GeneralEvent } from "../editor/eventUtil";
import { AcceptedEvent, convertToGeneralEvent } from "../hooks/useEventStore";
import { ProjectId } from "../types/util";
import { MicroDate } from "../types/zod";

type EditorState = {
  verifiedState: ProjectState;
  eventQueue: GeneralEvent[];
  liveState: ProjectState;
  verifiedMicrodateString?: string;
  lastVerifiedEventID?: EventID;
  eventBeingPublished?: GeneralEvent;
  projectId?: string;
  isLoading: boolean;
};

const initialState: EditorState = {
  verifiedState: initialProjectState,
  eventQueue: [],
  liveState: initialProjectState,
  verifiedMicrodateString: undefined,
  lastVerifiedEventID: undefined,
  eventBeingPublished: undefined,
  projectId: undefined,
  isLoading: true,
};

enum EditorActionType {
  ProjectOpened = "editor/projectOpened",
  ProjectClosed = "editor/projectClosed",
  EventTriggered = "editor/eventTriggered",
  PublishStarted = "editor/publishStarted",
  AcceptEventReceived = "editor/acceptEventReceived",
  PostponeEventReceived = "editor/postponeEventReceivedAction",
  Reconnected = "editor/reconnected",
  PublishEventAccepted = "editor/publishEventAccepted",
  PublishEventPostponed = "editor/publishEventPostponed",
  InvalidEvent = "editor/invalidEvent"
}

type ProjectOpenedAction = {
  type: EditorActionType.ProjectOpened;
  payload: { projectId: ProjectId; events: AcceptedEvent[] };
};

type ProjectClosedAction = {
  type: EditorActionType.ProjectClosed;
};

type EventTriggeredAction = {
  type: EditorActionType.EventTriggered;
  payload: {
    event: UserEvent;
  };
};

type PublishStartedAction = {
  type: EditorActionType.PublishStarted;
  payload: {
    event: GeneralEvent;
  };
};

type AcceptEventReceivedAction = {
  type: EditorActionType.AcceptEventReceived;
  payload: {
    acceptedEvent: AcceptedEvent;
  };
};

type PublishEventAcceptedAction = {
  type: EditorActionType.PublishEventAccepted;
  payload: {
    acceptedEvent: AcceptedEvent;
  };
};

type PublishEventPostponedAction = {
  type: EditorActionType.PublishEventPostponed;
};

type InvalidEventAction = {
  type: EditorActionType.InvalidEvent;
  payload: {
    invalidEvent: UserEvent;
  }
};

type ReconnectedAction = {
  type: EditorActionType.Reconnected;
  payload: {
    unseenEvents: AcceptedEvent[];
  };
};

type EditorAction =
  | ProjectOpenedAction
  | ProjectClosedAction
  | EventTriggeredAction
  | AcceptEventReceivedAction
  | PublishEventAcceptedAction
  | PublishEventPostponedAction
  | PublishStartedAction
  | ReconnectedAction
  | InvalidEventAction
  ;

const editorReducer: Reducer<EditorState, EditorAction> = (
  state: EditorState = initialState,
  action: EditorAction,
): EditorState => {
  switch (action.type) {
    case EditorActionType.ProjectOpened: {
      const events = action.payload.events;
      const lastEvent =
        events.length > 0 ? events[events.length - 1] : undefined;
      const generalEvents = action.payload.events.map(convertToGeneralEvent);
      const initVerifiedState = buildState(initialProjectState, generalEvents);
      return {
        ...state,
        verifiedState: initVerifiedState,
        verifiedMicrodateString: lastEvent?.created_at,
        liveState: initVerifiedState,
        lastVerifiedEventID: lastEvent?.id,
        projectId: action.payload.projectId,
        isLoading: false,
      };
    }
    case EditorActionType.ProjectClosed: {
      return initialState;
    }
    case EditorActionType.EventTriggered: {
      const userEvent: UserEvent = action.payload.event;
      const generalEvent: GeneralEvent = generalizeEvent(userEvent);

      return {
        ...state,
        liveState: reduceEvent(state.liveState, userEvent),
        eventQueue: [...state.eventQueue, generalEvent],
      };
    }
    case EditorActionType.AcceptEventReceived: {
      const acceptedEvent: AcceptedEvent = action.payload.acceptedEvent;
      // An accept event could have already been taken into account if we have run a "catch up"
      if (
        state.verifiedMicrodateString &&
        new MicroDate(acceptedEvent.created_at) <
        new MicroDate(state.verifiedMicrodateString)
      ) {
        return state;
      }
      const newEvent: GeneralEvent = convertToGeneralEvent(acceptedEvent);
      const newVerifiedState = buildState(state.verifiedState, [newEvent]);
      const eventIsOurs =
        state.eventBeingPublished &&
        eventsAreEqual(newEvent, state.eventBeingPublished);
      const newLiveState = eventIsOurs
        ? state.liveState
        : buildState(newVerifiedState, state.eventQueue);
      return {
        ...state,
        liveState: newLiveState,
        verifiedState: newVerifiedState,
        lastVerifiedEventID: acceptedEvent.id,
        verifiedMicrodateString: acceptedEvent.created_at,
      };
    }
    case EditorActionType.PublishEventAccepted: {
      const event: GeneralEvent = convertToGeneralEvent(
        action.payload.acceptedEvent,
      );
      if (
        state.eventQueue.length < 1 ||
        !eventsAreEqual(event, state.eventQueue[0])
      ) {
        throw new Error(
          `Publish: Event ${JSON.stringify(event)} was not next.`,
        );
      }
      return {
        ...state,
        eventQueue: state.eventQueue.slice(1),
        eventBeingPublished: undefined,
      };
    }
    case EditorActionType.PublishEventPostponed: {
      return { ...state, eventBeingPublished: undefined };
    }
    case EditorActionType.PublishStarted: {
      return { ...state, eventBeingPublished: action.payload.event };
    }
    case EditorActionType.Reconnected: {
      const newEvents: AcceptedEvent[] = action.payload.unseenEvents.filter(
        (e: AcceptedEvent) =>
          !state.verifiedMicrodateString ||
          new MicroDate(e.created_at) >
          new MicroDate(state.verifiedMicrodateString),
      );
      const generalEvents = newEvents.map(convertToGeneralEvent);
      const lastNewEvent =
        newEvents.length > 0 ? newEvents[newEvents.length - 1] : undefined;
      const newVerifiedStateTime = lastNewEvent
        ? lastNewEvent.created_at
        : state.verifiedMicrodateString;
      const newVerifiedState = buildState(state.verifiedState, generalEvents);
      const newLiveState = buildState(newVerifiedState, state.eventQueue);
      const lastEventId = lastNewEvent
        ? lastNewEvent.id
        : state.lastVerifiedEventID;
      return {
        ...state,
        liveState: newLiveState,
        verifiedState: newVerifiedState,
        verifiedMicrodateString: newVerifiedStateTime,
        lastVerifiedEventID: lastEventId,
      };
    }
    case EditorActionType.InvalidEvent: {
      return {
        ...state,
        liveState: state.verifiedState,
        eventQueue: [],
        eventBeingPublished: undefined,
      };
    }
    default:
      return state;
  }
};

export { editorReducer, EditorActionType };

export type {
  EditorState,
  EditorAction,
  ProjectOpenedAction,
  EventTriggeredAction,
  PublishStartedAction,
  AcceptEventReceivedAction,
  PublishEventAcceptedAction,
  PublishEventPostponedAction,
  ProjectClosedAction,
  ReconnectedAction,
  InvalidEventAction,
};
