import {
  Button,
  Divider,
  Heading,
  Menu,
  MenuButton,
  MenuItem, Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text
} from "monday-ui-react-core";
import { Trash } from "@phosphor-icons/react";
import { useState } from "react";
import { Brand, useAccount } from "../../../hooks/useAccount";
import getDateString from "../../../utils/date/DateUtils";
import { toast } from "react-toastify";

const BrandMembership = ({ className }: { className?: string }) => {

  const [isAttemptingToCancelMembership, setIsAttemptingToCancelMembership] = useState<boolean>(false);
  const [brandToCancelMembership, setBrandToCancelMembership] = useState<Brand | undefined>(undefined);

  const {
    account,
    cancelBrandMembership
  } = useAccount();

  const deleteMemberFromBrand = () => {
    if (!brandToCancelMembership || !account) {
      return;
    }

    cancelBrandMembership({
      brand_id: brandToCancelMembership.id,
      member_id: account.id
    }).then(() => {
      toast.success("Membership cancelled");
      setBrandToCancelMembership(undefined);
      setIsAttemptingToCancelMembership(false);
    });
  };

  if (!account) {
    return <></>;
  }

  return (
    <div className={`${className}`}>
      <Heading type={Heading.types.h2} value={"Your Brands"} />
      <Divider className="my-4" />
      <>
        <div
          className="flex shadow hover:bg-slate-50 w-full bg-white gap-2 py-2 px-4 mb-1 rounded-md items-center">
          <div className="grid grid-cols-10 w-full">
            <div className="col-span-3 text-start">
              <Text weight={"bold"}>Name</Text>
            </div>
            <div className="col-span-3 text-start">
              <Text weight={"bold"}>Agency</Text>
            </div>
            <div className="col-span-3 text-start">
              <Text weight={"bold"}>Join date</Text>
            </div>
            <div className="col-span-1"></div>
          </div>
        </div>
        {account.brands.map((brand: Brand) => {
          return (
            <div key={brand.id}>
              <div
                className="flex shadow hover:bg-slate-50 w-full bg-white gap-2 py-2 px-4 mb-1 rounded-md items-center">
                <div className="grid grid-cols-10 w-full">
                  <div className="col-span-3 text-start">
                    {brand.name}
                  </div>
                  <div className="col-span-3 text-start">
                    {brand.agency_name}
                  </div>
                  <div className="col-span-3 text-start">
                    {getDateString(new Date(brand.joined_at))}
                  </div>
                  <div className="col-span-1">
                    <MenuButton
                      className="float-right"
                      size={MenuButton.sizes.XS}
                      dialogPosition={MenuButton.dialogPositions.BOTTOM_START}>
                      <Menu id={`membership-brand-${brand.id}`}>
                        <MenuItem
                          icon={Trash}
                          title="Cancel membership"
                          onClick={() => {
                            setBrandToCancelMembership(brand);
                            setIsAttemptingToCancelMembership(true);
                          }}
                        />
                      </Menu>
                    </MenuButton>
                  </div>
                </div>

              </div>
            </div>
          );
        })}
      </>
      <Modal
        data-testid={"modal_delete_membership_brand"}
        show={isAttemptingToCancelMembership}
        onClose={() => {
          setBrandToCancelMembership(undefined);
          setIsAttemptingToCancelMembership(false);
        }}
      >
        <ModalHeader title="Cancel membership?" />
        <ModalContent>
          <div className="pb-2">
            Are you sure you want to cancel your membership?
            <Text weight={"bold"}>{brandToCancelMembership?.name}</Text>
          </div>
        </ModalContent>
        <ModalFooter className="flex justify-start gap-2">
          <Button kind={Button.kinds.PRIMARY} onClick={() => {
            setBrandToCancelMembership(undefined);
            setIsAttemptingToCancelMembership(false);
          }
          }>
            Cancel
          </Button>
          <Button
            kind={Button.kinds.SECONDARY}
            onClick={() => {
              deleteMemberFromBrand();
              setIsAttemptingToCancelMembership(false);
            }}
            rightIcon={Trash}
            color={Button.colors.NEGATIVE}
          >
            Cancel membership
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export { BrandMembership };