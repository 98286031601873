import { EditableHeading } from "monday-ui-react-core";
import { useProject } from "../hooks/useProjects";
import { toast } from "react-toastify";
import React, { useEffect } from "react";

const NavProjectName = ({
  className,
  projectId,
}: {
  className?: string;
  projectId: string;
}) => {
  const { project, renameProject } = useProject(projectId);

  useEffect(() => {
    if (!project) {
      return;
    }
    document.title = project.name;

    return function cleanup() {
      document.title = process.env.REACT_APP_TITLE || "";
    };
  }, [project]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      toast.error("Project name cannot be empty");
      return;
    }

    renameProject(e.target.value);
  };

  const handleKeyboardEvent = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      (e.target as HTMLInputElement).blur();
    }
  };

  return (
    <div className={`${className} flex items-center`}>
      <EditableHeading
        type={EditableHeading.types.h4}
        className="text-left w-1/4"
        value={project?.name || ""}
        onBlur={(e) =>
          handleChange(e as unknown as React.ChangeEvent<HTMLInputElement>)
        }
        onKeyDown={(e) => handleKeyboardEvent(e as unknown as KeyboardEvent)}
      />
    </div>
  );
};

export { NavProjectName };
