import { UID } from "./uid";
import { UTMParamType } from "./utm";

enum FilterType {
  // e.g. checkbox "is one of" checked and/or unchecked
  TrueFalseSet = "trueFalseSet",
  // e.g. text includes some substring
  AttributeValueSubstring = "attributeValueSubstring",
  // e.g. UTM campaign is for Case IH
  UTMParam = "utmParam",
  // e.g. set of offer ids
  EntityIdSet = "entityIdSet",
  // e.g. set of possible select values
  SelectSet = "selectSet",
  // e.g. Date is after 4/02/23
  ValueComparison = "valueComparison",
  // convenience type for no filter
  None = "none",
}

enum FilterEntityType {
  Offer = "offer",
  Target = "target",
  Touchpoint = "touchpoint",
  UTMParam = "utmParam",
}

enum BoolType {
  True = "true",
  False = "false",
}

enum SetMatchType {
  Inclusion = "inclusion",
}

enum ComparisonType {
  Equal = "equal",
  NotEqual = "notEqual",
  GreaterThan = "greaterThan",
  GreaterThanEq = "greaterThanEq",
  LessThan = "lessThan",
  LessThanEq = "lessThanEq",
  Between = "between",
  NotBetween = "notBetween",
}

enum RefValueType {
  Primary = "primary",
  Secondary = "secondary",
}

type CommonFilterProperties = {
  entityType: FilterEntityType;
  userFriendlyType: string;
  entrySetId: UID;
};

interface FilterAttributeValue extends CommonFilterProperties {
  type: FilterType.TrueFalseSet;
  attributeId?: UID;
}

interface FilterAttributeSubstring extends CommonFilterProperties {
  type: FilterType.AttributeValueSubstring;
  attributeId?: UID;
  substring: string;
}

interface UTMParamSubstring extends CommonFilterProperties {
  type: FilterType.UTMParam;
  param?: UTMParamType;
  attributeId?: UID;
}

interface FilterEntity extends CommonFilterProperties {
  type: FilterType.EntityIdSet;
  setMatchType: SetMatchType;
}

interface FilterSelect extends CommonFilterProperties {
  type: FilterType.SelectSet;
  attributeId?: UID;
  selectId?: UID;
  setMatchType: SetMatchType;
}

interface FilterComparison extends CommonFilterProperties {
  type: FilterType.ValueComparison;
  attributeId?: UID;
  refValuePrimary: string;
  refValueSecondary: string;
  comparisonType?: ComparisonType;
}

interface FilterNone extends CommonFilterProperties {
  type: FilterType.None;
}

type Filter =
  | FilterAttributeValue
  | FilterAttributeSubstring
  | FilterEntity
  | FilterSelect
  | FilterComparison
  | FilterNone;

export {
  FilterType,
  SetMatchType,
  ComparisonType,
  RefValueType,
  BoolType,
  FilterEntityType,
};
export type {
  FilterAttributeValue,
  FilterEntity,
  FilterSelect,
  FilterComparison,
  FilterNone,
  Filter,
  UTMParamSubstring,
};
