import { Button, Menu, MenuButton, MenuItem } from "monday-ui-react-core";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { SignOut, User, UserCircle } from "@phosphor-icons/react";

const Account = ({ className }: { className?: string }) => {
  const navigate = useNavigate();
  const { user, signOut } = useAuth();

  return (
    <div className={`${className} flex items-center px-2`}>
      {user ? (
        <MenuButton component={() => <UserCircle size={24} />}>
          <Menu>
            <MenuItem
              icon={User}
              title="Manage account"
              onClick={() => navigate("/account")}
            />
            <MenuItem
              icon={SignOut}
              title="Sign out"
              onClick={() => signOut(true)}
            />
          </Menu>
        </MenuButton>
      ) : (
        <Button
          className="mx-2"
          kind={Button.kinds.SECONDARY}
          onClick={() => navigate("/login")}
        >
          Log in
        </Button>
      )}
    </div>
  );
};

export { Account };
