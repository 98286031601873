import { useApiInstance } from "../api/useApiInstance";
import { useSWRConfig } from "swr";
import { z } from "zod";
import { TimestampSchema } from "../types/zod";

const AgencySchema = z.object({
  id: z.number(),
  name: z.string(),
  joined_at: TimestampSchema
});

const BrandSchema = z.object({
  id: z.number(),
  name: z.string(),
  agency_name: z.string(),
  joined_at: TimestampSchema
});

const AccountSchema = z.object({
  id: z.union([z.string(), z.number()]),
  agency_managed: AgencySchema.nullable(),
  agencies: z.array(AgencySchema),
  brands: z.array(BrandSchema),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  email: z.string(),
  is_active: z.boolean(),
  roles: z.array(z.string())
});

type Account = z.infer<typeof AccountSchema>;
type Agency = z.infer<typeof AgencySchema>;
type Brand = z.infer<typeof BrandSchema>;

const useAccount = () => {

  const { mutate: globalMutate } = useSWRConfig();
  const { instance, useWrappedSWR } = useApiInstance();
  const { data, mutate, isLoading, error } = useWrappedSWR(
    `/account`
  );
  const account: Account | undefined = !isLoading && !error && data ? AccountSchema.parse(data) : undefined;

  const updateAccount = async (updateRequest: {
    first_name: string | null,
    last_name: string | null,
    email: string,
    is_active: boolean,
  }) => {
    await instance.put(`/account-edit`, {
      first_name: updateRequest.first_name,
      last_name: updateRequest.last_name,
      email: updateRequest.email,
      is_active: updateRequest.is_active
    });
    await mutate({
      ...data,
      first_name: updateRequest.first_name,
      last_name: updateRequest.last_name,
      email: updateRequest.email,
      is_active: updateRequest.is_active
    });
  };

  if (account) {
    account.roles = account.roles.filter((role: string) => role !== "ROLE_USER");
  }

  const isAgencyAdmin = () => {
    return account?.roles.includes("ROLE_AGENCY_ADMIN");
  };

  const isAgencyMember = () => {
    return account?.roles.includes("ROLE_AGENCY_MEMBER");
  };

  const isBrandMember = () => {
    return account?.roles.includes("ROLE_BRAND_MEMBER");
  };

  const roleLabel = (role: string) => {
    switch (role) {
      case "ROLE_AGENCY_ADMIN":
        return "Agency Admin";
      case "ROLE_AGENCY_MEMBER":
        return "Agency Member";
      case "ROLE_BRAND_MEMBER":
        return "Brand Member";
    }
  };

  const cancelAgencyMembership = async (updateRequest: {
    agency_id: number | string,
    member_id: number | string,
  }) => {
    await instance.delete(`/agency/${updateRequest.agency_id}/member/${updateRequest.member_id}`);
    await globalMutate(`/account`);
  };

  const cancelBrandMembership = async (updateRequest: {
    brand_id: number | string,
    member_id: number | string,
  }) => {
    await instance.delete(`/brand/${updateRequest.brand_id}/member/${updateRequest.member_id}`);
    await globalMutate(`/account`);
  };

  return {
    account,
    isLoading,
    roleLabel,
    isAgencyAdmin,
    isAgencyMember,
    isBrandMember,
    updateAccount,
    cancelAgencyMembership,
    cancelBrandMembership
  };
};

export { useAccount };
export type { Account, Agency, Brand };
