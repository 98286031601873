import { Text } from "monday-ui-react-core";
import { EmailCodeType, EmailCodeVerified } from "../../hooks/useEmailCode";

const BrandWelcome = ({ className, verified }: { className?: string, verified?: EmailCodeVerified }) => {

  if (verified?.code_type !== EmailCodeType.BRAND_INVITE) {
    return <></>;
  }

  return (
    <div className={`${className}`}>
      <Text className="text-center" ellipsis={false}>
        You have been invited to join the <strong>{verified?.brand?.name}</strong> brand
        for <strong>{verified?.agency.name}</strong>.
      </Text>
    </div>
  );
};

export { BrandWelcome };
