import { produce } from "immer";
import { UID } from "../../types/uid";
import {
  EventGroup,
  GeneralPayload,
  HandlerLookup,
  makeGeneralPayload,
} from "../eventUtil";
import { ProjectState } from "../projectState";
import { SelectToggle } from "../../types/select";
import { createAction } from "../userEventHandlers";
import { EditorPage } from "../../types/editorPage";
import { BoolType } from "../../types/filter";

enum SelectEventType {
  Create = "select/create",
  Rename = "select/rename",
  Delete = "select/delete",
  Toggle = "select/toggleColumn",
}

type SelectEvent = EventGroup<{
  [SelectEventType.Create]: {
    selectId: UID;
  };
  [SelectEventType.Rename]: {
    selectId: UID;
    newName: string;
  };
  [SelectEventType.Toggle]: {
    selectId: UID;
    target: SelectToggle;
    newState: boolean;
  };
  [SelectEventType.Delete]: {
    selectId: UID;
  };
}>;

const selectHandlers: HandlerLookup<SelectEvent> = {
  [SelectEventType.Create]: {
    generalizer: ({ payload }): GeneralPayload =>
      makeGeneralPayload({ payloadA: payload.selectId }),
    specifier: ({ payloadA }: GeneralPayload) =>
      createAction[SelectEventType.Create]({
        selectId: payloadA,
      }),
    reducer: (state: ProjectState, action) =>
      produce(state, (draftState: ProjectState) => {
        draftState.selects.definitions[action.payload.selectId] = {
          title: "",
          usesURL: false,
          usesUTM: false,
          rowDefinitions: {},
          rowOrdering: [],
        };
        draftState.selects.ordering.push(action.payload.selectId);
      }),
    validator: (state: ProjectState, action) => {
      return !(action.payload.selectId in state.selects.definitions);
    },
  },
  [SelectEventType.Rename]: {
    generalizer: ({ payload }): GeneralPayload =>
      makeGeneralPayload({
        payloadA: payload.selectId,
        payloadB: payload.newName,
      }),
    specifier: ({ payloadA, payloadB }: GeneralPayload) =>
      createAction[SelectEventType.Rename]({
        selectId: payloadA,
        newName: payloadB,
      }),
    reducer: (state: ProjectState, action) =>
      produce(state, (draftState: ProjectState) => {
        draftState.selects.definitions[action.payload.selectId].title =
          action.payload.newName;
      }),
    validator: (state: ProjectState, action) => {
      return action.payload.selectId in state.selects.definitions;
    },
  },
  [SelectEventType.Delete]: {
    generalizer: ({ payload }): GeneralPayload =>
      makeGeneralPayload({ payloadA: payload.selectId }),
    specifier: ({ payloadA }: GeneralPayload) =>
      createAction[SelectEventType.Delete]({
        selectId: payloadA,
      }),
    reducer: (state: ProjectState, action) =>
      produce(state, (draftState: ProjectState) => {
        const selectId: UID = action.payload.selectId;
        delete draftState.selects.definitions[selectId];
        draftState.selects.ordering = state.selects.ordering.filter(
          (selectId: UID) => selectId !== action.payload.selectId
        );

        for (const page in draftState.attributes.definitions) {
          for (const attributeId in draftState.attributes.definitions[
            page as EditorPage
          ]) {
            if (
              draftState.attributes.definitions[page as EditorPage][attributeId]
                .selectId === selectId
            ) {
              draftState.attributes.definitions[page as EditorPage][
                attributeId
              ].selectId = undefined;
            }
          }
        }
      }),
    validator: (state: ProjectState, action) => {
      return true;
    },
  },
  [SelectEventType.Toggle]: {
    generalizer: ({ payload }): GeneralPayload =>
      makeGeneralPayload({
        payloadA: payload.selectId,
        payloadB: payload.target,
        payloadC: payload.newState ? BoolType.True : BoolType.False,
      }),
    specifier: ({ payloadA, payloadB, payloadC }: GeneralPayload) =>
      createAction[SelectEventType.Toggle]({
        selectId: payloadA,
        target: payloadB as SelectToggle,
        newState: payloadC === BoolType.True,
      }),
    reducer: (state: ProjectState, action) =>
      produce(state, (draftState: ProjectState) => {
        switch (action.payload.target) {
          case SelectToggle.UTM:
            draftState.selects.definitions[action.payload.selectId].usesUTM =
              action.payload.newState;
            break;
          case SelectToggle.URL:
            draftState.selects.definitions[action.payload.selectId].usesURL =
              action.payload.newState;
            break;
        }
      }),
    validator: (state: ProjectState, action) => {
      return Object.values(SelectToggle).includes(action.payload.target);
    },
  },
};

export { selectHandlers, SelectEventType };

export type { SelectEvent };
