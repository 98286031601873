import produce from "immer";
import { EventGroup, HandlerLookup, makeGeneralPayload } from "../eventUtil";
import { createAction } from "../userEventHandlers";
import { UID } from "../../types/uid";

enum ExportFormatEventType {
  Create = "exportFormat/create",
  Delete = "exportFormat/delete",
  Rename = "exportFormat/rename",
  AssignColumnFormat = "exportFormat/assignColumnFormat",
}

type ExportFormatEvent = EventGroup<{
  [ExportFormatEventType.Create]: {
    formatId: UID;
    tabListId: UID;
  };
  [ExportFormatEventType.Delete]: {
    formatId: UID;
  };
  [ExportFormatEventType.Rename]: {
    formatId: UID;
    newName: string;
  };
}>;

const exportFormatHandlers: HandlerLookup<ExportFormatEvent> = {
  [ExportFormatEventType.Create]: {
    generalizer: ({ payload: { formatId, tabListId } }) =>
      makeGeneralPayload({
        payloadA: formatId,
        payloadB: tabListId,
      }),
    specifier: ({ payloadA, payloadB }) => {
      return createAction[ExportFormatEventType.Create]({
        formatId: payloadA,
        tabListId: payloadB,
      });
    },
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { formatId, tabListId } = action.payload;
        draftState.exportFormats.definitions[formatId] = {
          tabListId,
          name: "New export format",
        };
        draftState.exportFormats.ordering.push(formatId);
        draftState.sets[tabListId] = [];
      }),
    validator: (state, action) =>
      !(action.payload.tabListId in state.sets) &&
      !(action.payload.formatId in state.exportFormats.definitions),
  },
  [ExportFormatEventType.Delete]: {
    generalizer: ({ payload: { formatId } }) =>
      makeGeneralPayload({
        payloadA: formatId,
      }),
    specifier: ({ payloadA }) => {
      return createAction[ExportFormatEventType.Delete]({
        formatId: payloadA,
      });
    },
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { formatId } = action.payload;
        const { tabListId } = state.exportFormats.definitions[formatId];
        delete draftState.exportFormats.definitions[formatId];
        draftState.exportFormats.ordering = state.exportFormats.ordering.filter(
          (formatId: UID) => formatId !== action.payload.formatId
        );
        delete draftState.sets[tabListId];
      }),
    validator: (state, action) => {
      const { formatId } = action.payload;
      const { tabListId } = state.exportFormats.definitions[formatId];
      return (
        formatId in state.exportFormats.definitions && tabListId in state.sets
      );
    },
  },
  [ExportFormatEventType.Rename]: {
    generalizer: ({ payload: { formatId, newName } }) =>
      makeGeneralPayload({
        payloadA: formatId,
        payloadB: newName,
      }),
    specifier: ({ payloadA, payloadB }) => {
      return createAction[ExportFormatEventType.Rename]({
        formatId: payloadA,
        newName: payloadB,
      });
    },
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { formatId, newName } = action.payload;
        draftState.exportFormats.definitions[formatId].name = newName;
      }),
    validator: (state, action) => {
      const { formatId } = action.payload;
      return formatId in state.exportFormats.definitions;
    },
  },
};

export { exportFormatHandlers, ExportFormatEventType };
export type { ExportFormatEvent };
