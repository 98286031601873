import axios from "axios";
import { BASE_URL } from "./useApiInstance";
import { z } from "zod";

enum LoginFailureReason {
  InvalidCredentials,
  InvalidRequest,
  InvalidResponse,
}

type LoginFailure = {
  successful: false;
  failureReason: LoginFailureReason;
};

const CheckLoginSchema = z.object({
  result: z.string().email(),
});

type LoginSuccessData = z.infer<typeof CheckLoginSchema>;

interface LoginSuccess {
  successful: true;
  data: LoginSuccessData;
}

type LoginResponse = LoginSuccess | LoginFailure;

const formatLoginFail: (reason: LoginFailureReason) => LoginFailure = (
  reason
) => ({
  successful: false,
  failureReason: reason,
});

const checkLogin: () => Promise<LoginResponse> = async () => {
  try {
    const loginSuccessData: LoginSuccessData = await axios
      .get(BASE_URL, { withCredentials: true })
      .then((res) => res.data)
      .then((data) => CheckLoginSchema.parse(data));
    return { successful: true, data: loginSuccessData };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return formatLoginFail(LoginFailureReason.InvalidRequest);
  }
};

const loginRequest: (
  userEmail: string,
  userPassword: string
) => Promise<LoginResponse> = async (userEmail, userPassword) => {
  try {
    await axios.post(
      `${BASE_URL}/login_check`,
      {
        username: userEmail,
        password: userPassword,
      },
      { withCredentials: true }
    );

    const loginSuccess = await checkLogin();

    return loginSuccess;
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    switch (error.response?.status) {
      case 401:
        return formatLoginFail(LoginFailureReason.InvalidCredentials);
      case 400:
        return formatLoginFail(LoginFailureReason.InvalidRequest);
      default:
        throw error;
    }
  }
};

const logoutRequest: () => Promise<void> = async () => {
  try {
    await axios.get(`${BASE_URL}/logout`, {
      withCredentials: true,
    });
  } catch (error) {
    console.error(error);
  }
};

export { loginRequest, logoutRequest, checkLogin, LoginFailureReason };

export type { LoginResponse, LoginSuccess, LoginFailure };
