import { useProjectDispatch, useProjectSelector } from "../../hooks/useEditor";
import { Select, SelectRow, SelectToggle } from "../../types/select";
import { UID, generateUid } from "../../types/uid";
import {
  SelectFieldAttribute,
  SelectFieldEventType,
} from "../handlers/handleSelectField";
import { SelectEventType } from "../handlers/handleSelects";
import { ProjectState } from "../projectState";
import { createAction } from "../userEventHandlers";

const useSelectRow = (selectId: UID, rowId: UID) => {
  const projectDispatch = useProjectDispatch();

  const name = useProjectSelector(
    (project) =>
      project.selects.definitions[selectId].rowDefinitions[rowId].name
  );
  const utmTerm = useProjectSelector(
    (project) =>
      project.selects.definitions[selectId].rowDefinitions[rowId].utmTerm
  );
  const urlTerm = useProjectSelector(
    (project) =>
      project.selects.definitions[selectId].rowDefinitions[rowId].urlTerm
  );

  const editUrl = (value: string) =>
    projectDispatch(
      createAction[SelectFieldEventType.AssignField]({
        selectId,
        rowId,
        attribute: SelectFieldAttribute.URLTerm,
        value,
      })
    );

  const editUtm = (value: string) =>
    projectDispatch(
      createAction[SelectFieldEventType.AssignField]({
        selectId,
        rowId,
        attribute: SelectFieldAttribute.UTMTerm,
        value,
      })
    );

  const editName = (value: string) =>
    projectDispatch(
      createAction[SelectFieldEventType.AssignField]({
        selectId,
        rowId,
        attribute: SelectFieldAttribute.Name,
        value,
      })
    );

  const remove = () =>
    projectDispatch(
      createAction[SelectFieldEventType.DeleteRow]({
        selectId,
        rowId,
      })
    );

  return { name, utmTerm, urlTerm, editUrl, editUtm, editName, remove };
};

const useSelectOptions = (selectId?: UID) => {
  const selectRowIds = useProjectSelector((project: ProjectState) =>
    selectId ? project.selects.definitions[selectId]?.rowOrdering : undefined
  );

  const selectRowDefinitions = useProjectSelector((project: ProjectState) =>
    selectId ? project.selects.definitions[selectId]?.rowDefinitions : undefined
  );

  const selectRows: { rowId: UID; row: SelectRow }[] | undefined =
    selectRowIds && selectRowDefinitions
      ? selectRowIds.map((rowId: UID) => ({
          rowId: rowId,
          row: selectRowDefinitions[rowId],
        }))
      : undefined;

  return { selectRows };
};

const useSelect = (selectId: UID) => {
  const projectDispatch = useProjectDispatch();

  const title = useProjectSelector(
    (project) => project.selects.definitions[selectId]?.title
  );

  const usesUTM = useProjectSelector(
    (project) => project.selects.definitions[selectId]?.usesUTM
  );

  const usesURL = useProjectSelector(
    (project) => project.selects.definitions[selectId]?.usesURL
  );

  const rows = useProjectSelector(
    (project) => project.selects.definitions[selectId]?.rowOrdering
  );

  const addRow = () =>
    projectDispatch(
      createAction[SelectFieldEventType.CreateRow]({
        selectId,
        rowId: generateUid(),
      })
    );

  const rename = (newName: string) => {
    projectDispatch(
      createAction[SelectEventType.Rename]({ selectId, newName })
    );
  };

  const remove = () => {
    projectDispatch(createAction[SelectEventType.Delete]({ selectId }));
  };

  const toggleUTM = (newState: boolean) => {
    projectDispatch(
      createAction[SelectEventType.Toggle]({
        selectId,
        newState,
        target: SelectToggle.UTM,
      })
    );
  };

  const toggleURL = (newState: boolean) => {
    projectDispatch(
      createAction[SelectEventType.Toggle]({
        selectId,
        newState,
        target: SelectToggle.URL,
      })
    );
  };

  return {
    title,
    rows,
    usesUTM,
    usesURL,
    rename,
    remove,
    toggleUTM,
    toggleURL,
    addRow,
  };
};

const useSelects = () => {
  const projectDispatch = useProjectDispatch();

  const selectIds: UID[] = useProjectSelector(
    (project: ProjectState) => project.selects.ordering
  );

  const selectDefinitions = useProjectSelector(
    (project: ProjectState) => project.selects.definitions
  );

  const selects: { selectId: UID; select: Select }[] = selectIds.map(
    (selectId: UID) => ({ selectId, select: selectDefinitions[selectId] })
  );

  const create = () => {
    projectDispatch(
      createAction[SelectEventType.Create]({ selectId: generateUid() })
    );
  };

  return { selectIds, selectDefinitions, selects, create };
};

export { useSelect, useSelects, useSelectRow, useSelectOptions };
