import produce from "immer";
import {
  Filter,
  FilterType,
  SetMatchType,
  ComparisonType,
  RefValueType,
  FilterEntityType,
} from "../../types/filter";
import { UID } from "../../types/uid";
import { EventGroup, HandlerLookup, makeGeneralPayload } from "../eventUtil";
import { createAction } from "../userEventHandlers";
import { AttributeType } from "../../types/attribute";
import { RecordFilterType } from "../hooks/useRecordFilter";

enum FilterEventType {
  ChangeRecordFilterType = "filter/changeType",
  SelectAttributeId = "filter/selectAttributeId",
  SelectComparisonType = "filter/selectComparisonType",
  ChangeRefValue = "filter/changeRefValue",
  SelectSetMatchType = "filter/selectSetMatchType",
  SetSubstringValue = "filter/setSubstringValue",
}

type FilterEvent = EventGroup<{
  [FilterEventType.ChangeRecordFilterType]: {
    filterId: UID;
    newType: string;
  };
  [FilterEventType.ChangeRefValue]: {
    filterId: UID;
    refValueType: RefValueType;
    newValue: string;
  };
  [FilterEventType.SelectAttributeId]: {
    filterId: UID;
    attributeId?: UID;
  };
  [FilterEventType.SelectComparisonType]: {
    filterId: UID;
    comparisonType: ComparisonType;
  };
  [FilterEventType.SelectSetMatchType]: {
    filterId: UID;
    setMatchType: SetMatchType;
  };
  [FilterEventType.SetSubstringValue]: {
    filterId: UID;
    substringValue: string;
  };
}>;

const getDateStringToday = () => {
  const today = new Date();
  const formatted = `${today.getFullYear()}-${String(
    today.getMonth() + 1,
  ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;
  return formatted; // yyyy-MM-dd
};

const getDefaultFilterForAttribute = (
  attributeType: AttributeType,
  entrySetId: UID,
  attributeId?: UID,
): Filter => {
  switch (attributeType) {
    case AttributeType.Text:
      return {
        type: FilterType.AttributeValueSubstring,
        entityType: FilterEntityType.Offer,
        userFriendlyType: RecordFilterType.Attribute,
        attributeId,
        entrySetId,
        substring: "",
      };
    case AttributeType.Number:
    case AttributeType.Currency:
      return {
        type: FilterType.ValueComparison,
        entityType: FilterEntityType.Offer,
        userFriendlyType: RecordFilterType.Attribute,
        entrySetId,
        attributeId,
        refValuePrimary: "",
        refValueSecondary: "",
        comparisonType: ComparisonType.GreaterThan,
      };
    case AttributeType.Date:
      return {
        type: FilterType.ValueComparison,
        entityType: FilterEntityType.Offer,
        userFriendlyType: RecordFilterType.Attribute,
        entrySetId,
        attributeId,
        refValuePrimary: getDateStringToday(),
        refValueSecondary: getDateStringToday(),
        comparisonType: ComparisonType.GreaterThan,
      };
    case AttributeType.Checkbox:
      return {
        type: FilterType.TrueFalseSet,
        entityType: FilterEntityType.Offer,
        userFriendlyType: RecordFilterType.Attribute,
        entrySetId,
        attributeId,
      };
    case AttributeType.MultiSelect:
    case AttributeType.SingleSelect:
      return {
        type: FilterType.SelectSet,
        entityType: FilterEntityType.Offer,
        userFriendlyType: RecordFilterType.Attribute,
        entrySetId,
        attributeId,
        selectId: undefined,
        setMatchType: SetMatchType.Inclusion,
      };
  }
};

const getDefaultFilterForRecordFilter = (
  filterType: RecordFilterType,
  entrySetId: UID,
): Filter => {
  switch (filterType) {
    case RecordFilterType.OfferName:
    case RecordFilterType.SourceMediumName:
    case RecordFilterType.TargetName:
      const entityType = {
        [RecordFilterType.OfferName]: FilterEntityType.Offer,
        [RecordFilterType.SourceMediumName]: FilterEntityType.Touchpoint,
        [RecordFilterType.TargetName]: FilterEntityType.Target,
      }[filterType];
      return {
        type: FilterType.EntityIdSet,
        entityType,
        userFriendlyType: filterType,
        entrySetId,
        setMatchType: SetMatchType.Inclusion,
      };
    case RecordFilterType.UTMParam:
      return {
        type: FilterType.None,
        entityType: FilterEntityType.Offer,
        userFriendlyType: filterType,
        entrySetId,
      };
    case RecordFilterType.Attribute:
      return getDefaultFilterForAttribute(AttributeType.Text, entrySetId);
    case RecordFilterType.None:
      return {
        type: FilterType.None,
        entityType: FilterEntityType.Offer,
        userFriendlyType: filterType,
        entrySetId,
      };
  }
};

const filterHandlers: HandlerLookup<FilterEvent> = {
  [FilterEventType.ChangeRecordFilterType]: {
    generalizer: ({ payload: { filterId, newType } }) =>
      makeGeneralPayload({
        payloadA: filterId,
        payloadB: newType,
      }),
    specifier: ({ payloadA, payloadB }) =>
      createAction[FilterEventType.ChangeRecordFilterType]({
        filterId: payloadA,
        newType: payloadB,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { filterId, newType } = action.payload;
        draftState.filters[filterId] = getDefaultFilterForRecordFilter(
          newType as RecordFilterType,
          state.filters[filterId].entrySetId,
        );
        const entrySetId = state.filters[filterId].entrySetId;
        draftState.sets[entrySetId] = [];
      }),
    validator: (state, action) => action.payload.filterId in state.filters,
  },
  [FilterEventType.SelectAttributeId]: {
    generalizer: ({ payload: { filterId, attributeId } }) =>
      makeGeneralPayload({
        payloadA: filterId,
        payloadB: attributeId,
      }),
    specifier: ({ payloadA, payloadB }) =>
      createAction[FilterEventType.SelectAttributeId]({
        filterId: payloadA,
        attributeId: payloadB,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { filterId, attributeId } = action.payload;

        const entrySetId = state.filters[filterId].entrySetId;
        draftState.sets[entrySetId] = [];

        switch (state.filters[filterId].type) {
          case FilterType.TrueFalseSet:
          case FilterType.SelectSet:
          case FilterType.ValueComparison:
          case FilterType.AttributeValueSubstring:
            const attributeType = (() => {
              if (!attributeId) return undefined;
              const definitions = state.attributes.definitions;
              if (attributeId in definitions.offers) {
                return definitions.offers[attributeId].type;
              }
              return definitions.touchpoints[attributeId]?.type;
            })();
            const attributeDefaultFilter = getDefaultFilterForAttribute(
              attributeType || AttributeType.Text,
              state.filters[filterId].entrySetId,
              attributeId,
            );
            draftState.filters[filterId] = attributeDefaultFilter;
        }
      }),
    validator: (state, action) =>
      action.payload.filterId in state.filters &&
      (action.payload.attributeId
        ? action.payload.attributeId in state.attributes.definitions.offers ||
          action.payload.attributeId in state.attributes.definitions.touchpoints
        : true),
  },
  [FilterEventType.SelectComparisonType]: {
    generalizer: ({ payload: { filterId, comparisonType } }) =>
      makeGeneralPayload({
        payloadA: filterId,
        payloadB: comparisonType,
      }),
    specifier: ({ payloadA, payloadB }) =>
      createAction[FilterEventType.SelectComparisonType]({
        filterId: payloadA,
        comparisonType: payloadB as ComparisonType,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { filterId, comparisonType } = action.payload;
        const filter = state.filters[filterId];
        const newFilter: Filter =
          filter.type === FilterType.ValueComparison
            ? {
                ...filter,
                comparisonType,
              }
            : filter;
        draftState.filters[filterId] = newFilter;
      }),
    validator: (state, action) => action.payload.filterId in state.filters,
  },
  [FilterEventType.ChangeRefValue]: {
    generalizer: ({ payload: { filterId, newValue, refValueType } }) =>
      makeGeneralPayload({
        payloadA: filterId,
        payloadB: refValueType,
        payloadC: newValue,
      }),
    specifier: ({ payloadA, payloadB, payloadC }) =>
      createAction[FilterEventType.ChangeRefValue]({
        filterId: payloadA,
        refValueType: payloadB as RefValueType,
        newValue: payloadC,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { filterId, refValueType, newValue } = action.payload;
        const filter = state.filters[filterId];
        const newFilter: Filter =
          filter.type === FilterType.ValueComparison
            ? {
                ...filter,
                ...(refValueType === RefValueType.Primary
                  ? { refValuePrimary: newValue || "" }
                  : { refValueSecondary: newValue || "" }),
              }
            : filter;
        draftState.filters[filterId] = newFilter;
      }),
    validator: (state, action) => action.payload.filterId in state.filters,
  },
  [FilterEventType.SelectSetMatchType]: {
    generalizer: ({ payload: { filterId, setMatchType } }) =>
      makeGeneralPayload({
        payloadA: filterId,
        payloadB: setMatchType,
      }),
    specifier: ({ payloadA, payloadB }) =>
      createAction[FilterEventType.SelectSetMatchType]({
        filterId: payloadA,
        setMatchType: payloadB as SetMatchType,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { filterId, setMatchType } = action.payload;
        const filter = state.filters[filterId];
        if (
          filter.type === FilterType.SelectSet ||
          filter.type === FilterType.EntityIdSet
        ) {
          const newFilter: Filter = {
            ...filter,
            setMatchType,
          };
          draftState.filters[filterId] = newFilter;
        }
      }),
    validator: (state, action) => action.payload.filterId in state.filters,
  },
  [FilterEventType.SetSubstringValue]: {
    generalizer: ({ payload: { filterId, substringValue } }) =>
      makeGeneralPayload({
        payloadA: filterId,
        payloadB: substringValue,
      }),
    specifier: ({ payloadA, payloadB }) =>
      createAction[FilterEventType.SetSubstringValue]({
        filterId: payloadA,
        substringValue: payloadB,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { filterId, substringValue } = action.payload;
        const filter = state.filters[filterId];
        if (filter.type === FilterType.AttributeValueSubstring) {
          const newFilter: Filter = {
            ...filter,
            substring: substringValue,
          };
          draftState.filters[filterId] = newFilter;
        }
      }),
    validator: (state, action) => action.payload.filterId in state.filters,
  },
};

export { filterHandlers, FilterEventType };
export type { FilterEvent };
