import { z } from "zod";

enum EditorPage {
  Offers = "offers",
  Touchpoints = "touchpoints",
}

const EditorPageSchema = z.nativeEnum(EditorPage);

export { EditorPage, EditorPageSchema };
