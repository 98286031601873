import { Tooltip } from "monday-ui-react-core";
import { Icon } from "@phosphor-icons/react";

const Toggle = ({
  className,
  title,
  icon,
  tooltip,
  isSelected,
  onToggle,
}: {
  className?: string;
  title?: string;
  icon: Icon;
  tooltip?: string;
  isSelected: boolean;
  onToggle: (isSelected: boolean) => void;
}) => {
  const Icon = icon;
  return (
    <Tooltip
      children={
        <button
          className={`${className} flex h-8 items-center text-xs gap-0.5 p-1
         border border-gray-300 hover:border-black  rounded ${
           isSelected ? "bg-blue-200" : "hover:bg-slate-100"
         }`}
          onClick={() => {
            onToggle(!isSelected);
          }}
        >
          <Icon size={20} />
          {title}
        </button>
      }
      content={tooltip}
    />
  );
};

export { Toggle };
