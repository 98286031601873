import { SaveStatus } from "../editor/common/SaveStatus";
import { Account } from "./Account";
import { Logo } from "./Logo";
import { ReactElement } from "react";

const Nav = ({
  className,
  fullySpreadsControls,
  projectName,
}: {
  className?: string;
  fullySpreadsControls?: boolean;
  projectName?: ReactElement;
}) => {
  return (
    <nav
      className={`${className} flex p-4 h-14 justify-center bg-slate-50 shadow border-b`}
    >
      <div
        className={`flex w-full ${
          fullySpreadsControls ? "" : "max-w-7xl"
        } justify-between`}
      >
        <Logo />
        {projectName ? (
          <div className="flex w-full">
            {projectName}
            <SaveStatus />
          </div>
        ) : (
          <></>
        )}
        <Account />
      </div>
    </nav>
  );
};

export { Nav };
