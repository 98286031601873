import { UID } from "./uid";

enum UTMParamType {
  Source = "source",
  Medium = "medium",
  Term = "term",
  Content = "content",
  Id = "id",
  Campaign = "campaign",
}

const getFriendlyName = (param: UTMParamType): string => {
  switch (param) {
    case UTMParamType.Source:
      return "Source";
    case UTMParamType.Medium:
      return "Medium";
    case UTMParamType.Term:
      return "Term";
    case UTMParamType.Content:
      return "Content";
    case UTMParamType.Id:
      return "Id";
    case UTMParamType.Campaign:
      return "Campaign name";
  }
};

const getUTMCode = (param: UTMParamType): string => {
  switch (param) {
    case UTMParamType.Source:
      return "utm_source";
    case UTMParamType.Medium:
      return "utm_medium";
    case UTMParamType.Term:
      return "utm_term";
    case UTMParamType.Content:
      return "utm_content";
    case UTMParamType.Id:
      return "utm_id";
    case UTMParamType.Campaign:
      return "utm_campaign";
  }
};

type UTM = {
  isUsed: boolean;
  formulaId?: UID;
};

export type { UTM };
export { UTMParamType, getFriendlyName, getUTMCode };
