import { produce } from "immer";
import { EventGroup, GeneralPayload, HandlerLookup, makeGeneralPayload } from "../eventUtil";
import { ProjectState } from "../projectState";
import { createAction } from "../userEventHandlers";

enum CounterEventType {
  Increment = "counter/increment",
  Decrement = "counter/decrement",
}

type CounterEvent = EventGroup<{
  [CounterEventType.Increment]: { counterId: number };
  [CounterEventType.Decrement]: { counterId: number };
}>;

const counterHandlers: HandlerLookup<CounterEvent> = {
  [CounterEventType.Increment]: {
    generalizer: ({ payload }): GeneralPayload =>
      makeGeneralPayload({
        payloadA: payload.counterId.toString(),
      }),
    specifier: ({ payloadA }: GeneralPayload) =>
      createAction[CounterEventType.Increment]({
        counterId: parseInt(payloadA),
      }),
    reducer: (state: ProjectState, action: CounterEvent) =>
      produce(state, (draftState) => {
        draftState.counters[action.payload.counterId].value += 1;
      }),
    validator: () => true,
  },
  [CounterEventType.Decrement]: {
    generalizer: ({ payload }): GeneralPayload =>
      makeGeneralPayload({
        payloadA: payload.counterId.toString(),
      }),
    specifier: ({ payloadA }: GeneralPayload) =>
      createAction[CounterEventType.Decrement]({
        counterId: parseInt(payloadA),
      }),
    reducer: (state: ProjectState, action: CounterEvent) =>
      produce(state, (draftState) => {
        draftState.counters[action.payload.counterId].value -= 1;
      }),
    validator: () => true,
  },
};

export { counterHandlers, CounterEventType };
export type { CounterEvent };
