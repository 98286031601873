import { useProjectDispatch, useProjectSelector } from "../../hooks/useEditor";
import { Attribute, AttributeType } from "../../types/attribute";
import { EditorPage } from "../../types/editorPage";
import { UID, generateUid } from "../../types/uid";
import { DropdownItem } from "../attributes/Dropdown";
import { AttributeEventType } from "../handlers/handleAttributes";
import { ProjectState } from "../projectState";
import { createAction } from "../userEventHandlers";

const useAttribute = (page: EditorPage, attributeId: UID) => {
  const projectDispatch = useProjectDispatch();

  const name = useProjectSelector(
    (project) => project.attributes.definitions[page][attributeId]?.name
  );

  const type = useProjectSelector(
    (project) => project.attributes.definitions[page][attributeId]?.type
  );

  const selectId = useProjectSelector(
    (project) => project.attributes.definitions[page][attributeId]?.selectId
  );

  const rename = (newName: string) => {
    projectDispatch(
      createAction[AttributeEventType.Rename]({
        attributeId,
        page,
        newName,
      })
    );
  };

  const remove = () => {
    projectDispatch(
      createAction[AttributeEventType.Delete]({ attributeId, page })
    );
  };

  const chooseSelect = (selectId?: UID) => {
    projectDispatch(
      createAction[AttributeEventType.ChooseSelect]({
        attributeId,
        page,
        selectId,
      })
    );
  };

  return { name, type, selectId, rename, remove, chooseSelect };
};

const useAttributes = (page: EditorPage) => {
  const projectDispatch = useProjectDispatch();

  const attributeIds: UID[] = useProjectSelector(
    (project: ProjectState) => project.attributes.orderings[page]
  );

  const definitions: { [attributeId: UID]: Attribute } = useProjectSelector(
    (project: ProjectState) => project.attributes.definitions[page]
  );

  const create = (type: AttributeType) => {
    projectDispatch(
      createAction[AttributeEventType.Create]({
        attributeId: generateUid(),
        page,
        type,
      })
    );
  };

  const attributeOptions: DropdownItem[] = attributeIds.map((id) => ({
    id,
    label: definitions[id].name,
  }));

  return { attributeIds, definitions, attributeOptions, create };
};

export { useAttribute, useAttributes };
