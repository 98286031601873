import { UID } from "./uid";

enum FormulaBlockType {
  Attribute = "attribute",
  Text = "text",
}

enum SpreadMethodType {
  ForEach = "forEach",
  Concatenate = "concatenate",
}

type FormulaBlock = {
  type: FormulaBlockType;
  value: string | UID;
  spreadMethod: SpreadMethodType;
  separator: string;
};

type Formula = {
  blockDefinitions: {
    [blockId: UID]: FormulaBlock;
  };
  blockOrdering: UID[];
};

export { FormulaBlockType, SpreadMethodType };
export type { Formula, FormulaBlock };
