import { UtilityEventType } from "./handlers/utility";
import { ProjectState } from "./projectState";
import { UserEvent, handlers } from "./userEventHandlers";

type EventID = string;

type EventTemplate = {
  eventType: string;
  payload: object;
};

type GeneralPayload = {
  payloadA: string;
  payloadB: string;
  payloadC: string;
};

interface GeneralEvent extends EventTemplate {
  eventType: string;
  payload: GeneralPayload;
}

interface EventHandler<CustomEvent> {
  generalizer: (customPayload: CustomEvent) => GeneralPayload;
  specifier: (generalPayload: GeneralPayload) => CustomEvent;
  reducer: (state: ProjectState, action: CustomEvent) => ProjectState;
  validator: (state: ProjectState, action: CustomEvent) => boolean;
}

type HandlerLookup<CustomEvent extends EventTemplate> = {
  [K in CustomEvent extends { eventType: infer K } ? K : never]: EventHandler<
    Extract<CustomEvent, { eventType: K }>
  >;
};

type ActionCreator<CustomEvent extends EventTemplate> = (
  payload: CustomEvent["payload"]
) => CustomEvent;

type ActionCreatorLookup<CustomEvent extends EventTemplate> = {
  [K in CustomEvent extends { eventType: infer K } ? K : never]: ActionCreator<
    Extract<CustomEvent, { eventType: K }>
  >;
};

type EventGroup<Payloads> = {
  [K in keyof Payloads]: EventTemplate & {
    eventType: K;
    payload: Payloads[K];
  };
}[keyof Payloads];

type UserEventType = UserEvent["eventType"];

const sanitizeEventType = (rawEventType: string): UserEventType => {
  return (
    handlers[rawEventType as UserEventType]
      ? rawEventType
      : UtilityEventType.Unknown
  ) as UserEventType;
};

const generalizeEvent = (event: UserEvent): GeneralEvent => {
  const handler = handlers[sanitizeEventType(event.eventType)];
  return {
    eventType: event.eventType,
    payload: handler.generalizer(event as any),
  };
};

const validateEvent = (state: ProjectState, event: UserEvent): boolean => {
  const handler = handlers[sanitizeEventType(event.eventType)];
  return handler.validator(state, event as any);
};

const specifyEvent = (event: GeneralEvent): UserEvent => {
  const handler = handlers[sanitizeEventType(event.eventType)];
  return handler.specifier(event.payload);
};

const reduceEvent = (state: ProjectState, event: UserEvent): ProjectState => {
  const handler = handlers[sanitizeEventType(event.eventType)];
  return handler.reducer(state, event as any);
};

const eventsAreEqual = (eventA: GeneralEvent, eventB: GeneralEvent) => {
  return (
    eventA.eventType === eventB.eventType &&
    eventA.payload.payloadA === eventB.payload.payloadA &&
    eventA.payload.payloadB === eventB.payload.payloadB &&
    eventA.payload.payloadC === eventB.payload.payloadC
  );
};

const makeGeneralPayload = ({
  payloadA,
  payloadB,
  payloadC,
}: {
  payloadA?: string;
  payloadB?: string;
  payloadC?: string;
}): GeneralPayload => ({
  payloadA: payloadA || "",
  payloadB: payloadB || "",
  payloadC: payloadC || "",
});

export type {
  UserEvent,
  EventGroup,
  EventTemplate,
  EventID,
  GeneralEvent,
  GeneralPayload,
  HandlerLookup,
  UserEventType,
  ActionCreatorLookup,
};

export {
  generalizeEvent,
  validateEvent,
  specifyEvent,
  reduceEvent,
  eventsAreEqual,
  makeGeneralPayload,
};
