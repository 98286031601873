import { GeneralEvent, reduceEvent, specifyEvent } from "./eventUtil";
import { UID } from "../types/uid";
import { Attribute } from "../types/attribute";
import { EditorPage } from "../types/editorPage";
import { Select } from "../types/select";
import { Offer } from "../types/offer";
import { Source, Medium } from "../types/touchpoints";
import { Target } from "../types/target";
import { Formula } from "../types/formula";
import { Filter } from "../types/filter";
import { UTM, UTMParamType } from "../types/utm";
import { ColumnFormat } from "../types/columnFormat";

type Counter = {
  name: string;
  value: number;
};

type ExportFormat = {
  tabListId: UID;
  name: string;
};

type ExportTab = {
  name: string;
  filterUnionId: UID;
  columnFormatId?: UID;
};

type ProjectState = {
  counters: { [counterId: number]: Counter };
  message: string;
  attributes: {
    definitions: { [page in EditorPage]: { [attributeId: UID]: Attribute } };
    orderings: { [page in EditorPage]: UID[] };
  };
  selects: {
    definitions: { [selectId: UID]: Select };
    ordering: UID[];
  };
  offers: {
    definitions: { [offerId: UID]: Offer };
    ordering: UID[];
  };
  touchpoints: {
    sourceDefinitions: { [sourceId: UID]: Source };
    mediumDefinitions: { [mediumId: UID]: Medium };
    sourceOrdering: UID[];
  };
  targets: {
    definitions: { [targetId: UID]: Target };
    ordering: UID[];
  };
  columnFormats: {
    definitions: { [columnFormatId: UID]: ColumnFormat };
    ordering: UID[];
  };
  exportFormats: {
    definitions: { [exportFormatId: UID]: ExportFormat };
    ordering: UID[];
  };
  utmParameters: {
    [param in UTMParamType]: UTM;
  };
  formulas: {
    [formulaId: UID]: Formula;
  };
  exportTabs: {
    [exportTabId: UID]: ExportTab;
  };
  sets: {
    [setId: UID]: string[];
  };
  filters: { [filterId: UID]: Filter };
};

const initialProjectState: ProjectState = {
  counters: {
    0: { name: "Counter A", value: 0 },
    1: { name: "Counter B", value: 0 },
  },
  message: "",
  attributes: {
    definitions: {
      [EditorPage.Offers]: {},
      [EditorPage.Touchpoints]: {},
    },
    orderings: {
      [EditorPage.Offers]: [],
      [EditorPage.Touchpoints]: [],
    },
  },
  selects: {
    definitions: {},
    ordering: [],
  },
  offers: {
    definitions: {},
    ordering: [],
  },
  touchpoints: {
    sourceDefinitions: {},
    mediumDefinitions: {},
    sourceOrdering: [],
  },
  targets: {
    definitions: {},
    ordering: [],
  },
  columnFormats: {
    definitions: {},
    ordering: [],
  },
  exportFormats: {
    definitions: {},
    ordering: [],
  },
  utmParameters: {
    [UTMParamType.Term]: { isUsed: true, formulaId: UTMParamType.Term },
    [UTMParamType.Content]: { isUsed: true, formulaId: UTMParamType.Content },
    [UTMParamType.Campaign]: { isUsed: true, formulaId: UTMParamType.Campaign },
    [UTMParamType.Id]: { isUsed: true, formulaId: UTMParamType.Id },
    [UTMParamType.Source]: { isUsed: true },
    [UTMParamType.Medium]: { isUsed: true },
  },
  formulas: {
    [UTMParamType.Term]: { blockDefinitions: {}, blockOrdering: [] },
    [UTMParamType.Content]: { blockDefinitions: {}, blockOrdering: [] },
    [UTMParamType.Campaign]: { blockDefinitions: {}, blockOrdering: [] },
    [UTMParamType.Id]: { blockDefinitions: {}, blockOrdering: [] },
  },
  exportTabs: {},
  sets: {},
  filters: {},
};

const buildState = (
  startState: ProjectState,
  events: GeneralEvent[],
): ProjectState =>
  events.reduce(
    (state: ProjectState, event: GeneralEvent) =>
      reduceEvent(state, specifyEvent(event)),
    startState,
  );

export type { Counter, ProjectState };
export { initialProjectState, buildState };
