import { useState } from "react";
import { useCookies } from "react-cookie";
import { ProjectView } from "../editor/views/ProjectNavigator";

const DEBOUNCE_RATE = 800;

const usePersistentState = () => {
  const [cookies, setCookie] = useCookies([
    "selectedProjectView",
    "navigatorIsMinimized",
    "attributeBuilderIsMinimized",
  ]);

  const [selectedProjectView, setSelectedProjectViewState] =
    useState<ProjectView>(cookies.selectedProjectView || ProjectView.Selects);
  const [navigatorIsMinimized, setNavigatorIsMinimizedState] =
    useState<boolean>(cookies.navigatorIsMinimized || false);
  const [attributeBuilderIsMinimized, setAttributeBuilderIsMinimizedState] =
    useState<boolean>(cookies.attributeBuilderIsMinimized || false);

  let timer: NodeJS.Timeout;

  const debouncedSetCookie = (
    key:
      | "selectedProjectView"
      | "navigatorIsMinimized"
      | "attributeBuilderIsMinimized",
    value: any
  ) => {
    clearTimeout(timer);
    timer = setTimeout(() => setCookie(key, value), DEBOUNCE_RATE);
  };

  const setSelectedProjectView = (value: ProjectView) => {
    setSelectedProjectViewState(value);
    debouncedSetCookie("selectedProjectView", value);
  };

  const setNavigatorIsMinimized = (value: boolean) => {
    setNavigatorIsMinimizedState(value);
    debouncedSetCookie("navigatorIsMinimized", value);
  };

  const setAttributeBuilderIsMinimized = (value: boolean) => {
    setAttributeBuilderIsMinimizedState(value);
    debouncedSetCookie("attributeBuilderIsMinimized", value);
  };

  return {
    selectedProjectView,
    navigatorIsMinimized,
    attributeBuilderIsMinimized,
    setSelectedProjectView,
    setNavigatorIsMinimized,
    setAttributeBuilderIsMinimized,
  };
};

export { usePersistentState };
