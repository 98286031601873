import { utils, writeFileXLSX } from "xlsx";

type ColumnExport = {
  headers: { key: string; label: string }[];
  rows: { [key: string]: string }[];
};

const objectToCsv = (data: ColumnExport, sortRows: boolean): string => {
  const headers = data.headers.map((header) => header.label);

  const dataRows = data.rows.map((row) =>
    data.headers
      .map(({ key }) => `"${row[key].replace(/"/g, '\\"')}"`)
      .join(",")
  );

  if (sortRows) {
    dataRows.sort();
  }

  return [headers.join(","), ...dataRows].join("\n");
};

const downloadCSV = (
  filename: string,
  data: ColumnExport,
  sortRows: boolean
): void => {
  const blob = new Blob(["\uFEFF" + objectToCsv(data, sortRows)], {
    type: "text/csv;charset=utf-8;",
  });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("hidden", "");
  a.setAttribute("href", url);
  a.setAttribute("download", `${filename}.csv`);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const colExportToAOA = (data: ColumnExport, sortRows: boolean): string[][] => {
  const { headers, rows } = data;

  const headersArray = headers.map((header) => header.label);

  const dataRows = rows.map((row) =>
    headers.map(({ key }) => (key in row ? row[key] || "" : ""))
  );

  if (sortRows) {
    dataRows.sort((a, b) => {
      const rowA = a.join(",");
      const rowB = b.join(",");
      return rowA.localeCompare(rowB);
    });
  }

  return [headersArray, ...dataRows];
};

const downloadExcelSheet = (
  filename: string,
  data: ColumnExport,
  sortRows: boolean
): void => {
  const wb = utils.book_new();
  const dataAoa = colExportToAOA(data, sortRows);
  const ws = utils.aoa_to_sheet(dataAoa);
  utils.book_append_sheet(wb, ws, filename);
  writeFileXLSX(wb, `${filename}.xlsx`);
};

const downloadExcelWorkbook = (
  filename: string,
  data: { name: string; columnExport: ColumnExport }[],
  sortRows: boolean
): void => {
  const wb = utils.book_new();
  data.forEach(({ name, columnExport: sheetData }) => {
    const dataAoa = colExportToAOA(sheetData, sortRows);
    const ws = utils.aoa_to_sheet(dataAoa);
    utils.book_append_sheet(wb, ws, name);
  });
  writeFileXLSX(wb, `${filename}.xlsx`);
};

export type { ColumnExport };
export { downloadCSV, downloadExcelSheet, downloadExcelWorkbook };
