import { z } from "zod";
import { TimestampSchema } from "../types/zod";
import { useApiInstance, ValidationErrorSchema } from "../api/useApiInstance";
import axios from "axios";

enum EmailCodeType {
  AGENCY_INVITE = "agency_invite",
  BRAND_INVITE = "brand_invite",
}

const UserSchema = z.object({
  id: z.union([z.string(), z.number()]),
  email: z.string(),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  account_confirmed: z.boolean()
});

const AgencySchema = z.object({
  id: z.union([z.string(), z.number()]),
  name: z.string()
});

const BrandSchema = z.object({
  id: z.union([z.string(), z.number()]),
  name: z.string(),
  agency: AgencySchema
});

const EmailCodeVerifiedSchema = z.object({
  code: z.string(),
  created_at: TimestampSchema,
  expires_at: TimestampSchema,
  code_type: z.string(),
  user: UserSchema,
  agency: AgencySchema,
  brand: BrandSchema.nullable()
});

const NewMemberSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  email: z.string().email(),
  plain_password: z.string()
});

type EmailCodeVerified = z.infer<typeof EmailCodeVerifiedSchema>;
type NewMemberObject = z.infer<typeof NewMemberSchema>;

const useEmailCode = (emailCode: string | null | undefined) => {

  const { instance, useWrappedSWR } = useApiInstance();
  const { data, mutate, isLoading, error } = useWrappedSWR(emailCode ? `/email-code/verify/${emailCode}` : undefined);
  const verified: EmailCodeVerified | undefined = data && !error ? EmailCodeVerifiedSchema.parse(data) : undefined;

  const acceptInvitation = async (verified: EmailCodeVerified, userData?: NewMemberObject) => {

    let url = "";
    switch (verified.code_type) {
      case EmailCodeType.AGENCY_INVITE:
        url = `/agency/invite/confirmation/${verified.code}`;
        break;
      case EmailCodeType.BRAND_INVITE:
        url = `/brand/invite/confirmation/${verified.code}`;
        break;
    }

    try {

      await instance.post(url, (verified.user.account_confirmed ? {
        token: verified.code
      } : {
        token: verified.code,
        first_name: userData?.first_name,
        last_name: userData?.last_name,
        plain_password: userData?.plain_password
      }));

    } catch (error: any) {

      if (error && error.response && error.response.status === 400 && axios.isAxiosError(error)) {
        throw ValidationErrorSchema.parse(error.response.data);
      }

    }

  };

  return { verified, isLoading, error, acceptInvitation };
};

export { useEmailCode, EmailCodeType };
export type { EmailCodeVerified, NewMemberObject };