import { ProjectState } from "../projectState";
import { useMemo } from "react";
import { useProjectDispatch, useProjectSelector } from "../../hooks/useEditor";
import { createAction } from "../userEventHandlers";
import { CounterEventType } from "../handlers/handleCounter";

const useCounters = () => {
  const counters = useProjectSelector(
    (project: ProjectState) => project.counters
  );

  const counterIds: number[] = useMemo(
    () =>
      Object.entries(counters)
        .map(([key]) => parseInt(key))
        .sort(),
    [counters]
  );

  return { counterIds };
};

const useCounter = (counterId: number) => {
  const projectDispatch = useProjectDispatch();

  const value = useProjectSelector(
    (project: ProjectState) => project.counters[counterId].value
  );

  const name = useProjectSelector(
    (project: ProjectState) => project.counters[counterId].name
  );

  const increment = () => {
    projectDispatch(createAction[CounterEventType.Increment]({ counterId }));
  };

  const decrement = () => {
    projectDispatch(createAction[CounterEventType.Decrement]({ counterId }));
  };

  return { value, name, increment, decrement };
};

export { useCounters, useCounter };
