import { useApiInstance, ValidationErrorSchema } from "../api/useApiInstance";
import { useSWRConfig } from "swr";
import { z } from "zod";
import { TimestampSchema } from "../types/zod";
import axios from "axios";

const AgencyMemberSchema = z.object({
  id: z.union([z.string(), z.number()]),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  email: z.string(),
  joined_at: TimestampSchema
});

const AgencySchema = z.object({
  id: z.union([z.string(), z.number()]),
  agency_name: z.string(),
  agency_members: z.array(AgencyMemberSchema)
});


type AgencyMember = z.infer<typeof AgencyMemberSchema>;
type Agency = z.infer<typeof AgencySchema>;


const useAgency = (agencyId: number) => {

  const { mutate: globalMutate } = useSWRConfig();
  const { instance, useWrappedSWR } = useApiInstance();
  const { data, mutate, isLoading, error } = useWrappedSWR(`/agency/${agencyId}`);
  const agency: Agency | undefined = data && !error ? AgencySchema.parse(data) : undefined;

  const renameAgency = async (updateRequest: {
    agency_name: string,
  }) => {
    await instance.put(`/agency/${agencyId}`, updateRequest);
    await mutate({ ...data, agency_name: updateRequest.agency_name });
    await globalMutate(`/account`);
  };

  const inviteMember = async (updateRequest: { email: string }) => {

    try {
      await instance.post(`/agency/${agencyId}/invite`, updateRequest);
    } catch (error: any) {
      if (error && error.response && error.response.status === 400 && axios.isAxiosError(error)) {
        throw ValidationErrorSchema.parse(error.response.data);
      }
    }

    await globalMutate(`/agency/${agencyId}`);
  };

  const deleteMember = async (memberId: number | string) => {
    await instance.delete(`/agency/${agencyId}/member/${memberId}`);
    await globalMutate(`/agency/${agencyId}`);
  };

  return { agency, isLoading, error, renameAgency, inviteMember, deleteMember };
};


export { useAgency };
export type { Agency, AgencyMember };
