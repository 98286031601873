import { Attribute, AttributeType } from "../../types/attribute";
import { UID } from "../../types/uid";
import {
  Formula,
  FormulaBlock,
  FormulaBlockType,
  SpreadMethodType,
} from "../../types/formula";
import { Select, SelectRow } from "../../types/select";
import { Source } from "../../types/touchpoints";
import {
  PartialRecord,
  FullRecord,
  EntityDefinitions,
  Pair,
} from "../../types/record";
import { BoolType } from "../../types/filter";

const extractRawAttributeValue = ({
  attributeId,
  attributeValues,
}: {
  attributeId: UID;
  attributeValues: { [attributeId: UID]: string | undefined };
}): string | undefined => {
  return attributeId in attributeValues
    ? attributeValues[attributeId]
    : undefined;
};

const extractReadableAttributeValueList = ({
  attributeId,
  attributeDefinitions,
  selectDefinitions,
  attributeValues,
  accessSelectRowValue,
}: {
  attributeId: UID;
  attributeDefinitions: { [attributeId: UID]: Attribute };
  selectDefinitions: { [selectId: UID]: Select };
  attributeValues: { [attributeId: UID]: string | undefined };
  accessSelectRowValue: (selectRow: SelectRow) => string;
}): { readable: string; raw: string | undefined }[] => {
  if (!(attributeId in attributeDefinitions)) {
    return [];
  }
  const attribute: Attribute = attributeDefinitions[attributeId];
  const attributeValue = extractRawAttributeValue({
    attributeId,
    attributeValues,
  });

  switch (attribute.type) {
    case AttributeType.Checkbox:
      const boolType: BoolType = (attributeValue as BoolType) || BoolType.False;
      return boolType === BoolType.True
        ? [{ readable: "True", raw: boolType }]
        : [{ readable: "False", raw: boolType }];
    case AttributeType.Currency:
    case AttributeType.Text:
    case AttributeType.Number:
    case AttributeType.Date:
      return [{ readable: attributeValue || "", raw: attributeValue }];
    case AttributeType.SingleSelect: {
      if (!attributeValue || !attribute.selectId) return [];
      const rowId: UID = attributeValue;
      const selectId: UID = attribute.selectId;
      if (!(selectId in selectDefinitions)) return [];
      const select: Select = selectDefinitions[selectId];
      if (!(rowId in select.rowDefinitions)) return [];
      const selectRow: SelectRow = select.rowDefinitions[rowId];
      return [
        { readable: accessSelectRowValue(selectRow), raw: attributeValue },
      ];
    }
    case AttributeType.MultiSelect: {
      if (!attributeValue || !attribute.selectId) return [];
      const rowIds: UID[] = attributeValue.split(",");
      const selectId: UID = attribute.selectId;
      if (!(selectId in selectDefinitions)) return [];
      const select: Select = selectDefinitions[selectId];
      const humanValues: { readable: string; raw: string | undefined }[] = [];
      rowIds.forEach((rowId: UID) => {
        if (!(rowId in select.rowDefinitions)) return;
        const selectRow: SelectRow = select.rowDefinitions[rowId];
        humanValues.push({
          readable: accessSelectRowValue(selectRow),
          raw: rowId,
        });
      });
      return humanValues;
    }
  }
};

const defineRecord = (
  partialRecord: PartialRecord,
  definitions: EntityDefinitions
): FullRecord => {
  const targetId = partialRecord.entityIds.targetId;
  const target = targetId
    ? definitions.targetDefinitions?.[targetId]
    : undefined;
  const offerId = partialRecord.entityIds.offerId;
  const offer = offerId ? definitions.offerDefinitions?.[offerId] : undefined;
  const sourceId = partialRecord.entityIds.sourceId;
  const source = sourceId
    ? definitions.sourceDefinitions?.[sourceId]
    : undefined;
  const mediumId = partialRecord.entityIds.mediumId;
  const medium = mediumId
    ? definitions.mediumDefinitions?.[mediumId]
    : undefined;
  return {
    targetPair:
      target && targetId ? { id: targetId, value: target } : undefined,
    offerPair: offer && offerId ? { id: offerId, value: offer } : undefined,
    sourcePair:
      source && sourceId ? { id: sourceId, value: source } : undefined,
    mediumPair:
      medium && mediumId ? { id: mediumId, value: medium } : undefined,
    chosenAttributeValues: partialRecord.chosenAttributeValues,
    chosenFormulaValues: partialRecord.chosenFormulaValues,
    formulaResults: partialRecord.formulaResults,
  };
};

const initializeRecords = ({
  offerIds,
}: {
  offerIds: UID[];
}): PartialRecord[] =>
  offerIds.map((offerId: UID) => ({
    entityIds: {
      offerId,
      targetId: undefined,
      sourceId: undefined,
      mediumId: undefined,
    },
    chosenAttributeValues: {},
    chosenFormulaValues: {},
    formulaResults: {},
  }));

const addTargetsToRecords = ({
  records,
  targetIds,
}: {
  records: PartialRecord[];
  targetIds: UID[];
}): PartialRecord[] => {
  const newRecords: PartialRecord[] = [];
  records.forEach((record) => {
    targetIds.forEach((targetId) => {
      const newRecord = structuredClone(record);
      newRecord.entityIds.targetId = targetId;
      newRecords.push(newRecord);
    });
  });
  return newRecords;
};

const addTouchpointsToRecords = ({
  records,
  sourceIds,
  sourceDefinitions,
}: {
  records: PartialRecord[];
  sourceIds: UID[];
  sourceDefinitions: { [sourceId: UID]: Source };
}): PartialRecord[] => {
  const newRecords: PartialRecord[] = [];
  records.forEach((record) => {
    sourceIds.forEach((sourceId) => {
      const mediumIds: UID[] =
        sourceId && sourceId in sourceDefinitions
          ? sourceDefinitions[sourceId].mediumOrdering
          : [];
      mediumIds.forEach((mediumId) => {
        const newRecord = structuredClone(record);
        newRecord.entityIds.mediumId = mediumId;
        newRecord.entityIds.sourceId = sourceId;
        newRecords.push(newRecord);
      });
    });
  });
  return newRecords;
};

const buildFormula = ({
  partialRecord,
  formulaPair,
  entityDefinitions,
  attributeDefinitions,
  selectDefinitions,
  accessSelectRowValue,
}: {
  partialRecord: PartialRecord;
  formulaPair: Pair<Formula>;
  entityDefinitions: EntityDefinitions;
  attributeDefinitions: { [attributeId: UID]: Attribute };
  selectDefinitions: { [selectId: UID]: Select };
  accessSelectRowValue: (row: SelectRow) => string;
}): PartialRecord[] => {
  const formulaId = formulaPair.id;
  const formulaBlockDefinitions = formulaPair.value.blockDefinitions;
  const formulaBlockIds = formulaPair.value.blockOrdering;
  const formulaBlocks: FormulaBlock[] = formulaBlockIds
    .filter((blockId) => blockId in formulaBlockDefinitions)
    .map((blockId: UID) => formulaBlockDefinitions[blockId]);

  partialRecord.formulaResults[formulaId] = "";

  const accumulateRecords = (
    remainingBlocks: FormulaBlock[],
    partialRecords: PartialRecord[]
  ): PartialRecord[] => {
    if (remainingBlocks.length === 0) return partialRecords;
    const nextRecords: PartialRecord[] = [];
    const formulaBlock = remainingBlocks[0];

    partialRecords.forEach((oldRecord) => {
      const partialRecord = structuredClone(oldRecord);
      const fullRecord = defineRecord(partialRecord, entityDefinitions);

      const attributeValues = {
        ...(fullRecord.offerPair?.value.attributeValues || {}),
        ...(fullRecord.mediumPair?.value.attributeValues || {}),
        ...partialRecord.chosenAttributeValues,
      };

      switch (formulaBlock.type) {
        case FormulaBlockType.Text:
          partialRecord.formulaResults[formulaId] += formulaBlock.value;
          nextRecords.push(partialRecord);
          break;
        case FormulaBlockType.Attribute:
          const attributeId: UID = formulaBlock.value;
          const formulaValues: { readable: string; raw: string | undefined }[] =
            [];
          if (
            formulaId in partialRecord.chosenFormulaValues &&
            attributeId in partialRecord.chosenFormulaValues[formulaId]
          ) {
            formulaValues.push({
              readable:
                partialRecord.chosenFormulaValues[formulaId][attributeId],
              raw: partialRecord.chosenAttributeValues[attributeId],
            });
          } else {
            extractReadableAttributeValueList({
              attributeId,
              attributeDefinitions,
              selectDefinitions,
              attributeValues,
              accessSelectRowValue,
            }).forEach((formulaValue) => formulaValues.push(formulaValue));
          }
          const spreadFormulaValues =
            formulaBlock.spreadMethod === SpreadMethodType.ForEach
              ? formulaValues
              : [
                  {
                    readable: formulaValues
                      .map(({ readable }) => readable)
                      .join(formulaBlock.separator),
                    raw: extractRawAttributeValue({
                      attributeId,
                      attributeValues,
                    }),
                  },
                ];

          spreadFormulaValues
            .filter((formulaValue) => formulaValue.readable.length > 0)
            .forEach((formulaValue) => {
              const newRecordFormula = structuredClone(partialRecord);
              newRecordFormula.chosenAttributeValues[attributeId] =
                formulaValue.raw;
              if (!(formulaId in newRecordFormula.chosenFormulaValues)) {
                newRecordFormula.chosenFormulaValues[formulaId] = {};
              }
              newRecordFormula.chosenFormulaValues[formulaId][attributeId] =
                formulaValue.readable;
              newRecordFormula.formulaResults[formulaId] +=
                formulaValue.readable;
              nextRecords.push(newRecordFormula);
            });
      }
    });

    return accumulateRecords(remainingBlocks.slice(1), nextRecords);
  };

  return accumulateRecords(formulaBlocks, [partialRecord]);
};

export {
  buildFormula,
  initializeRecords,
  defineRecord,
  addTargetsToRecords,
  addTouchpointsToRecords,
  extractReadableAttributeValueList,
};
