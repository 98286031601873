import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Skeleton,
  TextField,
  Title,
} from "monday-ui-react-core";
import { Content } from "../components/Content";
import { Nav } from "../components/Nav";
import { BrandId, ProjectId } from "../types/util";
import { BrandProject, useBrand } from "../hooks/useBrand";
import { Copy, Pencil, Plus, Trash } from "@phosphor-icons/react";
import { useState } from "react";
import { useProject, useProjects } from "../hooks/useProjects";
import { toast } from "react-toastify";

const CampaignRow = ({
  className,
  projectId,
  onClick,
}: {
  className?: string;
  projectId: ProjectId;
  onClick: () => void;
}) => {
  const [isAttemptingDelete, setIsAttemptingDelete] = useState(false);
  const [deleteString, setDeleteString] = useState<string | undefined>("");
  const [isAttemptingRename, setIsAttemptingRename] = useState(false);
  const [renameString, setRenameString] = useState<string | undefined>("");
  const {
    project,
    isLoading,
    createdDateString,
    modifiedDateString,
    deleteProject,
    renameProject,
    duplicateProject,
  } = useProject(projectId);

  const renameStringIsValid = () => {
    return renameString && renameString.length > 0;
  };

  const closeDeleteModal = () => {
    setIsAttemptingDelete(false);
    setDeleteString(undefined);
  };

  const closeRenameModal = () => {
    setIsAttemptingRename(false);
    setRenameString(undefined);
  };

  return (
    <div
      className={`${className} flex shadow hover:bg-slate-50 w-full bg-white gap-2 py-2 px-4 rounded-md items-center`}
    >
      {!project || isLoading ? (
        <div className="grid grid-cols-12 w-full">
          {Array.from({ length: 3 }, (_, idx) => idx).map((key) => (
            <div className="col-span-4 h-8 flex items-center" key={key}>
              <Skeleton type={Skeleton.types.TEXT}></Skeleton>
            </div>
          ))}
          <div className="col-span-4" />
        </div>
      ) : (
        <>
          <button className="grid grid-cols-12 w-full " onClick={onClick}>
            <div className="col-span-4 text-start font-semibold">
              {project.name}
            </div>
            <div className="col-span-4 text-start ">
              Last edited {modifiedDateString}
            </div>
            <div className="col-span-4 text-start ">
              Created {createdDateString}
            </div>
          </button>
          <MenuButton dialogPosition={MenuButton.dialogPositions.BOTTOM_START}>
            <Menu id={`campaign-${project.id}`}>
              <MenuItem
                icon={Pencil}
                title="Rename"
                onClick={() => {
                  setIsAttemptingRename(true);
                  setRenameString(project.name);
                }}
              />
              <MenuItem
                icon={Copy}
                title="Duplicate"
                onClick={() => {
                  duplicateProject();
                  toast.success(
                    "Duplicate started! You should see it shortly."
                  );
                }}
              />
              <MenuItem
                icon={Trash}
                title="Delete campaign"
                onClick={() => setIsAttemptingDelete(true)}
              />
            </Menu>
          </MenuButton>
          <Modal
            data-testid={"modal_delete_attempt"}
            show={isAttemptingDelete}
            onClose={closeDeleteModal}
          >
            <ModalHeader title={`Delete ${project.name}?`} />
            <ModalContent>
              <div className="pb-2">
                All data will be lost; others will also lose access.
              </div>
              <div className="pb-5">
                {`To confirm, type the name of the project "${project.name}":`}
              </div>
              <TextField
                autoFocus
                value={deleteString}
                placeholder={"Project name"}
                onChange={(str) => setDeleteString(str)}
                validation={
                  deleteString?.toLowerCase() === project.name.toLowerCase()
                    ? { status: undefined }
                    : { status: "error" }
                }
              ></TextField>
            </ModalContent>
            <ModalFooter className="flex justify-start gap-2">
              <Button kind={Button.kinds.PRIMARY} onClick={closeDeleteModal}>
                Cancel
              </Button>
              <Button
                kind={Button.kinds.SECONDARY}
                disabled={
                  deleteString?.toLowerCase() !== project.name.toLowerCase()
                }
                onClick={() => {
                  deleteProject();
                  closeDeleteModal();
                }}
                color={Button.colors.NEGATIVE}
              >
                Delete
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            data-testid={"modal_rename_attempt"}
            show={isAttemptingRename}
            onClose={closeRenameModal}
          >
            <ModalHeader title={`Rename "${project.name}"`} />
            <ModalContent>
              <TextField
                value={renameString}
                onChange={(str) => setRenameString(str)}
                validation={
                  !renameStringIsValid()
                    ? { status: "error" }
                    : { status: undefined }
                }
                onKeyDown={(event) => {
                  if (
                    event.key === "Enter" &&
                    renameString &&
                    renameStringIsValid()
                  ) {
                    renameProject(renameString);
                    closeRenameModal();
                  }
                }}
              ></TextField>
            </ModalContent>
            <ModalFooter className="flex justify-start gap-2">
              <Button kind={Button.kinds.SECONDARY} onClick={closeRenameModal}>
                Cancel
              </Button>
              <Button
                kind={Button.kinds.PRIMARY}
                disabled={!renameString || renameString.length === 0}
                onClick={() => {
                  renameProject(renameString!);
                  closeRenameModal();
                }}
              >
                Rename
              </Button>
            </ModalFooter>
          </Modal>
        </>
      )}
    </div>
  );
};

const Campaigns = ({ className }: { className?: string }) => {
  const { brandId } = useParams<{ brandId: BrandId }>();
  const navigate = useNavigate();
  const [isCreatingCampaign, setIsCreatingCampaign] = useState(false);
  const [newCampaignName, setNewCampaignName] = useState<string | undefined>();
  const { createCampaign } = useProjects(brandId!);

  if (!brandId) {
    throw new Error("Campaigns view started without brand ID");
  }

  const {
    brand,
    isLoading: brandIsLoading,
    error: brandError,
  } = useBrand(brandId);

  if ((!brand && !brandIsLoading) || brandError) {
    console.error(brandError);
  }

  const closeNewCampaignModal = () => {
    setIsCreatingCampaign(false);
    setNewCampaignName(undefined);
  };

  return (
    <div className={`${className} flex flex-col gap-2 bg-slate-50 h-screen`}>
      <Nav />
      <Content innerClassName="flex flex-col gap-4 pt-2">
        {brandIsLoading ? (
          <Skeleton type={Skeleton.types.TEXT} size={Skeleton.sizes.TEXT.H1} />
        ) : (
          <Title type="h1">{brand?.brand_name}</Title>
        )}
        <div>
          <Button leftIcon={Plus} onClick={() => setIsCreatingCampaign(true)}>
            New campaign
          </Button>
        </div>
        <div className="flex flex-col gap-2">
          {!brand || brandIsLoading
            ? Array.from({ length: 4 }, (_, idx) => idx).map((key) => (
                <Skeleton
                  key={key}
                  type={Skeleton.types.RECTANGLE}
                  height={40}
                  width={800}
                ></Skeleton>
              ))
            : brand.projects.map((project: BrandProject) => (
                <CampaignRow
                  key={project.id}
                  projectId={project.id as ProjectId}
                  onClick={() => navigate(`/edit/${project.id}`)}
                />
              ))}
        </div>
      </Content>

      <Modal
        data-testid={"modal_rename_attempt"}
        show={isCreatingCampaign}
        onClose={closeNewCampaignModal}
      >
        <ModalHeader title="New Campaign" />
        <ModalContent>
          <TextField
            autoFocus
            onChange={(str) => setNewCampaignName(str)}
            onKeyDown={(event) => {
              if (
                event.key === "Enter" &&
                newCampaignName &&
                newCampaignName.length > 0
              ) {
                createCampaign(newCampaignName);
                closeNewCampaignModal();
              }
            }}
          ></TextField>
        </ModalContent>
        <ModalFooter className="flex justify-start gap-2">
          <Button kind={Button.kinds.SECONDARY} onClick={closeNewCampaignModal}>
            Cancel
          </Button>
          <Button
            kind={Button.kinds.PRIMARY}
            disabled={!newCampaignName || newCampaignName.length === 0}
            onClick={() => {
              createCampaign(newCampaignName!);
              closeNewCampaignModal();
            }}
          >
            Create
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export { Campaigns };
