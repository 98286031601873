import { produce } from "immer";
import { UID } from "../../types/uid";
import { EventGroup, HandlerLookup, makeGeneralPayload } from "../eventUtil";
import { createAction } from "../userEventHandlers";

enum ColumnFormatEventType {
  Create = "columnFormat/create",
  Rename = "columnFormat/rename",
  Delete = "columnFormat/delete",
  Duplicate = "columnFormat/duplicate",
}

type ColumnFormatEvent = EventGroup<{
  [ColumnFormatEventType.Create]: {
    columnFormatId: UID;
  };
  [ColumnFormatEventType.Rename]: {
    columnFormatId: UID;
    newName: string;
  };
  [ColumnFormatEventType.Delete]: {
    columnFormatId: UID;
  };
  [ColumnFormatEventType.Duplicate]: {
    referenceColumnFormatId: UID;
    newColumnFormatId: UID;
  };
}>;

const columnFormatHandlers: HandlerLookup<ColumnFormatEvent> = {
  [ColumnFormatEventType.Create]: {
    generalizer: ({ payload: { columnFormatId } }) =>
      makeGeneralPayload({
        payloadA: columnFormatId,
      }),
    specifier: ({ payloadA }) =>
      createAction[ColumnFormatEventType.Create]({
        columnFormatId: payloadA,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { columnFormatId } = action.payload;
        draftState.columnFormats.definitions[columnFormatId] = {
          name: "New column format",
          columnDefinitions: {},
          columnOrdering: [],
        };
        draftState.columnFormats.ordering.push(columnFormatId);
      }),
    validator: (state, action) => {
      const { columnFormatId } = action.payload;
      return !(columnFormatId in state.columnFormats.definitions);
    },
  },
  [ColumnFormatEventType.Rename]: {
    generalizer: ({ payload: { columnFormatId, newName } }) =>
      makeGeneralPayload({
        payloadA: columnFormatId,
        payloadB: newName,
      }),
    specifier: ({ payloadA, payloadB }) =>
      createAction[ColumnFormatEventType.Rename]({
        columnFormatId: payloadA,
        newName: payloadB,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        draftState.columnFormats.definitions[
          action.payload.columnFormatId
        ].name = action.payload.newName;
      }),
    validator: (state, action) => {
      const { columnFormatId } = action.payload;
      return columnFormatId in state.columnFormats.definitions;
    },
  },
  [ColumnFormatEventType.Delete]: {
    generalizer: ({ payload }) =>
      makeGeneralPayload({
        payloadA: payload.columnFormatId,
      }),
    specifier: ({ payloadA }) =>
      createAction[ColumnFormatEventType.Delete]({
        columnFormatId: payloadA,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { columnFormatId } = action.payload;
        delete draftState.columnFormats.definitions[columnFormatId];
        draftState.columnFormats.ordering =
          draftState.columnFormats.ordering.filter(
            (columnFormatId: UID) =>
              action.payload.columnFormatId !== columnFormatId
          );
      }),
    validator: (state, action) => {
      const { columnFormatId } = action.payload;
      return columnFormatId in state.columnFormats.definitions;
    },
  },
  [ColumnFormatEventType.Duplicate]: {
    generalizer: ({
      payload: { referenceColumnFormatId, newColumnFormatId },
    }) =>
      makeGeneralPayload({
        payloadA: referenceColumnFormatId,
        payloadB: newColumnFormatId,
      }),
    specifier: ({ payloadA, payloadB }) =>
      createAction[ColumnFormatEventType.Duplicate]({
        referenceColumnFormatId: payloadA,
        newColumnFormatId: payloadB,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { referenceColumnFormatId, newColumnFormatId } = action.payload;
        draftState.columnFormats.definitions[newColumnFormatId] = {
          ...state.columnFormats.definitions[referenceColumnFormatId],
        };
        draftState.columnFormats.ordering.splice(
          draftState.columnFormats.ordering.indexOf(referenceColumnFormatId) +
            1,
          0,
          newColumnFormatId
        );
      }),
    validator: (state, action) => {
      const { referenceColumnFormatId, newColumnFormatId } = action.payload;
      return (
        !(newColumnFormatId in state.columnFormats.definitions) &&
        referenceColumnFormatId in state.columnFormats.definitions
      );
    },
  },
};

export { columnFormatHandlers, ColumnFormatEventType };

export type { ColumnFormatEvent };
