import { Check } from "@phosphor-icons/react";
import { useSaveState } from "../../hooks/useSaveState";
import { useOnlineStatus } from "../../hooks/useOnlineStatus";

const SaveStatus = ({ className }: { className?: string }) => {
  const { isSaved } = useSaveState();
  const { isOnline } = useOnlineStatus();

  return (
    <div className={`${className} text-sm flex items-center text-slate-600 `}>
      {!isOnline ? (
        <div className="text-red-600">
          Disconnected from server. Please check your internet connection.
        </div>
      ) : isSaved ? (
        <>
          Saved
          <div className="ml-1">
            <Check />
          </div>
        </>
      ) : (
        "Saving..."
      )}
    </div>
  );
};

export { SaveStatus };
