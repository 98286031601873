const AttributeTitle = ({
  className,
  text,
}: {
  className?: string;
  text?: string;
}) => {
  return (
    <div className={`${className} text-sm whitespace-nowrap`}>{text}</div>
  );
};

export { AttributeTitle };
