import { Icon, Plus } from "@phosphor-icons/react";
import { Button, Menu, MenuButton, MenuItem } from "monday-ui-react-core";
import { useState } from "react";

type AddNewOptions<OptionValueType> = {
  value: OptionValueType;
  displayValue: string;
  icon: Icon;
}[];

const AddNewButton = <OptionValueType,>({
  className,
  onSelect = () => {},
  options,
  title = "Add new",
}: {
  className?: string;
  onSelect?: (item: OptionValueType) => void;
  options?: AddNewOptions<OptionValueType>;
  title?: string;
}) => {
  const [showsMenu, setShowsMenu] = useState(false);

  return (
    <div className={`${className}`}>
      {options ? (
        <MenuButton
          className="text-sm pl-2 gap-0 shadow w-fit bg-white"
          size={MenuButton.sizes.XS}
          component={() => <Plus size={16} />}
          text={title}
          onMenuHide={() => setShowsMenu(false)}
          onMenuShow={() => setShowsMenu(true)}
          open={showsMenu}
        >
          <Menu id="add-new-menu">
            {options.map(({ displayValue, value, icon }) => (
              <MenuItem
                key={displayValue}
                title={displayValue}
                icon={icon}
                onClick={() => {
                  onSelect(value);
                  setShowsMenu(false);
                }}
              />
            ))}
          </Menu>
        </MenuButton>
      ) : (
        <Button
          kind={Button.kinds.TERTIARY}
          leftIcon={Plus}
          size={Button.sizes.XS}
          className="text-sm px-2 gap-0 shadow bg-white"
          noSidePadding
          onClick={() => {
            if (!options) {
              onSelect(undefined as OptionValueType);
            }
          }}
        >
          <div>{title}</div>
        </Button>
      )}
    </div>
  );
};

export type { AddNewOptions };
export { AddNewButton };
