import {
  ArrowSquareOut,
  CaretLeft,
  CirclesThree,
  Columns,
  Link,
  Megaphone,
  Star,
  Video,
} from "@phosphor-icons/react";
import { Button, Divider, ListItem, ListItemIcon } from "monday-ui-react-core";
import { forwardRef, useState } from "react";
import { usePersistentState } from "../../hooks/usePersistentState";
import { useDebug } from "../hooks/useDebug";

const USE_DEBUG_TOOLBAR =
  process.env.REACT_APP_DISPLAY_DEBUG_TOOLBAR?.toLowerCase() === "true";

enum ProjectView {
  Selects = "selects",
  Offers = "offers",
  Touchpoints = "touchpoints",
  URLs = "urls",
  UTMs = "utms",
  Columns = "columns",
  Export = "export",
}

const getIcon = (view: ProjectView) => {
  switch (view) {
    case ProjectView.Selects:
      return CirclesThree;
    case ProjectView.Offers:
      return Megaphone;
    case ProjectView.Touchpoints:
      return Video;
    case ProjectView.URLs:
      return Link;
    case ProjectView.UTMs:
      return Star;
    case ProjectView.Columns:
      return Columns;
    case ProjectView.Export:
      return ArrowSquareOut;
  }
};

const getDisplayName = (view: ProjectView) => {
  switch (view) {
    case ProjectView.Selects:
      return "Selects";
    case ProjectView.Offers:
      return "Offers";
    case ProjectView.Touchpoints:
      return "Touchpoints";
    case ProjectView.URLs:
      return "Links";
    case ProjectView.UTMs:
      return "UTMs";
    case ProjectView.Columns:
      return "Columns";
    case ProjectView.Export:
      return "Export";
  }
};

const ProjectNavigator = ({
  className,
  onViewChange,
  selectedView,
}: {
  className?: string;
  onViewChange: (view: ProjectView) => void;
  selectedView: ProjectView;
}) => {
  const {
    navigatorIsMinimized: isMinimized,
    setNavigatorIsMinimized: setIsMinimized,
  } = usePersistentState();

  const [isHovering, setIsHovering] = useState(false);

  const ViewOption = forwardRef(({ view }: { view: ProjectView }, ref) => {
    return (
      <ListItem
        ref={ref}
        id={view}
        onClick={() => onViewChange(view)}
        selected={selectedView === view}
      >
        <>
          <ListItemIcon className="m-0" icon={getIcon(view)} />
          <div className={isMinimized ? "hidden" : "ml-2"}>
            {getDisplayName(view)}
          </div>
        </>
      </ListItem>
    );
  });

  const SectionHeader = ({ title }: { title: string }) => {
    return (
      <div
        className={`${
          isMinimized ? "hidden" : ""
        } ml-2 mb-1 text-sm font-semibold`}
      >
        {title}
      </div>
    );
  };

  const { createError } = useDebug();

  return (
    <div
      className={`${className} ${
        isMinimized ? "w-16" : "w-40"
      } transition-width transition-300`}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div className={`${isHovering ? "relative w-full" : "hidden"}`}>
        <button
          className="absolute flex items-center justify-center  right-0 w-6 h-8 rounded-bl-lg bg-slate-100 border"
          onClick={() => setIsMinimized(!isMinimized)}
        >
          <div
            className={`${
              isMinimized ? "rotate-180" : "rotate-0"
            } transform transition-transform`}
          >
            <CaretLeft />
          </div>
        </button>
      </div>
      <div
        className={`flex flex-col h-full bg-slate-50 p-2 ${
          isMinimized ? "items-center" : ""
        } `}
      >
        <SectionHeader title="Setup" />
        <ViewOption view={ProjectView.Selects} />
        <Divider />
        <SectionHeader title="Strategize" />
        <ViewOption view={ProjectView.Offers} />
        <ViewOption view={ProjectView.Touchpoints} />
        <Divider />
        <SectionHeader title="Build" />
        <ViewOption view={ProjectView.URLs} />
        <ViewOption view={ProjectView.UTMs} />
        <Divider />
        <SectionHeader title="Share" />
        <ViewOption view={ProjectView.Columns} />
        <ViewOption view={ProjectView.Export} />

        {USE_DEBUG_TOOLBAR && (
          <div className="mt-auto h-24 text-slate-600">
            <div className="text-xs">Debug Toolbar</div>
            <Button
              size={Button.sizes.XXS}
              onClick={() => createError()}
              kind={Button.kinds.TERTIARY}
            >
              Cause error
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export { ProjectNavigator, ProjectView };
