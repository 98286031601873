import { useProjectDispatch, useProjectSelector } from "../../hooks/useEditor";
import { UID } from "../../types/uid";
import { SetEventType } from "../handlers/handleSet";
import { ProjectState } from "../projectState";
import { createAction } from "../userEventHandlers";

const useSet = (setId: UID) => {
  const projectDispatch = useProjectDispatch();

  const values = useProjectSelector(
    (project: ProjectState) => project.sets[setId]
  );

  const addValue = (value: string) => {
    projectDispatch(
      createAction[SetEventType.AddValue]({
        setId,
        value,
      })
    );
  };

  const removeValue = (value: string) => {
    projectDispatch(
      createAction[SetEventType.RemoveValue]({
        setId,
        value,
      })
    );
  };

  const clearValues = () => {
    projectDispatch(
      createAction[SetEventType.ClearValues]({
        setId,
      })
    );
  };

  return { values, addValue, removeValue, clearValues };
};

const useSets = () => {
  const setDefinitions = useProjectSelector(
    (project: ProjectState) => project.sets
  );
  return { setDefinitions };
};

export { useSets, useSet };
