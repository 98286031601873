import { useMemo } from "react";
import axios from "axios";
import { useAuth } from "../AuthContext";
import useSWR, { SWRConfiguration } from "swr";
import { z } from "zod";
import { toast } from "react-toastify";
import { maxRetryExceededErrorMessage } from "../utils/errorMessages/networkingErrorMessages";

const BASE_URL: string = process.env.REACT_APP_API_URL!;

const ValidationErrorSchema = z.array(
  z.object({
    property_path: z.string(),
    message: z.string()
  })
);

type ValidationErrors = z.infer<typeof ValidationErrorSchema>;

const useApiInstance = () => {
  const { signOut } = useAuth();

  const MAX_RETRIES = 3;
  const RETRY_DELAY = 2000;

  const instance = useMemo(() => {
    const instance = axios.create({
      baseURL: BASE_URL
    });

    instance.defaults.withCredentials = true;

    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error && error.response) {
          if (error.response.status === 401) {
            signOut(false);
          } else if (error.response.status === 500) {
            const config = error.config;

            config.__retryCount = config.__retryCount || 0;

            if (config.__retryCount >= MAX_RETRIES) {

              toast.error(maxRetryExceededErrorMessage, {
                autoClose: false,
                closeOnClick: false,
                hideProgressBar: true,
                draggable: false
              });

              return Promise.reject(error);
            }

            config.__retryCount += 1;

            const backoff: Promise<void> = new Promise((resolve) => {
              setTimeout(() => {
                resolve();
              }, RETRY_DELAY);
            });

            return backoff.then(() => {
              return instance(config);
            });
          } else {
            return Promise.reject(error);
          }
        }

        return Promise.reject(error);
      }
    );

    return instance;
  }, [signOut]);

  const fetcher = (url: string) =>
    instance
      .get(url)
      .then((res) => res.data)
      .catch((err) => {
        console.error(err);
        throw err;
      });

  const useWrappedSWR = (url?: string, options?: SWRConfiguration) => {
    return useSWR(url, fetcher, {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // retry after 0.5 seconds if the error status was 500
        if (error.status === 500)
          setTimeout(() => revalidate({ retryCount }), 500);
        else return;
      },
      ...options
    });
  };

  return { instance, useWrappedSWR };
};

export { useApiInstance, BASE_URL, ValidationErrorSchema };
export type { ValidationErrors };
