import { EventGroup, HandlerLookup, makeGeneralPayload } from "../eventUtil";
import { createAction } from "../userEventHandlers";

enum DebugEventType {
  CauseError = "debug/causeError",
}

type DebugEvent = EventGroup<{
  [DebugEventType.CauseError]: {
    errorName: string;
  };
}>;

const debugEventHandlers: HandlerLookup<DebugEvent> = {
  [DebugEventType.CauseError]: {
    generalizer: ({ payload: { errorName } }) =>
      makeGeneralPayload({
        payloadA: errorName,
      }),
    specifier: ({ payloadA }) =>
      createAction[DebugEventType.CauseError]({
        errorName: payloadA,
      }),
    reducer: (state, action) => state,
    validator: (state, action) => false,
  },
};

export { debugEventHandlers, DebugEventType };

export type { DebugEvent };
