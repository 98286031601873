import { Trash, X } from "@phosphor-icons/react";
import { Menu, MenuButton, MenuItem, MenuTitle } from "monday-ui-react-core";
import { useState } from "react";

const RemoveItemButton = ({
  className,
  onRemove,
}: {
  className?: string;
  onRemove: () => void;
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <MenuButton
      component={() => <Trash size={20} />}
      size={MenuButton.sizes.SMALL}
      className={`${className} hover:text-red-500`}
      onMenuShow={() => setMenuIsOpen(true)}
      onMenuHide={() => setMenuIsOpen(false)}
      open={menuIsOpen}
    >
      <Menu id="menu" size={Menu.sizes.XS}>
        <MenuTitle
          caption="Remove this item?"
          captionPosition={MenuTitle.positions.TOP}
        />
        <MenuItem
          icon={() => <X size={20} />}
          iconType={MenuItem.iconType.SVG}
          title="Cancel"
          onClick={() => setMenuIsOpen(false)}
        />
        <MenuItem
          className="text-red-500"
          icon={() => <Trash size={20} />}
          onClick={() => onRemove()}
          iconType={MenuItem.iconType.SVG}
          title="Delete"
        />
      </Menu>
    </MenuButton>
  );
};

export { RemoveItemButton };
