import { Heading, Text } from "monday-ui-react-core";
import { Content } from "../../components/Content";
import { Logo } from "../../components/Logo";
import { useEmailCode } from "../../hooks/useEmailCode";
import { useQuery } from "../../hooks/useQuery";
import { AgencyWelcome } from "./AgencyWelcome";
import { BrandWelcome } from "./BrandWelcome";
import { ConfirmedMember } from "./ConfirmedMember";
import { NewMember } from "./NewMember";

const AcceptInvitation = ({ className }: { className?: string }) => {

  const query = useQuery();

  const {
    verified,
    error
  } = useEmailCode(query.get("token"));

  // bad token
  if (error && error.response.status === 400) {
    return (
      <div className={`${className} h-screen`}>
        <nav className="flex gap-2 justify-between items-center">
          <Logo />
        </nav>
        <Content innerClassName="flex flex-col gap-4">
          <Text className="text-center" ellipsis={false}>
            Invalid token.
          </Text>
        </Content>
      </div>
    );
  }

  return (
    <div className={`${className} h-screen`}>

      <nav className="flex gap-2 justify-between items-center">
        <Logo />
      </nav>

      <Content innerClassName="flex flex-col gap-4">

        <Heading type={Heading.types.h1} className="text-center" value={"Accept Invitation"} />

        <AgencyWelcome className="mx-auto w-1/3" verified={verified} />

        <BrandWelcome className="mx-auto w-1/3" verified={verified} />

        <ConfirmedMember className="mx-auto w-1/3 text-center" verified={verified} />

        <NewMember className="mx-auto w-1/3" verified={verified} />

      </Content>

    </div>
  );
};

export { AcceptInvitation };
