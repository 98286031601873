import { ProjectState } from "../projectState";
import { useProjectDispatch, useProjectSelector } from "../../hooks/useEditor";
import { createAction } from "../userEventHandlers";
import { EditorPage } from "../../types/editorPage";
import { UID, generateUid } from "../../types/uid";
import { OfferEventType } from "../handlers/handleOffers";
import { useAttribute, useAttributes } from "./useAttributes";
import { useSelectOptions } from "./useSelects";
import { DropdownItem } from "../attributes/Dropdown";

const useOfferAttribute = (offerId: UID, attributeId: UID) => {
  const projectDispatch = useProjectDispatch();

  const { name, type, selectId } = useAttribute(EditorPage.Offers, attributeId);

  const { selectRows } = useSelectOptions(selectId);

  const value = useProjectSelector(
    (project: ProjectState) =>
      project.offers.definitions[offerId].attributeValues[attributeId] ||
      undefined
  );

  const editValue = (newValue?: string) => {
    projectDispatch(
      createAction[OfferEventType.EditAttribute]({
        offerId,
        attributeId,
        newValue: newValue || "",
      })
    );
  };

  return { name, type, selectRows, value, editValue };
};

const useOffer = (offerId: UID) => {
  const projectDispatch = useProjectDispatch();

  const { attributeIds } = useAttributes(EditorPage.Offers);
  const name = useProjectSelector(
    (project: ProjectState) => project.offers.definitions[offerId].title
  );

  const remove = () => {
    projectDispatch(createAction[OfferEventType.Delete]({ offerId }));
  };

  const rename = (newName: string) => {
    projectDispatch(createAction[OfferEventType.Rename]({ offerId, newName }));
  };

  const duplicate = () => {
    projectDispatch(
      createAction[OfferEventType.Duplicate]({
        referenceOfferId: offerId,
        newOfferId: generateUid(),
      })
    );
  };

  return { name, remove, rename, duplicate, attributeIds };
};

const useOffers = () => {
  const projectDispatch = useProjectDispatch();

  const offerIds = useProjectSelector(
    (project: ProjectState) => project.offers.ordering
  );

  const offerDefinitions = useProjectSelector(
    (project: ProjectState) => project.offers.definitions
  );

  const offerIdOptions: DropdownItem[] = offerIds.map((offerId: UID) => ({
    id: offerId,
    label: offerDefinitions[offerId].title,
  }));

  const create = () => {
    const offerId = generateUid();
    projectDispatch(createAction[OfferEventType.Create]({ offerId }));
  };

  return { offerIds, offerIdOptions, offerDefinitions, create };
};

export { useOffers, useOffer, useOfferAttribute };
