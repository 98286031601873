import { produce } from "immer";
import { EventGroup, HandlerLookup, makeGeneralPayload } from "../eventUtil";
import { createAction } from "../userEventHandlers";

enum MessageEventType {
  Change = "message/change",
}

type MessageEvent = EventGroup<{
  [MessageEventType.Change]: {
    newMessage: string;
  };
}>;

const messageHandlers: HandlerLookup<MessageEvent> = {
  [MessageEventType.Change]: {
    generalizer: ({ payload }) =>
      makeGeneralPayload({ payloadA: payload.newMessage }),
    specifier: ({ payloadA }) =>
      createAction[MessageEventType.Change]({ newMessage: payloadA }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        draftState.message = action.payload.newMessage;
      }),
    validator: () => true,
  },
};

export { messageHandlers, MessageEventType };
export type { MessageEvent };
