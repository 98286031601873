import { Text } from "monday-ui-react-core";
import { EmailCodeType, EmailCodeVerified } from "../../hooks/useEmailCode";

const AgencyWelcome = ({ className, verified }: { className?: string, verified?: EmailCodeVerified }) => {

  if (verified?.code_type !== EmailCodeType.AGENCY_INVITE) {
    return <></>;
  }

  return (
    <div className={`${className}`}>
      <Text className="text-center" ellipsis={false}>
        You have been invited to join the <strong>{verified?.agency.name}</strong> agency.
      </Text>
    </div>
  );
};

export { AgencyWelcome };
