import { useProjectDispatch, useProjectSelector } from "../../hooks/useEditor";
import { EditorPage } from "../../types/editorPage";
import { MediumIconType } from "../../types/touchpoints";
import { UID, generateUid } from "../../types/uid";
import { MediumEventType } from "../handlers/handleMedium";
import { ProjectState } from "../projectState";
import { createAction } from "../userEventHandlers";
import { useAttribute, useAttributes } from "./useAttributes";
import { useSelectOptions } from "./useSelects";

const useMediumAttribute = (mediumId: UID, attributeId: UID) => {
  const projectDispatch = useProjectDispatch();

  const { name, type, selectId } = useAttribute(
    EditorPage.Touchpoints,
    attributeId
  );

  const { selectRows } = useSelectOptions(selectId);

  const value = useProjectSelector(
    (project: ProjectState) =>
      project.touchpoints.mediumDefinitions[mediumId].attributeValues[
        attributeId
      ] || undefined
  );

  const editValue = (newValue?: string) => {
    projectDispatch(
      createAction[MediumEventType.EditAttribute]({
        mediumId,
        attributeId,
        newValue: newValue || "",
      })
    );
  };
  return { name, type, selectRows, value, editValue };
};

const useMedium = (mediumId: UID) => {
  const projectDispatch = useProjectDispatch();
  const { attributeIds } = useAttributes(EditorPage.Touchpoints);

  const icon = useProjectSelector(
    (project: ProjectState) =>
      project.touchpoints.mediumDefinitions[mediumId].icon
  );

  const name = useProjectSelector(
    (project: ProjectState) =>
      project.touchpoints.mediumDefinitions[mediumId].title
  );

  const utmValue = useProjectSelector(
    (project: ProjectState) =>
      project.touchpoints.mediumDefinitions[mediumId].utmValue
  );

  const remove = () => {
    projectDispatch(createAction[MediumEventType.Delete]({ mediumId }));
  };

  const rename = (newName: string) => {
    projectDispatch(
      createAction[MediumEventType.Rename]({ mediumId, newName })
    );
  };

  const changeIcon = (newIcon: MediumIconType) => {
    projectDispatch(
      createAction[MediumEventType.ChangeIcon]({ mediumId, newIcon })
    );
  };

  const editUTM = (newUTM: string) => {
    projectDispatch(
      createAction[MediumEventType.EditUTM]({ mediumId, newUTM })
    );
  };

  const duplicate = () => {
    projectDispatch(
      createAction[MediumEventType.Duplicate]({
        referenceMediumId: mediumId,
        newMediumId: generateUid(),
      })
    );
  };

  return {
    icon,
    name,
    utmValue,
    attributeIds,
    remove,
    rename,
    changeIcon,
    editUTM,
    duplicate,
  };
};

const useMediums = (sourceId: UID) => {
  const projectDispatch = useProjectDispatch();

  const mediumIds = useProjectSelector(
    (project: ProjectState) =>
      project.touchpoints.sourceDefinitions[sourceId].mediumOrdering
  );

  const create = (title: string, icon: MediumIconType, utmValue: string) => {
    const mediumId = generateUid();
    projectDispatch(
      createAction[MediumEventType.Create]({
        sourceId,
        mediumId,
        title,
        icon,
        utmValue,
      })
    );
  };

  return { mediumIds, create };
};

export { useMediums, useMedium, useMediumAttribute };
