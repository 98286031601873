import { UID } from "../../types/uid";
import { EventGroup, HandlerLookup, makeGeneralPayload } from "../eventUtil";
import { createAction } from "../userEventHandlers";
import produce from "immer";
import { FormulaBlockType, SpreadMethodType } from "../../types/formula";
import { arrayMove } from "@dnd-kit/sortable";

enum FormulaBlockEventType {
  Add = "formulaBlock/add",
  Remove = "formulaBlock/remove",
  EditValue = "formulaBlock/editValue",
  EditSpreadMethod = "formulaBlock/editSpreadMethod",
  EditSeparator = "formulaBlock/editSeparator",
  Move = "formulaBlock/move",
}

type FormulaBlockEvent = EventGroup<{
  [FormulaBlockEventType.Add]: {
    formulaId: UID;
    blockId: UID;
    blockType: FormulaBlockType;
    spreadMethod: SpreadMethodType;
  };
  [FormulaBlockEventType.Remove]: {
    formulaId: UID;
    blockId: UID;
  };
  [FormulaBlockEventType.EditValue]: {
    formulaId: UID;
    blockId: UID;
    newValue: UID;
  };
  [FormulaBlockEventType.EditSpreadMethod]: {
    formulaId: UID;
    blockId: UID;
    newSpreadMethod: SpreadMethodType;
  };
  [FormulaBlockEventType.EditSeparator]: {
    formulaId: UID;
    blockId: UID;
    newSeparator: string;
  };
  [FormulaBlockEventType.Move]: {
    formulaId: UID;
    blockId: UID;
    newIndex: number;
  };
}>;

const formulaBlockHandlers: HandlerLookup<FormulaBlockEvent> = {
  [FormulaBlockEventType.Add]: {
    generalizer: ({
      payload: { formulaId, blockId, blockType, spreadMethod },
    }) =>
      makeGeneralPayload({
        payloadA: `${formulaId}/${blockId}`,
        payloadB: blockType,
        payloadC: spreadMethod,
      }),
    specifier: ({ payloadA, payloadB, payloadC }) =>
      createAction[FormulaBlockEventType.Add]({
        formulaId: payloadA.split("/")[0],
        blockId: payloadA.split("/")[1],
        blockType: payloadB as FormulaBlockType,
        spreadMethod: payloadC as SpreadMethodType,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { formulaId, blockId, blockType, spreadMethod } = action.payload;
        draftState.formulas[formulaId].blockDefinitions[blockId] = {
          type: blockType,
          value: "",
          spreadMethod,
          separator: "-",
        };
        draftState.formulas[formulaId].blockOrdering.push(blockId);
      }),
    validator: (state, action) =>
      action.payload.formulaId in state.formulas &&
      !(
        action.payload.blockId in
        state.formulas[action.payload.formulaId].blockDefinitions
      ),
  },
  [FormulaBlockEventType.Remove]: {
    generalizer: ({ payload: { formulaId, blockId } }) =>
      makeGeneralPayload({
        payloadA: formulaId,
        payloadB: blockId,
      }),
    specifier: ({ payloadA, payloadB }) =>
      createAction[FormulaBlockEventType.Remove]({
        formulaId: payloadA,
        blockId: payloadB,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { formulaId, blockId } = action.payload;
        delete draftState.formulas[formulaId].blockDefinitions[blockId];
        draftState.formulas[formulaId].blockOrdering = state.formulas[
          formulaId
        ].blockOrdering.filter((blockId) => blockId !== action.payload.blockId);
      }),
    validator: (state, action) =>
      action.payload.formulaId in state.formulas &&
      action.payload.blockId in
        state.formulas[action.payload.formulaId].blockDefinitions,
  },
  [FormulaBlockEventType.EditValue]: {
    generalizer: ({ payload: { formulaId, blockId, newValue } }) =>
      makeGeneralPayload({
        payloadA: formulaId,
        payloadB: blockId,
        payloadC: newValue,
      }),
    specifier: ({ payloadA, payloadB, payloadC }) =>
      createAction[FormulaBlockEventType.EditValue]({
        formulaId: payloadA,
        blockId: payloadB,
        newValue: payloadC,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { formulaId, blockId, newValue } = action.payload;
        draftState.formulas[formulaId].blockDefinitions[blockId].value =
          newValue;
      }),
    validator: (state, action) =>
      action.payload.formulaId in state.formulas &&
      action.payload.blockId in
        state.formulas[action.payload.formulaId].blockDefinitions,
  },
  [FormulaBlockEventType.EditSpreadMethod]: {
    generalizer: ({ payload: { formulaId, blockId, newSpreadMethod } }) =>
      makeGeneralPayload({
        payloadA: formulaId,
        payloadB: blockId,
        payloadC: newSpreadMethod,
      }),
    specifier: ({ payloadA, payloadB, payloadC }) =>
      createAction[FormulaBlockEventType.EditSpreadMethod]({
        formulaId: payloadA,
        blockId: payloadB,
        newSpreadMethod: payloadC as SpreadMethodType,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { formulaId, blockId, newSpreadMethod } = action.payload;
        draftState.formulas[formulaId].blockDefinitions[blockId].spreadMethod =
          newSpreadMethod;
      }),
    validator: (state, action) =>
      action.payload.formulaId in state.formulas &&
      action.payload.blockId in
        state.formulas[action.payload.formulaId].blockDefinitions,
  },
  [FormulaBlockEventType.EditSeparator]: {
    generalizer: ({ payload: { formulaId, blockId, newSeparator } }) =>
      makeGeneralPayload({
        payloadA: formulaId,
        payloadB: blockId,
        payloadC: newSeparator,
      }),
    specifier: ({ payloadA, payloadB, payloadC }) =>
      createAction[FormulaBlockEventType.EditSeparator]({
        formulaId: payloadA,
        blockId: payloadB,
        newSeparator: payloadC,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { formulaId, blockId, newSeparator } = action.payload;
        draftState.formulas[formulaId].blockDefinitions[blockId].value =
          newSeparator;
      }),
    validator: (state, action) =>
      action.payload.formulaId in state.formulas &&
      action.payload.blockId in
        state.formulas[action.payload.formulaId].blockDefinitions,
  },
  [FormulaBlockEventType.Move]: {
    generalizer: ({ payload: { formulaId, blockId, newIndex } }) =>
      makeGeneralPayload({
        payloadA: formulaId,
        payloadB: blockId,
        payloadC: `${newIndex}`,
      }),
    specifier: ({ payloadA, payloadB, payloadC }) =>
      createAction[FormulaBlockEventType.Move]({
        formulaId: payloadA,
        blockId: payloadB,
        newIndex: parseInt(payloadC),
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { formulaId, blockId, newIndex } = action.payload;
        const ordering = state.formulas[formulaId].blockOrdering;
        draftState.formulas[formulaId].blockOrdering = arrayMove(
          ordering,
          ordering.indexOf(blockId),
          newIndex
        );
      }),
    validator: (state, action) =>
      action.payload.formulaId in state.formulas &&
      action.payload.blockId in
        state.formulas[action.payload.formulaId].blockDefinitions &&
      action.payload.newIndex <
        state.formulas[action.payload.formulaId].blockOrdering.length,
  },
};

export { FormulaBlockEventType, formulaBlockHandlers };
export type { FormulaBlockEvent };
