import { Link } from "react-router-dom";

const Signup = ({ className }: { className?: string }) => {
  return (
    <div className={`${className} flex gap-2`}>
      Signup
      <Link to="/login" className="text-blue-600 underline">
        Log in
      </Link>
    </div>
  );
};

export { Signup };
