import produce from "immer";
import { UID } from "../../types/uid";
import { EventGroup, HandlerLookup, makeGeneralPayload } from "../eventUtil";
import { createAction } from "../userEventHandlers";
import { MediumIconType } from "../../types/touchpoints";

enum MediumEventType {
  Create = "medium/create",
  Delete = "medium/delete",
  Duplicate = "medium/duplicate",
  Rename = "medium/rename",
  ChangeIcon = "medium/changeIcon",
  EditUTM = "medium/editUTM",
  EditAttribute = "medium/editAttribute",
}

type MediumEvent = EventGroup<{
  [MediumEventType.Create]: {
    sourceId: UID;
    mediumId: UID;
    title: string;
    icon: MediumIconType;
    utmValue: string;
  };
  [MediumEventType.Delete]: {
    mediumId: UID;
  };
  [MediumEventType.Duplicate]: {
    referenceMediumId: UID;
    newMediumId: UID;
  };
  [MediumEventType.Rename]: {
    mediumId: UID;
    newName: string;
  };
  [MediumEventType.ChangeIcon]: {
    mediumId: UID;
    newIcon: MediumIconType;
  };
  [MediumEventType.EditUTM]: {
    mediumId: UID;
    newUTM: string;
  };
  [MediumEventType.EditAttribute]: {
    mediumId: UID;
    attributeId: UID;
    newValue: string;
  };
}>;

const mediumHandlers: HandlerLookup<MediumEvent> = {
  [MediumEventType.Create]: {
    generalizer: ({ payload: { sourceId, mediumId, title, icon, utmValue } }) =>
      makeGeneralPayload({
        payloadA: `${sourceId}/${mediumId}`,
        payloadB: title,
        payloadC: `${icon}/${utmValue}`,
      }),
    specifier: ({ payloadA, payloadB, payloadC }) => {
      const path = payloadA.split("/");
      const iconName = payloadC.split("/")[0];
      const slashIdx = payloadC.indexOf("/");
      const utmValue = slashIdx >= 0 ? payloadC.slice(slashIdx + 1) : "";
      return createAction[MediumEventType.Create]({
        sourceId: path[0],
        mediumId: path[1],
        title: payloadB,
        icon: iconName as MediumIconType,
        utmValue,
      });
    },
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { sourceId, mediumId, title, icon, utmValue } = action.payload;
        draftState.touchpoints.mediumDefinitions[mediumId] = {
          sourceId,
          title,
          utmValue,
          icon,
          attributeValues: {},
        };
        draftState.touchpoints.sourceDefinitions[sourceId].mediumOrdering.push(
          mediumId
        );
      }),
    validator: (state, action) =>
      !(action.payload.mediumId in state.touchpoints.mediumDefinitions),
  },
  [MediumEventType.Delete]: {
    generalizer: ({ payload: { mediumId } }) =>
      makeGeneralPayload({
        payloadA: mediumId,
      }),
    specifier: ({ payloadA }) =>
      createAction[MediumEventType.Delete]({
        mediumId: payloadA,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { mediumId } = action.payload;
        const sourceId = state.touchpoints.mediumDefinitions[mediumId].sourceId;
        delete draftState.touchpoints.mediumDefinitions[mediumId];
        draftState.touchpoints.sourceDefinitions[sourceId].mediumOrdering =
          state.touchpoints.sourceDefinitions[sourceId].mediumOrdering.filter(
            (mediumId) => mediumId !== action.payload.mediumId
          );
      }),
    validator: (state, action) =>
      action.payload.mediumId in state.touchpoints.mediumDefinitions,
  },
  [MediumEventType.Duplicate]: {
    generalizer: ({ payload: { referenceMediumId, newMediumId } }) =>
      makeGeneralPayload({
        payloadA: referenceMediumId,
        payloadB: newMediumId,
      }),
    specifier: ({ payloadA, payloadB }) =>
      createAction[MediumEventType.Duplicate]({
        referenceMediumId: payloadA,
        newMediumId: payloadB,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { newMediumId, referenceMediumId } = action.payload;
        const sourceId =
          state.touchpoints.mediumDefinitions[referenceMediumId].sourceId;
        draftState.touchpoints.mediumDefinitions[newMediumId] = {
          ...state.touchpoints.mediumDefinitions[referenceMediumId],
        };
        draftState.touchpoints.sourceDefinitions[
          sourceId
        ].mediumOrdering.splice(
          draftState.touchpoints.sourceDefinitions[
            sourceId
          ].mediumOrdering.indexOf(referenceMediumId) + 1,
          0,
          newMediumId
        );
      }),
    validator: (state, action) =>
      !(action.payload.newMediumId in state.touchpoints.mediumDefinitions) &&
      action.payload.referenceMediumId in state.touchpoints.mediumDefinitions,
  },
  [MediumEventType.Rename]: {
    generalizer: ({ payload: { mediumId, newName } }) =>
      makeGeneralPayload({
        payloadA: mediumId,
        payloadB: newName,
      }),
    specifier: ({ payloadA, payloadB }) =>
      createAction[MediumEventType.Rename]({
        mediumId: payloadA,
        newName: payloadB,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { mediumId, newName } = action.payload;
        draftState.touchpoints.mediumDefinitions[mediumId].title = newName;
      }),
    validator: (state, action) =>
      action.payload.mediumId in state.touchpoints.mediumDefinitions,
  },
  [MediumEventType.ChangeIcon]: {
    generalizer: ({ payload: { mediumId, newIcon } }) =>
      makeGeneralPayload({
        payloadA: mediumId,
        payloadB: newIcon,
      }),
    specifier: ({ payloadA, payloadB }) =>
      createAction[MediumEventType.ChangeIcon]({
        mediumId: payloadA,
        newIcon: payloadB as MediumIconType,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { mediumId, newIcon } = action.payload;
        draftState.touchpoints.mediumDefinitions[mediumId].icon = newIcon;
      }),
    validator: (state, action) =>
      action.payload.mediumId in state.touchpoints.mediumDefinitions &&
      action.payload.newIcon in MediumIconType,
  },
  [MediumEventType.EditUTM]: {
    generalizer: ({ payload: { mediumId, newUTM } }) =>
      makeGeneralPayload({
        payloadA: mediumId,
        payloadB: newUTM,
      }),
    specifier: ({ payloadA, payloadB }) =>
      createAction[MediumEventType.EditUTM]({
        mediumId: payloadA,
        newUTM: payloadB,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { mediumId, newUTM } = action.payload;
        draftState.touchpoints.mediumDefinitions[mediumId].utmValue = newUTM;
      }),
    validator: (state, action) =>
      action.payload.mediumId in state.touchpoints.mediumDefinitions,
  },
  [MediumEventType.EditAttribute]: {
    generalizer: ({ payload: { mediumId, attributeId, newValue } }) =>
      makeGeneralPayload({
        payloadA: mediumId,
        payloadB: attributeId,
        payloadC: newValue,
      }),
    specifier: ({ payloadA, payloadB, payloadC }) =>
      createAction[MediumEventType.EditAttribute]({
        mediumId: payloadA,
        attributeId: payloadB,
        newValue: payloadC,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { mediumId, attributeId, newValue } = action.payload;
        draftState.touchpoints.mediumDefinitions[mediumId].attributeValues[
          attributeId
        ] = newValue;
      }),
    validator: (state, action) =>
      action.payload.mediumId in state.touchpoints.mediumDefinitions,
  },
};

export { mediumHandlers, MediumEventType };
export type { MediumEvent };
