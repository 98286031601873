import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text
} from "monday-ui-react-core";
import { Trash } from "@phosphor-icons/react";
import { useState } from "react";
import { AgencyMember, useAgency } from "../../../hooks/useAgency";
import { toast } from "react-toastify";
import getDateString from "../../../utils/date/DateUtils";

const AgencyMembers = ({ className, agencyId }: { className?: string, agencyId: number }) => {

  const [isAttemptingDeleteMember, setIsAttemptingDeleteMember] = useState<boolean>(false);
  const [memberToDelete, setMemberToDelete] = useState<AgencyMember | undefined>(undefined);

  const {
    agency,
    isLoading,
    deleteMember
  } = useAgency(agencyId);

  const deleteMemberFromAgency = () => {
    if (!memberToDelete) {
      return;
    }

    deleteMember(memberToDelete.id).then(() => {
      toast.success("Member deleted");
      setMemberToDelete(undefined);
      setIsAttemptingDeleteMember(false);
    });

  };

  if (isLoading || !agency) {
    return (
      <></>
    );
  }

  return (

    <div className={`${className} px-2 w-full`}>
      <div
        className="flex shadow hover:bg-slate-50 w-full bg-white gap-2 py-2 px-4 mb-1 rounded-md items-center">
        <div className="grid grid-cols-9 w-full">
          <div className="col-span-2 text-start font-semibold">
            First Name
          </div>
          <div className="col-span-2 text-start font-semibold">
            Last Name
          </div>
          <div className="col-span-2 text-start font-semibold">
            Email
          </div>
          <div className="col-span-2 text-start font-semibold">
            Joined date
          </div>
          <div className="col-span-1"></div>
        </div>
      </div>
      {agency.agency_members.map((member: AgencyMember, index) => (
        <div key={`member-${member.id}`}
             className="flex shadow hover:bg-slate-50 w-full bg-white gap-2 py-2 px-4 mb-1 rounded-md items-center">
          <div className="grid grid-cols-9 w-full">
            <div className="col-span-2 text-start">
              {member.first_name}
            </div>
            <div className="col-span-2 text-start">
              {member.last_name}
            </div>
            <div className="col-span-2 text-start">
              {member.email}
            </div>
            <div className="col-span-2 text-start">
              {getDateString(new Date(member.joined_at))}
            </div>
            <div className="col-span-1">
              <MenuButton
                size={MenuButton.sizes.XS}
                className="float-right"
                dialogPosition={MenuButton.dialogPositions.BOTTOM_START}>
                <Menu id={`member-${member.id}`}>
                  <MenuItem
                    icon={Trash}
                    title="Delete member"
                    onClick={() => {
                      setMemberToDelete(member);
                      setIsAttemptingDeleteMember(true);
                    }}
                  />
                </Menu>
              </MenuButton>
            </div>
          </div>

        </div>
      ))}

      <Modal
        data-testid={"modal_delete_member_attempt"}
        show={isAttemptingDeleteMember}
        onClose={() => {
          setMemberToDelete(undefined);
          setIsAttemptingDeleteMember(false);
        }}
      >
        <ModalHeader title="Delete member?" />
        <ModalContent>
          <div className="pb-2">
            Are you sure you want to delete this member?
            <Text weight={"bold"}>{memberToDelete?.first_name} {memberToDelete?.last_name}</Text>
          </div>
        </ModalContent>
        <ModalFooter className="flex justify-start gap-2">
          <Button kind={Button.kinds.PRIMARY} onClick={() => {
            setMemberToDelete(undefined);
            setIsAttemptingDeleteMember(false);
          }
          }>
            Cancel
          </Button>
          <Button
            kind={Button.kinds.SECONDARY}
            onClick={() => {
              deleteMemberFromAgency();
              setMemberToDelete(undefined);
            }}
            rightIcon={Trash}
            color={Button.colors.NEGATIVE}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>

    </div>
  );
};

export { AgencyMembers };