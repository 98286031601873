import { Link } from "react-router-dom";

const ErrorPage = ({ className }: { className?: string }) => {
  return (
    <div className={`${className} flex gap-2`}>
      This page cannot be found.
      <Link to="/" className="text-blue-600 underline">
        Back to home
      </Link>
    </div>
  );
};

export { ErrorPage };
