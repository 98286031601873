import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

const useSaveState = () => {
  const eventQueue = useSelector((state: RootState) => state.editor.eventQueue);

  const isSaved = eventQueue.length === 0;

  return {
    isSaved,
  };
};

export { useSaveState };
