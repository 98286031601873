import {
  Circle,
  Envelope,
  FacebookLogo,
  GoogleLogo,
  Icon,
  InstagramLogo,
  LinkedinLogo,
  MagnifyingGlass,
  Newspaper,
  Slideshow,
  SnapchatLogo,
  Square,
  TiktokLogo,
  UserRectangle,
  UserSwitch,
  Video,
  WindowsLogo,
  YoutubeLogo,
} from "@phosphor-icons/react";
import { UID } from "./uid";

type Source = {
  title: string;
  utmValue: string;
  icon: SourceIconType;
  mediumOrdering: UID[];
};

type Medium = {
  sourceId: UID;
  title: string;
  utmValue: string;
  icon: MediumIconType;
  attributeValues: {
    [attributeId: UID]: string;
  };
};

enum SourceIconType {
  SEM = "sem",
  RetargetedDisplay = "retargetedDisplay",
  TargetedDisplay = "targetedDisplay",
  Video = "video",
  Email = "email",
  Print = "print",
  OrganicSocial = "organicSocial",
  Custom = "custom",
}

enum MediumIconType {
  Google = "google",
  Microsoft = "microsoft",
  Facebook = "facebook",
  LinkedIn = "linkedIn",
  YouTube = "youTube",
  Instagram = "instagram",
  Snapchat = "snapchat",
  TikTok = "tikTok",
  Custom = "custom",
}

const isValidSourceMedium = (
  source: SourceIconType,
  medium: MediumIconType
) => {
  if (source === SourceIconType.Custom || medium === MediumIconType.Custom)
    return true;
  switch (source) {
    case SourceIconType.SEM:
      return [MediumIconType.Google, MediumIconType.Microsoft].includes(medium);
    case SourceIconType.TargetedDisplay:
    case SourceIconType.RetargetedDisplay:
      return [
        MediumIconType.Google,
        MediumIconType.Microsoft,
        MediumIconType.Facebook,
        MediumIconType.LinkedIn,
      ].includes(medium);
    case SourceIconType.Video:
      return [MediumIconType.YouTube, MediumIconType.Facebook].includes(medium);
    case SourceIconType.OrganicSocial:
      return [
        MediumIconType.Facebook,
        MediumIconType.LinkedIn,
        MediumIconType.Instagram,
        MediumIconType.Snapchat,
        MediumIconType.TikTok,
        MediumIconType.Custom,
      ].includes(medium);
    default:
      return false;
  }
};

const getSourceIcon = (iconType: SourceIconType): Icon => {
  switch (iconType) {
    case SourceIconType.SEM:
      return MagnifyingGlass;
    case SourceIconType.RetargetedDisplay:
      return UserSwitch;
    case SourceIconType.TargetedDisplay:
      return UserRectangle;
    case SourceIconType.Video:
      return Video;
    case SourceIconType.Email:
      return Envelope;
    case SourceIconType.Print:
      return Newspaper;
    case SourceIconType.OrganicSocial:
      return Slideshow;
    case SourceIconType.Custom:
      return Circle;
  }
};

const getSourceDisplayName = (iconType: SourceIconType): string => {
  switch (iconType) {
    case SourceIconType.SEM:
      return "SEM";
    case SourceIconType.RetargetedDisplay:
      return "Display (Retargeted)";
    case SourceIconType.TargetedDisplay:
      return "Display (Targeted)";
    case SourceIconType.Video:
      return "Video";
    case SourceIconType.Email:
      return "Email";
    case SourceIconType.Print:
      return "Print";
    case SourceIconType.OrganicSocial:
      return "Organic Social";
    case SourceIconType.Custom:
      return "Custom Source";
  }
};

const getSourceUTMValue = (iconType: SourceIconType): string => {
  switch (iconType) {
    case SourceIconType.SEM:
      return "sem";
    case SourceIconType.RetargetedDisplay:
      return "display-rt";
    case SourceIconType.TargetedDisplay:
      return "display-tg";
    case SourceIconType.Video:
      return "video";
    case SourceIconType.Email:
      return "email";
    case SourceIconType.Print:
      return "print";
    case SourceIconType.OrganicSocial:
      return "social";
    case SourceIconType.Custom:
      return "";
  }
};

const getMediumIcon = (iconType: MediumIconType): Icon => {
  switch (iconType) {
    case MediumIconType.Google:
      return GoogleLogo;
    case MediumIconType.Microsoft:
      return WindowsLogo;
    case MediumIconType.Facebook:
      return FacebookLogo;
    case MediumIconType.LinkedIn:
      return LinkedinLogo;
    case MediumIconType.YouTube:
      return YoutubeLogo;
    case MediumIconType.Instagram:
      return InstagramLogo;
    case MediumIconType.Snapchat:
      return SnapchatLogo;
    case MediumIconType.TikTok:
      return TiktokLogo;
    case MediumIconType.Custom:
      return Square;
  }
};

const getMediumDisplayName = (iconType: MediumIconType): string => {
  switch (iconType) {
    case MediumIconType.Google:
      return "Google";
    case MediumIconType.Microsoft:
      return "Microsoft";
    case MediumIconType.Facebook:
      return "Facebook";
    case MediumIconType.LinkedIn:
      return "LinkedIn";
    case MediumIconType.YouTube:
      return "YouTube";
    case MediumIconType.Instagram:
      return "Instagram";
    case MediumIconType.Snapchat:
      return "Snapchat";
    case MediumIconType.TikTok:
      return "TikTok";
    case MediumIconType.Custom:
      return "Custom Medium";
  }
};

const getMediumUTMValue = (iconType: MediumIconType): string => {
  switch (iconType) {
    case MediumIconType.Google:
      return "google";
    case MediumIconType.Microsoft:
      return "microsoft";
    case MediumIconType.Facebook:
      return "facebook";
    case MediumIconType.LinkedIn:
      return "linkedin";
    case MediumIconType.YouTube:
      return "youtube";
    case MediumIconType.Instagram:
      return "instagram";
    case MediumIconType.Snapchat:
      return "snapchat";
    case MediumIconType.TikTok:
      return "tiktok";
    case MediumIconType.Custom:
      return "";
  }
};

export {
  SourceIconType,
  MediumIconType,
  getSourceIcon,
  getSourceDisplayName,
  getMediumIcon,
  getMediumDisplayName,
  isValidSourceMedium,
  getSourceUTMValue,
  getMediumUTMValue,
};

export type { Source, Medium };
