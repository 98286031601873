import { FilterEntityType, FilterType } from "../../types/filter";
import { UID } from "../../types/uid";
import { EventGroup, HandlerLookup, makeGeneralPayload } from "../eventUtil";
import { RecordFilterType } from "../hooks/useRecordFilter";
import { createAction } from "../userEventHandlers";
import produce from "immer";

enum TargetEventType {
  Create = "target/create",
  Duplicate = "target/duplicate",
  Rename = "target/rename",
  Delete = "target/delete",
}

type TargetEvent = EventGroup<{
  [TargetEventType.Create]: {
    targetId: UID;
    formulaId: UID;
    filterIdBase: UID;
    entrySetId: UID;
  };
  [TargetEventType.Duplicate]: {
    referenceTargetId: UID;
    newTargetId: UID;
    newFormulaId: UID;
    newFilterIdBase: UID;
    newEntrySetId: UID;
  };
  [TargetEventType.Rename]: {
    targetId: UID;
    newName: string;
  };
  [TargetEventType.Delete]: {
    targetId: UID;
  };
}>;

const getFilterIdGroup = (filterIdBase: UID) => {
  /*
  The old structure kept a singular filter id. The current structure requires
  a filter union, filter intersection, and a filter id. Now, the second entry
  in payload B is a unique ID used as a base string on which to build the 
  three required unique ids. 
    */
  const filterId = filterIdBase;
  const filterUnionId = filterIdBase + "1";
  const filterIntersectionId = filterIdBase + "2";
  return { filterUnionId, filterIntersectionId, filterId };
};

const targetHandlers: HandlerLookup<TargetEvent> = {
  [TargetEventType.Create]: {
    generalizer: ({
      payload: { targetId, formulaId, filterIdBase, entrySetId },
    }) =>
      makeGeneralPayload({
        payloadA: targetId,
        payloadB: `${formulaId},${filterIdBase},${entrySetId}`,
      }),
    specifier: ({ payloadA, payloadB }) =>
      createAction[TargetEventType.Create]({
        targetId: payloadA,
        formulaId: payloadB.split(",")[0],
        filterIdBase: payloadB.split(",")[1],
        entrySetId: payloadB.split(",")[2],
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { targetId, formulaId, filterIdBase, entrySetId } =
          action.payload;
        const { filterUnionId, filterIntersectionId, filterId } =
          getFilterIdGroup(filterIdBase);
        draftState.targets.definitions[targetId] = {
          filterUnionId,
          formulaId,
          name: "",
        };
        draftState.targets.ordering.push(targetId);
        draftState.formulas[formulaId] = {
          blockDefinitions: {},
          blockOrdering: [],
        };
        draftState.sets[filterUnionId] = [filterIntersectionId];
        draftState.sets[filterIntersectionId] = [filterId];
        draftState.filters[filterId] = {
          type: FilterType.None,
          entityType: FilterEntityType.Offer,
          userFriendlyType: RecordFilterType.None,
          entrySetId,
        };
        draftState.sets[entrySetId] = [];
      }),
    validator: (state, action) => {
      const { targetId, formulaId, filterIdBase, entrySetId } = action.payload;
      const { filterUnionId, filterIntersectionId, filterId } =
        getFilterIdGroup(filterIdBase);
      return (
        !(targetId in state.targets.definitions) &&
        !(formulaId in state.formulas) &&
        !(filterId in state.filters) &&
        !(filterUnionId in state.sets) &&
        !(filterIntersectionId in state.sets) &&
        !(entrySetId in state.sets)
      );
    },
  },
  [TargetEventType.Duplicate]: {
    generalizer: ({
      payload: {
        referenceTargetId,
        newTargetId,
        newFormulaId,
        newFilterIdBase,
        newEntrySetId,
      },
    }) =>
      makeGeneralPayload({
        payloadA: referenceTargetId,
        payloadB: newTargetId,
        payloadC: `${newFormulaId},${newFilterIdBase},${newEntrySetId}`,
      }),
    specifier: ({ payloadA, payloadB, payloadC }) =>
      createAction[TargetEventType.Duplicate]({
        referenceTargetId: payloadA,
        newTargetId: payloadB,
        newFormulaId: payloadC.split(",")[0],
        newFilterIdBase: payloadC.split(",")[1],
        newEntrySetId: payloadC.split(",")[2],
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const {
          referenceTargetId,
          newTargetId,
          newFormulaId,
          newFilterIdBase,
          newEntrySetId,
        } = action.payload;
        const referenceTarget = state.targets.definitions[referenceTargetId];
        const {
          filterUnionId: newFilterUnionId,
          filterIntersectionId: newFilterIntersectionId,
          filterId: newFilterId,
        } = getFilterIdGroup(newFilterIdBase);
        draftState.targets.definitions[newTargetId] = {
          filterUnionId: newFilterUnionId,
          formulaId: newFormulaId,
          name: referenceTarget.name,
        };
        draftState.targets.ordering.splice(
          draftState.targets.ordering.indexOf(referenceTargetId) + 1,
          0,
          newTargetId,
        );

        const { formulaId: referenceFormulaId } = referenceTarget;
        draftState.formulas[newFormulaId] = {
          ...state.formulas[referenceFormulaId],
        };
        /**
         * Don't actually copy over an entire filter union.
         * Users most likely want to duplicate the link formula
         * but change the underlying filter.
         */
        draftState.sets[newFilterUnionId] = [newFilterIntersectionId];
        draftState.sets[newFilterIntersectionId] = [newFilterId];
        draftState.filters[newFilterId] = {
          type: FilterType.None,
          entityType: FilterEntityType.Offer,
          userFriendlyType: RecordFilterType.None,
          entrySetId: newEntrySetId,
        };
        draftState.sets[newEntrySetId] = [];
      }),
    validator: (state, action) => {
      const {
        referenceTargetId,
        newTargetId,
        newFormulaId,
        newFilterIdBase,
        newEntrySetId,
      } = action.payload;

      const {
        filterUnionId: newFilterUnionId,
        filterIntersectionId: newFilterIntersectionId,
        filterId: newFilterId,
      } = getFilterIdGroup(newFilterIdBase);
      return (
        !(newTargetId in state.targets.definitions) &&
        !(newFormulaId in state.formulas) &&
        !(newFilterId in state.filters) &&
        !(newFilterUnionId in state.sets) &&
        !(newFilterIntersectionId in state.sets) &&
        !(newEntrySetId in state.filters) &&
        referenceTargetId in state.targets.definitions
      );
    },
  },
  [TargetEventType.Rename]: {
    generalizer: ({ payload: { targetId, newName } }) =>
      makeGeneralPayload({ payloadA: targetId, payloadB: newName }),
    specifier: ({ payloadA, payloadB }) =>
      createAction[TargetEventType.Rename]({
        targetId: payloadA,
        newName: payloadB,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { targetId, newName } = action.payload;
        draftState.targets.definitions[targetId].name = newName;
      }),
    validator: (state, action) => {
      const { targetId } = action.payload;
      return targetId in state.targets.definitions;
    },
  },
  [TargetEventType.Delete]: {
    generalizer: ({ payload: { targetId } }) =>
      makeGeneralPayload({ payloadA: targetId }),
    specifier: ({ payloadA }) =>
      createAction[TargetEventType.Delete]({
        targetId: payloadA,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { targetId } = action.payload;

        const { formulaId, filterUnionId } =
          state.targets.definitions[targetId];

        state.sets[filterUnionId].forEach((filterIntersectionId) => {
          state.sets[filterIntersectionId].forEach((filterId) => {
            const entrySetId = state.filters[filterId].entrySetId;
            delete draftState.sets[entrySetId];
            delete draftState.filters[filterId];
          });
          delete draftState.sets[filterIntersectionId];
        });
        delete draftState.sets[filterUnionId];

        delete draftState.formulas[formulaId];

        delete draftState.targets.definitions[targetId];
        draftState.targets.ordering = state.targets.ordering.filter(
          (targetId) => targetId !== action.payload.targetId,
        );
      }),
    validator: (state, action) =>
      action.payload.targetId in state.targets.definitions,
  },
};

export { TargetEventType, targetHandlers };
export type { TargetEvent };
