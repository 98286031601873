import { EditorHeader } from "../common/EditorHeader";
import { UTMParamType, getFriendlyName, getUTMCode } from "../../types/utm";
import { VerticalCard } from "../common/Card";
import { FormulaBuilder } from "../common/FormulaBuilder";
import { EditorPage } from "../../types/editorPage";
import { useUTM } from "../hooks/useUTM";
import { SpreadMethodType } from "../../types/formula";
import { useTouchpoints } from "../hooks/useTouchpoints";
import { UTMPreviewLookup, useRecordPreviews } from "../hooks/useRecords";
import { useState } from "react";
import { RefreshButton } from "../common/RefreshButton";
import { Checkbox } from "../attributes/Checkbox";

const UTMParamEditor = ({
  className,
  utmParam,
}: {
  className?: string;
  utmParam: UTMParamType;
}) => {
  const { isUsed, formulaId, changeIsUsed } = useUTM(utmParam);

  const { computeUtmPreviewLookup } = useRecordPreviews();
  const [utmPreviewLookup, setUtmPreviewLookup] = useState<UTMPreviewLookup>(
    {},
  );
  const [previewIsLoading, setPreviewIsLoading] = useState<boolean>(true);

  const updateUtmPreview = async () => {
    setPreviewIsLoading(true);
    setUtmPreviewLookup(computeUtmPreviewLookup());
    setPreviewIsLoading(false);
  };

  return (
    <div className={`${className}  ${isUsed ? "" : "opacity-80"}`}>
      <VerticalCard header={getFriendlyName(utmParam)} bodyClassName="p-2">
        <div className="flex flex-col gap-2">
          <Checkbox
            initialValue={isUsed}
            title="Use parameter in UTM link"
            onChange={() => changeIsUsed(!isUsed)}
          />
          {formulaId ? (
            <div className="">
              <FormulaBuilder
                formulaId={formulaId}
                editorPage={EditorPage.Offers}
                title="Formula"
                defaultSpreadMethod={SpreadMethodType.Concatenate}
              />
              <div className="text-xs">
                Tip: If using select attributes, be sure to include a UTM Term
                in the selects editor
              </div>
            </div>
          ) : (
            <div className="flex gap-1 items-end">
              <div className="select-none">Formula:</div>
              <div className="font-mono text-sm">{getUTMCode(utmParam)}</div>
            </div>
          )}
        </div>
        <div className="col-span-6">
          <div className="flex items-center gap-1">
            Preview
            <RefreshButton onRefresh={() => updateUtmPreview()} />
          </div>
          {previewIsLoading ? (
            <div>Press refresh button to load</div>
          ) : utmPreviewLookup[utmParam] ? (
            Array.from(utmPreviewLookup[utmParam]).map((value, idx) => (
              <div
                key={value}
                className={`text-sm ${
                  idx % 2 === 0 ? "bg-slate-100" : "bg-slate-200"
                }`}
              >
                {getUTMCode(utmParam)}={value}
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </VerticalCard>
    </div>
  );
};

const UTMsEditor = ({ className }: { className?: string }) => {
  const { flatTouchpoints } = useTouchpoints();

  return (
    <div className={`${className} w-full overflow-y-scroll no-scrollbar pb-48`}>
      <EditorHeader
        className="p-4"
        title="UTMs"
        subtitle="Edit UTMs for all entries"
      ></EditorHeader>

      <div className="w-full grid grid-cols-12 gap-4 justify-center px-4">
        {[
          UTMParamType.Campaign,
          UTMParamType.Content,
          UTMParamType.Id,
          UTMParamType.Term,
        ].map((param: UTMParamType) => (
          <UTMParamEditor
            className="col-span-12 xl:col-span-6"
            key={param}
            utmParam={param}
          />
        ))}
        <VerticalCard
          className="col-span-12 xl:col-span-6"
          header="Source/Medium combinations"
        >
          <div className="grid grid-cols-12 p-2">
            <div className="text-sm col-span-6">Source</div>
            <div className="text-sm col-span-6">Medium</div>

            {flatTouchpoints.map((flatTouchpoint, idx) => (
              <div
                className="col-span-12 grid grid-cols-12"
                key={`${flatTouchpoint.sourceId}/${flatTouchpoint.mediumId}`}
              >
                <div
                  className={`text-sm col-span-6 ${
                    idx % 2 === 0 ? "bg-slate-100" : "bg-slate-200"
                  }`}
                >
                  utm_source={flatTouchpoint.sourceUtmValue}
                </div>

                <div
                  className={`text-sm col-span-6 ${
                    idx % 2 === 0 ? "bg-slate-100" : "bg-slate-200"
                  }`}
                >
                  utm_medium={flatTouchpoint.mediumUtmValue}
                </div>
              </div>
            ))}
          </div>
        </VerticalCard>
      </div>
    </div>
  );
};

export { UTMsEditor };
