const EditorHeader = ({
  className,
  title,
  subtitle,
  children,
}: {
  className?: string;
  title: string;
  subtitle?: string;
  children?: JSX.Element | JSX.Element[];
}) => {
  return (
    <div className={`${className} flex flex-col gap-2`}>
      <div>{title}</div>
      {subtitle && <div className="text-sm -mt-2">{subtitle}</div>}
      {children}
    </div>
  );
};

export { EditorHeader };
