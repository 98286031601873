import { z } from "zod";

export const TimestampSchema = z
  .string()
  .refine((value) => !isNaN(Date.parse(value)), {
    message: "Invalid date format, expected ISO 8601 format",
  });

export const MicrotimeSchema = z.string().refine(
  (value) => {
    if (isNaN(Date.parse(value))) {
      return false;
    }

    // Ensure microtime exists and is a six-digit number.
    const dateSplit = value.split(".");
    if (dateSplit.length < 2) {
      return false;
    }
    const microtime = dateSplit[1];
    return !microtime || microtime.length !== 6 || isNaN(Number(microtime));
  },
  {
    message: "Invalid date format, expected YYYY-MM-DDTHH:MM:SS.SSSSSS±HH:MM",
  }
);

export class MicroDate extends Date {
  private _microseconds: number;

  constructor(dateStr: string) {
    const microtime = dateStr.split(".")[1].split(/[+-]/)[0].slice(3);
    if (microtime.length !== 3) {
      throw new Error(`Invalid MicroDate ${dateStr}`);
    }
    super(dateStr);
    this._microseconds = microtime ? Number(microtime) : 0;
  }

  valueOf() {
    return super.valueOf() * 1000 + this._microseconds;
  }

  getMicroseconds(): number {
    return this._microseconds;
  }
}
