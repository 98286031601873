import { useProjectSelector } from "../../hooks/useEditor";
import { Filter, FilterType } from "../../types/filter";
import { Formula } from "../../types/formula";
import { SelectRow } from "../../types/select";
import { UID } from "../../types/uid";
import { UTM } from "../../types/utm";
import { ProjectState } from "../projectState";
import { recordMatchesFilter } from "../util/filterComparison";
import {
  addTargetsToRecords,
  addTouchpointsToRecords,
  buildFormula,
  defineRecord,
  initializeRecords,
} from "../util/formulaUtil";
import {
  PartialRecord,
  FullRecord,
  EntityDefinitions,
  Pair,
} from "../../types/record";
import { useOffers } from "./useOffers";
import { useSelects } from "./useSelects";
import { useSets } from "./useSets";
import { useSources } from "./useSources";
import { useTargets } from "./useTargets";
import { useRecordAttributes } from "./useRecordAttributes";
import {
  commaSeparatedIntersection,
  commaSeparatedUnion,
} from "../util/stringSetUtil";

type TargetPreview = {
  [offerId: UID]: {
    offerName: string;
    urls: Set<string>;
  };
};

type TargetPreviewLookup = {
  [targetId: UID]: TargetPreview;
};

type UTMPreviewLookup = {
  [utmParamType: string]: Set<string>;
};

/**
 * Order of computation
 *  initialRecords: Offer
 *  targetRecords: Offer x Target
 *  UTMRecords: Offer x Target x (Source / Medium) x prod(UTM Params)
 */

const useRecords = () => {
  const { offerDefinitions, offerIds } = useOffers();
  const { sourceDefinitions, sourceIds } = useSources();
  const mediumDefinitions = useProjectSelector(
    (project: ProjectState) => project.touchpoints.mediumDefinitions,
  );
  const { targetDefinitions, targetIds } = useTargets();
  const { selectDefinitions } = useSelects();
  const { attributeDefinitions } = useRecordAttributes();

  const { setDefinitions } = useSets();

  const filterDefinitions = useProjectSelector(
    (project: ProjectState) => project.filters,
  );

  const formulaDefinitions = useProjectSelector(
    (project: ProjectState) => project.formulas,
  );

  const entityDefinitions: EntityDefinitions = {
    offerDefinitions,
    targetDefinitions,
    sourceDefinitions,
    mediumDefinitions,
  };

  const initialRecords = initializeRecords({ offerIds });

  const applyFormula = ({
    partialRecord,
    formulaPair,
    accessSelectRowValue,
  }: {
    partialRecord: PartialRecord;
    formulaPair: Pair<Formula>;
    accessSelectRowValue: (row: SelectRow) => string;
  }) =>
    buildFormula({
      partialRecord,
      formulaPair,
      entityDefinitions,
      attributeDefinitions,
      selectDefinitions,
      accessSelectRowValue,
    });

  const injectFilterSetsAsChosenAttributeValues = ({
    partialRecords,
    filterUnion,
  }: {
    partialRecords: PartialRecord[];
    filterUnion: Filter[][];
  }) => {
    const chosenAttributesUnion: { [attributeId: UID]: string } = {};
    filterUnion.forEach((filterIntersection: Filter[]) => {
      const chosenAttributesIntersection: { [attributeId: UID]: string } = {};
      filterIntersection.forEach((filter: Filter) => {
        if (filter.type !== FilterType.SelectSet || !filter.attributeId) return;
        const entrySetValues: string = (
          filter.entrySetId && filter.entrySetId in setDefinitions
            ? setDefinitions[filter.entrySetId]
            : []
        ).join(",");
        if (filter.attributeId in chosenAttributesIntersection) {
          chosenAttributesIntersection[filter.attributeId] =
            commaSeparatedIntersection(
              entrySetValues,
              chosenAttributesIntersection[filter.attributeId],
            );
        } else {
          chosenAttributesIntersection[filter.attributeId] = entrySetValues;
        }
      });
      Object.entries(chosenAttributesIntersection).forEach(
        ([attributeId, entrySetValues]: [UID, string]) => {
          if (attributeId in chosenAttributesUnion) {
            chosenAttributesUnion[attributeId] = commaSeparatedUnion(
              chosenAttributesUnion[attributeId],
              entrySetValues,
            );
          } else {
            chosenAttributesUnion[attributeId] = entrySetValues;
          }
        },
      );
    });

    return partialRecords.map((record) => {
      const chosenAttributes: { [attributeId: UID]: string | undefined } = {
        ...chosenAttributesUnion,
      };
      Object.entries(record.chosenAttributeValues).forEach(
        ([attributeId, entrySetValues]: [UID, string | undefined]) => {
          if (attributeId in chosenAttributesUnion) {
            chosenAttributes[attributeId] = entrySetValues
              ? commaSeparatedIntersection(
                  entrySetValues,
                  chosenAttributesUnion[attributeId],
                )
              : chosenAttributesUnion[attributeId];
          } else {
            chosenAttributes[attributeId] = entrySetValues;
          }
        },
      );
      return { ...record, chosenAttributeValues: chosenAttributes };
    });
  };

  /**
   * @param filterUnion A union of intersections of filters
   */
  const applyFilterUnion = ({
    partialRecords,
    filterUnion,
  }: {
    partialRecords: PartialRecord[];
    filterUnion: Filter[][];
  }) => {
    if (filterUnion.length === 0) return partialRecords;

    const filteredRecordIndexes = new Set<number>();
    filterUnion.forEach((filterIntersection) => {
      partialRecords.forEach((partialRecord: PartialRecord, idx) => {
        const matchesIntersection = filterIntersection.every((filter) => {
          const entrySetValues =
            filter.entrySetId && filter.entrySetId in setDefinitions
              ? setDefinitions[filter.entrySetId]
              : [];
          return recordMatchesFilter({
            filter,
            record: defineRecord(partialRecord, entityDefinitions),
            entrySetValues,
            attributeDefinitions,
          });
        });
        if (matchesIntersection) {
          filteredRecordIndexes.add(idx);
        }
      });
    });
    const filteredRecords = partialRecords.filter((_, idx) =>
      filteredRecordIndexes.has(idx),
    );

    return injectFilterSetsAsChosenAttributeValues({
      partialRecords: filteredRecords,
      filterUnion,
    });
  };

  const computeTargetRecords: () => PartialRecord[] = () => {
    const builtRecords: PartialRecord[] = [];
    const startingRecords = addTargetsToRecords({
      records: initialRecords,
      targetIds,
    });
    startingRecords.forEach((partialRecord: PartialRecord) => {
      const record: FullRecord = defineRecord(partialRecord, entityDefinitions);
      const formulaId: UID | undefined = record.targetPair?.value.formulaId;
      const formula: Formula | undefined = formulaId
        ? formulaDefinitions[formulaId]
        : undefined;
      const filterUnionId: UID | undefined =
        record.targetPair?.value.filterUnionId;

      const filterUnion: Filter[][] = filterUnionId
        ? setDefinitions[filterUnionId].map((filterIntersectionId) =>
            setDefinitions[filterIntersectionId].map(
              (filterId) => filterDefinitions[filterId],
            ),
          )
        : [];

      if (!formula || !formulaId) return;

      const records = applyFormula({
        partialRecord,
        formulaPair: { id: formulaId, value: formula },
        accessSelectRowValue: (row: SelectRow) => row.urlTerm,
      });
      const filteredRecords = applyFilterUnion({
        partialRecords: records,
        filterUnion,
      });
      builtRecords.push(...filteredRecords);
    });
    return builtRecords;
  };

  const utmParameters = useProjectSelector(
    (project: ProjectState) => project.utmParameters,
  );

  const computeUTMRecords: () => PartialRecord[] = () => {
    const targetRecords = computeTargetRecords();
    const utms = Object.values(utmParameters);
    const startingRecords = addTouchpointsToRecords({
      records: targetRecords,
      sourceIds,
      sourceDefinitions,
    });

    const buildRemainingUTMs = (
      remainingUTMs: UTM[],
      builtRecords: PartialRecord[],
    ): PartialRecord[] => {
      if (remainingUTMs.length === 0) return builtRecords;
      const utm: UTM = remainingUTMs[0];

      const formulaId = utm?.formulaId;
      const formula = formulaId ? formulaDefinitions[formulaId] : undefined;

      if (!formulaId || !formula)
        return buildRemainingUTMs(remainingUTMs.slice(1), builtRecords);

      const nextRecords: PartialRecord[] = [];

      builtRecords.forEach((partialRecord: PartialRecord) => {
        nextRecords.push(
          ...applyFormula({
            partialRecord,
            formulaPair: { id: utm.formulaId!, value: formula },
            accessSelectRowValue: (row: SelectRow) => row.utmTerm,
          }),
        );
      });

      return buildRemainingUTMs(remainingUTMs.slice(1), nextRecords);
    };

    return buildRemainingUTMs(utms, startingRecords);
  };

  return {
    attributeDefinitions,
    initialRecords,
    selectDefinitions,
    entityDefinitions,
    computeTargetRecords,
    computeUTMRecords,
    applyFilterUnion,
  };
};

const useRecordPreviews = () => {
  const { computeTargetRecords, computeUTMRecords, entityDefinitions } =
    useRecords();

  const computeTargetPreviewLookup: () => TargetPreviewLookup = () => {
    const preview: TargetPreviewLookup = {};
    const targetRecords = computeTargetRecords();

    targetRecords.forEach((partialRecord: PartialRecord) => {
      const record = defineRecord(partialRecord, entityDefinitions);
      if (!record.targetPair || !record.offerPair) return;

      const targetId = record.targetPair.id;
      const offerId = record.offerPair.id;
      if (!(targetId in preview)) {
        preview[targetId] = {};
      }
      if (!(offerId in preview[targetId])) {
        preview[targetId][offerId] = {
          offerName: record.offerPair.value.title,
          urls: new Set(),
        };
      }

      const formulaId = record.targetPair.value.formulaId;
      const url =
        formulaId in record.formulaResults
          ? record.formulaResults[formulaId]
          : undefined;

      if (url) {
        preview[targetId][offerId].urls.add(url);
      }
    });

    return preview;
  };

  const utmParameters = useProjectSelector(
    (project: ProjectState) => project.utmParameters,
  );

  const computeUtmPreviewLookup: () => UTMPreviewLookup = () => {
    const preview: UTMPreviewLookup = {};
    const utmRecords = computeUTMRecords();

    const utmParamTypes: string[] = Object.keys(utmParameters);

    utmRecords.forEach((partialRecord: PartialRecord) => {
      const record = defineRecord(partialRecord, entityDefinitions);
      utmParamTypes.forEach((utmParamType) => {
        if (!(utmParamType in preview)) {
          preview[utmParamType] = new Set();
        }

        if (
          utmParamType in record.formulaResults &&
          record.formulaResults[utmParamType]
        ) {
          preview[utmParamType].add(record.formulaResults[utmParamType]);
        }
      });
    });

    return preview;
  };

  return { computeTargetPreviewLookup, computeUtmPreviewLookup };
};

export { useRecords, useRecordPreviews };
export type { TargetPreview, TargetPreviewLookup, UTMPreviewLookup };
