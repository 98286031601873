import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

const useEditorDebugInfo = () => {
  const eventQueue = useSelector((state: RootState) => state.editor.eventQueue);
  const liveState = useSelector((state: RootState) => state.editor.liveState);
  const verifiedState = useSelector(
    (state: RootState) => state.editor.verifiedState
  );
  const lastVerifiedEventID = useSelector(
    (state: RootState) => state.editor.lastVerifiedEventID
  );

  return {
    eventQueue,
    liveState,
    verifiedState,
    lastVerifiedEventID,
  };
};

export { useEditorDebugInfo };
