import { Attribute } from "../../types/attribute";
import { EditorPage } from "../../types/editorPage";
import { UID } from "../../types/uid";
import { DropdownItem } from "../attributes/Dropdown";
import { useAttributes } from "./useAttributes";

enum ExtraRecordAttributeType {
  BaseLink = "baseLink",
  UTMLink = "utmLink",
  OfferName = "offerName",
  TargetName = "targetName",
  UTMSource = "utmSource",
  SourceName = "sourceName",
  UTMMedium = "utmMedium",
  MediumName = "mediumName",
  UTMCampaign = "utmCampaign",
  UTMContent = "utmContent",
  UTMTerm = "utmTerm",
  UTMId = "utmId",
}

const extraRecordAttributeOptions: DropdownItem[] = [
  { id: ExtraRecordAttributeType.BaseLink, label: "Base link" },
  { id: ExtraRecordAttributeType.UTMLink, label: "UTM link" },
  { id: ExtraRecordAttributeType.OfferName, label: "Offer name" },
  { id: ExtraRecordAttributeType.TargetName, label: "Page name" },
  { id: ExtraRecordAttributeType.SourceName, label: "Source name" },
  { id: ExtraRecordAttributeType.UTMSource, label: "UTM Source" },
  { id: ExtraRecordAttributeType.MediumName, label: "Medium name" },
  { id: ExtraRecordAttributeType.UTMMedium, label: "UTM Medium" },
  { id: ExtraRecordAttributeType.UTMCampaign, label: "UTM Campaign" },
  { id: ExtraRecordAttributeType.UTMContent, label: "UTM Content" },
  { id: ExtraRecordAttributeType.UTMTerm, label: "UTM Term" },
  { id: ExtraRecordAttributeType.UTMId, label: "UTM Id" },
];

const useRecordAttributes = () => {
  const {
    attributeOptions: offerAttributeOptions,
    definitions: offerAttributeDefinitions,
    attributeIds: offerAttributeIds,
  } = useAttributes(EditorPage.Offers);

  const {
    attributeOptions: touchpointAttributeOptions,
    definitions: touchpointAttributeDefinitions,
    attributeIds: touchpointAttributeIds,
  } = useAttributes(EditorPage.Touchpoints);

  const attributeIds: UID[] = [...offerAttributeIds, ...touchpointAttributeIds];

  const attributeDefinitions: { [entityId: UID]: Attribute } = {
    ...offerAttributeDefinitions,
    ...touchpointAttributeDefinitions,
  };

  const attributeOptions: DropdownItem[] = [
    ...offerAttributeOptions,
    ...touchpointAttributeOptions,
  ];

  return { attributeDefinitions, attributeOptions, attributeIds };
};

export {
  ExtraRecordAttributeType,
  extraRecordAttributeOptions,
  useRecordAttributes,
};
