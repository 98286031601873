import { Button, Text } from "monday-ui-react-core";
import { EmailCodeVerified, useEmailCode } from "../../hooks/useEmailCode";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ConfirmedMember = ({ className, verified }: { className?: string, verified?: EmailCodeVerified }) => {

  const navigate = useNavigate();

  const {
    acceptInvitation
  } = useEmailCode(verified?.code);

  const accept = () => {

    if (!verified) {
      return;
    }

    acceptInvitation(verified).then(r => {
      toast.success("Invitation accepted. You will be redirected to login page.");
      setTimeout(() => {
        navigate("/login");
      }, 4000);
    });
  };

  if (!verified?.user.account_confirmed) {
    return <></>;
  }

  return (
    <div className={`${className}`}>

      <Text className="text-center mb-4" ellipsis={false}>
        You already have an account. Please use Invite Button to accept invitation.
      </Text>

      <Button onClick={accept} size={Button.sizes.LARGE} color={Button.colors.POSITIVE}>
        Accept Invitation
      </Button>

    </div>
  );
};

export { ConfirmedMember };
