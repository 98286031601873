import { Skeleton, Title } from "monday-ui-react-core";
import { Nav } from "../components/Nav";
import { Content } from "../components/Content";
import { useBrands } from "../hooks/useBrands";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BrandId } from "../types/util";

const SelectBrand = ({ className }: { className?: string }) => {
  const { brands, isLoading } = useBrands();
  const navigate = useNavigate();

  const selectBrand = useCallback(
    (id: BrandId) => {
      navigate(`/campaigns/${id}`);
    },
    [navigate]
  );

  useEffect(() => {
    if (brands.length === 1) {
      selectBrand(brands[0].id as BrandId);
    }
  }, [brands, selectBrand]);

  return (
    <div className={`${className} flex flex-col gap-2`}>
      <Nav />
      <Content innerClassName="flex flex-col gap-4 pt-2">
        <Title>Choose Brand</Title>
        {!isLoading ? (
          <div className="flex flex-col">
            {brands.map((brand) => (
              <button
                key={brand.id}
                className="flex p-2
                hover:bg-slate-50 rounded-md shadow-sm border"
                onClick={() => selectBrand(brand.id as BrandId)}
              >
                {brand.brand_name}
              </button>
            ))}
          </div>
        ) : (
          <div className="flex flex-col gap-2 w-full">
            {Array.from({ length: 4 }, (_, idx) => idx).map((key) => (
              <Skeleton
                key={key}
                type={Skeleton.types.TEXT}
                width={600}
                height={60}
              />
            ))}
          </div>
        )}
      </Content>
    </div>
  );
};

export { SelectBrand };
