import { Nav } from "../../components/Nav";
import { Content } from "../../components/Content";
import { useAccount } from "../../hooks/useAccount";
import { MyAccount } from "./MyAccount";
import { AgencyName } from "./agencyAdmin/AgencyName";
import { AgencyInvite } from "./agencyAdmin/AgencyInvite";
import { AgencyMembers } from "./agencyAdmin/AgencyMembers";
import { AgencyBrands } from "./agencyAdmin/AgencyBrands";
import { AgencyBrandAdd } from "./agencyAdmin/AgencyBrandAdd";
import { AgencyMembership } from "./agencyMember/AgencyMembership";
import { BrandMembership } from "./brandMember/BrandMembership";
import { Deactivate } from "./Deactivate";

const Account = ({ className }: { className?: string }) => {

  const {
    account,
    isAgencyAdmin,
    isAgencyMember,
    isBrandMember,
    isLoading
  } = useAccount();

  if (isLoading || !account) {
    return <></>;
  }

  return (
    <div className={`${className} flex flex-col gap-2 bg-slate-50`}>

      <Nav />

      <Content innerClassName="flex flex-col gap-4">

        <MyAccount />

        {/*Agency Admin*/}
        {isAgencyAdmin() && account.agency_managed ?
          <>
            <AgencyName agencyId={account.agency_managed.id} />
            <AgencyInvite agencyId={account.agency_managed.id} />
            <AgencyMembers agencyId={account.agency_managed.id} />
            <AgencyBrandAdd agencyId={account.agency_managed.id} />
            <AgencyBrands agencyId={account.agency_managed.id} />
          </> : <></>}


        {/*Agency Member*/}
        {isAgencyMember() ? <AgencyMembership /> : <></>}

        {/*Brand Member*/}
        {isBrandMember() ? <BrandMembership /> : <></>}

        <Deactivate />

      </Content>

    </div>
  );
};

export { Account };
