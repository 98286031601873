import produce from "immer";
import { EventGroup, HandlerLookup, makeGeneralPayload } from "../eventUtil";
import { createAction } from "../userEventHandlers";
import { UID } from "../../types/uid";

enum FilterUnionEventType {
  AddFilterIntersection = "filterUnion/addFilterIntersection",
  RemoveFilterIntersection = "filterUnion/removeFilterIntersection",
}

type FilterUnionEvent = EventGroup<{
  [FilterUnionEventType.AddFilterIntersection]: {
    unionId: UID;
    intersectionId: UID;
  };
  [FilterUnionEventType.RemoveFilterIntersection]: {
    unionId: UID;
    intersectionId: UID;
  };
}>;

const filterUnionHandlers: HandlerLookup<FilterUnionEvent> = {
  [FilterUnionEventType.AddFilterIntersection]: {
    generalizer: ({ payload: { unionId, intersectionId } }) =>
      makeGeneralPayload({
        payloadA: unionId,
        payloadB: intersectionId,
      }),
    specifier: ({ payloadA, payloadB }) => {
      return createAction[FilterUnionEventType.AddFilterIntersection]({
        unionId: payloadA,
        intersectionId: payloadB,
      });
    },
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { unionId, intersectionId } = action.payload;
        draftState.sets[unionId].push(intersectionId);
        draftState.sets[intersectionId] = [];
      }),
    validator: (state, action) =>
      action.payload.unionId in state.sets &&
      !state.sets[action.payload.unionId].includes(
        action.payload.intersectionId
      ),
  },
  [FilterUnionEventType.RemoveFilterIntersection]: {
    generalizer: ({ payload: { unionId, intersectionId } }) =>
      makeGeneralPayload({
        payloadA: unionId,
        payloadB: intersectionId,
      }),
    specifier: ({ payloadA, payloadB }) => {
      return createAction[FilterUnionEventType.RemoveFilterIntersection]({
        unionId: payloadA,
        intersectionId: payloadB,
      });
    },
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { unionId, intersectionId } = action.payload;
        draftState.sets[unionId] = state.sets[unionId].filter(
          (intersectionId) => intersectionId !== action.payload.intersectionId
        );
        delete draftState.sets[intersectionId];
      }),
    validator: (state, action) =>
      action.payload.unionId in state.sets &&
      state.sets[action.payload.unionId].includes(
        action.payload.intersectionId
      ),
  },
};

export { filterUnionHandlers, FilterUnionEventType };
export type { FilterUnionEvent };
