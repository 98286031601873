const Content = ({
  className,
  innerClassName,
  children,
}: {
  className?: string;
  innerClassName?: string;
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <div className={`${className} flex p-4 justify-center`}>
      <div className={`${innerClassName} w-full max-w-7xl`}>{children}</div>
    </div>
  );
};

export { Content };
