import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import { EditorState, editorReducer } from "./editorReducer";
import { DebugState, debugReducer } from "./debugReducer";

const store = configureStore({
  reducer: {
    editor: editorReducer,
    debug: debugReducer,
  },
});

type RootState = {
  editor: EditorState;
  debug: DebugState;
};

type AppDispatch = typeof store.dispatch;

type EditorThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export { store };

export type { RootState, AppDispatch, EditorThunk };
