import { VerticalCard } from "../common/Card";
import { EditorHeader } from "../common/EditorHeader";
import { AddNewButton } from "../common/AddNewButton";
import { AttributeBuilder } from "../common/AttributeBuilder";
import { EditorPage } from "../../types/editorPage";
import { useOffer, useOfferAttribute, useOffers } from "../hooks/useOffers";
import { UID } from "../../types/uid";
import { Copy } from "@phosphor-icons/react";
import { IconButton } from "monday-ui-react-core";
import { TextInput } from "../attributes/Input";
import { Attribute } from "../common/Attribute";
import { RemoveItemButton } from "../common/RemoveItemButton";

const OfferAttribute = ({
  offerId,
  attributeId,
}: {
  offerId: UID;
  attributeId: UID;
}) => {
  const { name, type, selectRows, value, editValue } = useOfferAttribute(
    offerId,
    attributeId
  );

  return (
    <div className="w-fit">
      <Attribute
        type={type}
        selectRows={selectRows}
        value={value}
        editValue={editValue}
        name={name}
      />
    </div>
  );
};

const Offer = ({
  className,
  offerId,
}: {
  className?: string;
  offerId: UID;
}) => {
  const { name, remove, rename, duplicate, attributeIds } = useOffer(offerId);

  return (
    <VerticalCard
      className={`${className} h-fit w-fit`}
      header={
        <div className="flex items-center">
          {/* <DotsSixVertical size={20} /> */}
          <TextInput
            initialValue={name}
            className="w-full"
            onChange={(newName) => rename(newName)}
          />
          <IconButton icon={Copy} onClick={() => duplicate()} />

          <RemoveItemButton onRemove={() => remove()} />
        </div>
      }
    >
      <div className="flex flex-col gap-x-4 gap-y-2 p-2 ">
        {attributeIds.map((attributeId: UID) => (
          <OfferAttribute
            key={attributeId}
            offerId={offerId}
            attributeId={attributeId}
          />
        ))}
      </div>
    </VerticalCard>
  );
};

const OffersEditor = ({ className }: { className?: string }) => {
  const { offerIds, create } = useOffers();

  return (
    <div className={`${className} flex w-full overflow-y-scroll no-scrollbar `}>
      <div className="w-full">
        <EditorHeader
          className="p-4"
          title="Offers"
          subtitle="Outline promotions associated with the campaign"
        >
          <AddNewButton onSelect={() => create()} title="Add offer" />
        </EditorHeader>
        <div className="grid grid-cols-12 gap-4 px-4 pb-96">
          {offerIds.map((offerId: UID) => (
            <Offer
              className="col-span-12 lg:col-span-6 2xl:col-span-4 w-full"
              key={offerId}
              offerId={offerId}
            />
          ))}
        </div>
      </div>
      <AttributeBuilder className="sticky top-2 right-2 " page={EditorPage.Offers} />
    </div>
  );
};

export { OffersEditor };
