import produce from "immer";
import { UID } from "../../types/uid";
import { EventGroup, HandlerLookup, makeGeneralPayload } from "../eventUtil";
import { createAction } from "../userEventHandlers";

enum SetEventType {
  AddValue = "set/addValue",
  RemoveValue = "set/removeValue",
  ClearValues = "set/clearValues",
}

type SetEvent = EventGroup<{
  [SetEventType.AddValue]: {
    setId: UID;
    value: string;
  };
  [SetEventType.RemoveValue]: {
    setId: UID;
    value: string;
  };
  [SetEventType.ClearValues]: {
    setId: UID;
  };
}>;

const setHandlers: HandlerLookup<SetEvent> = {
  [SetEventType.AddValue]: {
    generalizer: ({ payload: { setId, value } }) =>
      makeGeneralPayload({ payloadA: setId, payloadB: value }),
    specifier: ({ payloadA, payloadB }) =>
      createAction[SetEventType.AddValue]({ setId: payloadA, value: payloadB }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { setId, value } = action.payload;
        if (!state.sets[setId].includes(value)) {
          draftState.sets[setId].push(value);
        }
      }),
    validator: (state, action) => action.payload.setId in state.sets,
  },
  [SetEventType.RemoveValue]: {
    generalizer: ({ payload: { setId, value } }) =>
      makeGeneralPayload({ payloadA: setId, payloadB: value }),
    specifier: ({ payloadA, payloadB }) =>
      createAction[SetEventType.RemoveValue]({
        setId: payloadA,
        value: payloadB,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { setId } = action.payload;
        draftState.sets[setId] = state.sets[setId].filter(
          (value) => value !== action.payload.value
        );
      }),
    validator: (state, action) => action.payload.setId in state.sets,
  },
  [SetEventType.ClearValues]: {
    generalizer: ({ payload: { setId } }) =>
      makeGeneralPayload({ payloadA: setId }),
    specifier: ({ payloadA }) =>
      createAction[SetEventType.ClearValues]({ setId: payloadA }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { setId } = action.payload;
        draftState.sets[setId] = [];
      }),
    validator: (state, action) => action.payload.setId in state.sets,
  },
};

export { setHandlers, SetEventType };
export type { SetEvent };
