import { useState } from "react";
import { ProjectId } from "../types/util";

const useProjectName = (projectId: ProjectId) => {
  /** TODO: Link with API endpoint to get & set project name by id */
  const [projectName, setProjectName] = useState("2024 Q1 Campaign");
  const onEditProjectName = (newName: string) => {
    setTimeout(() => {
      setProjectName(newName);
    }, 400);
  };

  return {
    projectName,
    onEditProjectName,
  };
};

export { useProjectName };
