import { Button } from "monday-ui-react-core";
import { Link, useNavigate } from "react-router-dom";
import { Logo } from "../components/Logo";

const Home = ({ className }: { className?: string }) => {
  const navigate = useNavigate();

  return (
    <div className={`${className} h-screen`}>
      <nav className="flex gap-2 justify-between items-center">
        <Logo />
        <Button
          className="mx-2"
          kind={Button.kinds.SECONDARY}
          onClick={() => navigate("/login")}
        >
          Log in
        </Button>
      </nav>
      <div className="h-full flex flex-col items-center gap-4">
        <div className="text-2xl">Campaign Manager</div>
        <div>(General info about the app)</div>
        <div className="flex gap-2">
          <Link to="/signup" className="text-blue-600 underline">
            Start new organization
          </Link>
          <Link to="/campaigns" className="text-blue-600 underline">
            Projects
          </Link>
        </div>
      </div>
    </div>
  );
};

export { Home };
