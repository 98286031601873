import { z } from "zod";

type UID = string;

const UIDSchema = z.string();

const UID_RANDOM_LENGTH: number = 6; // number of random base62 chars appended to timestamp

const generateUid = () => {
  const timestamp = Date.now();
  const base36Timestamp: string = timestamp.toString(36);
  const CHAR_SET =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const getRandomChar = () =>
    CHAR_SET[Math.floor(Math.random() * CHAR_SET.length)];
  const getRandomSequence = (length: number) =>
    Array(length)
      .fill(0)
      .reduce((acc) => `${acc}${getRandomChar()}`, "");
  return `${base36Timestamp}${getRandomSequence(UID_RANDOM_LENGTH)}`;
};

export type { UID };
export { generateUid, UIDSchema };
