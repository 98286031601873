import { useProjectDispatch, useProjectSelector } from "../../hooks/useEditor";
import { UID, generateUid } from "../../types/uid";
import { FilterIntersectionEventType } from "../handlers/handleFilterIntersection";
import { FilterUnionEventType } from "../handlers/handleFilterUnion";
import { ProjectState } from "../projectState";
import { createAction } from "../userEventHandlers";

/**
 * Structured as
 * Union ( Intersection (A, B), Intersection (C) )
 *
 * Where A, B, C are filters. If A', B', C' represent the record sets
 * passed by the filters, the resulting filter union includes the set
 *
 *  R' = (A' ∩ B') ∪ C'
 *
 */

const useFilterIntersection = (filterIntersectionId: UID) => {
  const projectDispatch = useProjectDispatch();

  const filterIds = useProjectSelector(
    (project: ProjectState) => project.sets[filterIntersectionId]
  );

  const addFilter = () => {
    projectDispatch(
      createAction[FilterIntersectionEventType.AddFilter]({
        intersectionId: filterIntersectionId,
        filterId: generateUid(),
        entrySetId: generateUid(),
      })
    );
  };

  const removeFilter = (filterId: UID) => {
    projectDispatch(
      createAction[FilterIntersectionEventType.DeleteFilter]({
        intersectionId: filterIntersectionId,
        filterId,
      })
    );
  };

  const removeFilterIntersection = (filterUnionId: UID) => {
    projectDispatch(
      createAction[FilterUnionEventType.RemoveFilterIntersection]({
        unionId: filterUnionId,
        intersectionId: filterIntersectionId,
      })
    );
  };

  return { filterIds, addFilter, removeFilter, removeFilterIntersection };
};

const useFilterUnion = (filterUnionId: UID) => {
  const projectDispatch = useProjectDispatch();

  const filterIntersectionIds = useProjectSelector(
    (project: ProjectState) => project.sets[filterUnionId]
  );

  const addFilterIntersection = () => {
    projectDispatch(
      createAction[FilterUnionEventType.AddFilterIntersection]({
        unionId: filterUnionId,
        intersectionId: generateUid(),
      })
    );
  };

  return {
    filterIntersectionIds,
    addFilterIntersection,
  };
};

export { useFilterIntersection, useFilterUnion };
