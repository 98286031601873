import { useProjectDispatch } from "../../hooks/useEditor";
import { DebugEventType } from "../handlers/handleDebug";
import { createAction } from "../userEventHandlers";

const useDebug = () => {
  const projectDispatch = useProjectDispatch();

  const createError = () => {
    projectDispatch(
      createAction[DebugEventType.CauseError]({
        errorName: "Cause error test",
      })
    );
  };

  return { createError };
};

export { useDebug };
