import { produce } from "immer";
import { EventGroup, HandlerLookup, makeGeneralPayload } from "../eventUtil";
import { createAction } from "../userEventHandlers";
import { UID } from "../../types/uid";
import { defaultOffer } from "../../types/offer";

enum OfferEventType {
  Create = "offer/create",
  Duplicate = "offer/duplicate",
  Rename = "offer/rename",
  Delete = "offer/delete",
  EditAttribute = "offer/editAttribute",
}

type OfferEvent = EventGroup<{
  [OfferEventType.Create]: {
    offerId: UID;
  };
  [OfferEventType.Duplicate]: {
    referenceOfferId: UID;
    newOfferId: UID;
  };
  [OfferEventType.Rename]: {
    offerId: UID;
    newName: string;
  };
  [OfferEventType.Delete]: {
    offerId: UID;
  };
  [OfferEventType.EditAttribute]: {
    offerId: UID;
    attributeId: UID;
    newValue: string;
  };
}>;

const offerHandlers: HandlerLookup<OfferEvent> = {
  [OfferEventType.Create]: {
    generalizer: ({ payload: { offerId } }) =>
      makeGeneralPayload({
        payloadA: offerId,
      }),
    specifier: ({ payloadA }) =>
      createAction[OfferEventType.Create]({
        offerId: payloadA,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { offerId } = action.payload;
        draftState.offers.definitions[offerId] = defaultOffer;
        draftState.offers.ordering.push(offerId);
      }),
    validator: (state, action) => {
      const { offerId } = action.payload;
      return !(offerId in state.offers.definitions);
    },
  },
  [OfferEventType.Duplicate]: {
    generalizer: ({ payload: { referenceOfferId, newOfferId } }) =>
      makeGeneralPayload({
        payloadA: referenceOfferId,
        payloadB: newOfferId,
      }),
    specifier: ({ payloadA, payloadB }) =>
      createAction[OfferEventType.Duplicate]({
        referenceOfferId: payloadA,
        newOfferId: payloadB,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { referenceOfferId, newOfferId } = action.payload;
        draftState.offers.definitions[newOfferId] = {
          ...draftState.offers.definitions[referenceOfferId],
        };
        draftState.offers.ordering.splice(
          draftState.offers.ordering.indexOf(referenceOfferId) + 1,
          0,
          newOfferId
        );
      }),
    validator: (state, action) => {
      const { referenceOfferId, newOfferId } = action.payload;
      return (
        !(newOfferId in state.offers.definitions) &&
        referenceOfferId in state.offers.definitions
      );
    },
  },
  [OfferEventType.Rename]: {
    generalizer: ({ payload: { offerId, newName } }) =>
      makeGeneralPayload({
        payloadA: offerId,
        payloadB: newName,
      }),
    specifier: ({ payloadA, payloadB }) =>
      createAction[OfferEventType.Rename]({
        offerId: payloadA,
        newName: payloadB,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { offerId, newName } = action.payload;
        draftState.offers.definitions[offerId].title = newName;
      }),
    validator: (state, action) => {
      const { offerId } = action.payload;
      return offerId in state.offers.definitions;
    },
  },
  [OfferEventType.Delete]: {
    generalizer: ({ payload: { offerId } }) =>
      makeGeneralPayload({ payloadA: offerId }),
    specifier: ({ payloadA }) =>
      createAction[OfferEventType.Delete]({
        offerId: payloadA,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { offerId } = action.payload;
        delete draftState.offers.definitions[offerId];
        draftState.offers.ordering = state.offers.ordering.filter(
          (offerId: UID) => offerId !== action.payload.offerId
        );
      }),
    validator: (state, action) => {
      const { offerId } = action.payload;
      return offerId in state.offers.definitions;
    },
  },
  [OfferEventType.EditAttribute]: {
    generalizer: ({ payload: { offerId, attributeId, newValue } }) =>
      makeGeneralPayload({
        payloadA: offerId,
        payloadB: attributeId,
        payloadC: newValue,
      }),
    specifier: ({ payloadA, payloadB, payloadC }) =>
      createAction[OfferEventType.EditAttribute]({
        offerId: payloadA,
        attributeId: payloadB,
        newValue: payloadC,
      }),
    reducer: (state, action) =>
      produce(state, (draftState) => {
        const { offerId, attributeId, newValue } = action.payload;
        draftState.offers.definitions[offerId].attributeValues[attributeId] =
          newValue;
      }),
    validator: (state, action) => {
      const { offerId } = action.payload;
      return offerId in state.offers.definitions;
    },
  },
};

export { offerHandlers, OfferEventType };
export type { OfferEvent };
